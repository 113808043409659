import { toCamelCase, capitalizeFirstLetter, formatComment } from './utils';

const toSetKey = (str) => {
  // Convert to camelCase, and capitalize the first letter
  return (
    'Set' + toCamelCase(str).replace(/^[a-z]/, (group) => group.toUpperCase())
  );
};

interface Config {
  client_name?: string;
  language?: string;
  country_codes?: string[];
  [key: string]: any;
}

// Some elements inside of arrays are actually enums instead of strings.
// But not all of them! So we have to hard code that list here,
const goEnumTypes = {
  products: 'plaid.Products',
  country_codes: 'plaid.CountryCode',
  additional_consented_products: 'plaid.Products',
  required_if_supported_products: 'plaid.Products',
  optional_products: 'plaid.Products',
  'account_filters.depository.account_subtypes':
    'plaid.DepositoryAccountSubtype',
  'account_filters.credit.account_subtypes': 'plaid.CreditAccountSubtype',
  'income_verification.income_source_types':
    'plaid.IncomeVerificationSourceType',
  'income_verification.payroll_income.flow_types':
    'plaid.IncomeVerificationPayrollFlowType',
};

const goSingleValueEnums = {
  consumer_report_permissible_purpose: 'plaid.ConsumerReportPermissiblePurpose',
};

const goEnumStripCommonPrefix = {
  // See https://github.com/swagger-api/swagger-codegen-generators/issues/246#issuecomment-442629121
  // for why we need this
  'income_verification.payroll_income.flow_types': 'PAYROLL_',
};

// The same holds true for many objects. They're often pre-defined classes
// that we can declare here.
const goTypes = {
  user: 'plaid.LinkTokenCreateRequestUser',
  account_filters: 'plaid.LinkTokenAccountFilters',
  'account_filters.depository': 'plaid.DepositoryFilter',
  'account_filters.credit': 'plaid.CreditFilter',
  auth: 'plaid.LinkTokenCreateRequestAuth',
  transactions: 'LinkTokenTransactions',
  payment_initiation: 'LinkTokenCreateRequestPaymentInitiation',
  statements: 'LinkTokenCreateRequestStatements',
  identity_verification: 'plaid.LinkTokenCreateRequestIdentityVerification',
  income_verification: 'plaid.LinkTokenCreateRequestIncomeVerification',
  'income_verification.bank_income':
    'plaid.LinkTokenCreateRequestIncomeVerificationBankIncome',
  'income_verification.payroll_income':
    'plaid.LinkTokenCreateRequestIncomeVerificationPayrollIncome',
  cra_options: 'plaid.LinkTokenCreateRequestCraOptions',
  'cra_options.base_report': 'plaid.LinkTokenCreateRequestCraOptionsBaseReport',
  'cra_options.partner_insights':
    'plaid.LinkTokenCreateRequestCraOptionsPartnerInsights',
};

// And just to make things extra fun, Go has "variables that need to be declared
// earlier and passed in by reference"
const pointerTypes = {
  'user.phone_number': 'string',
  'auth.automated_microdeposits_enabled': 'bool',
  'user.email_address': 'string',
  'income_verification.bank_income.enable_multiple_items': 'bool',
  'statements.start_date': 'string',
  'statements.end_date': 'string',
  // Add more fields here as necessary
};

// And then these are for variables that can be passed in by references, but
// don't need to be declared earlier. I wish there were a more deterministic way
// of determining which is which.
const inlinePointerTypes = ['income_verification.income_source_types'];

// And then these are for the variables that look like booleans, but are actually
// nullable booleans, which are declared differently!
const nullableBooleans = [
  'income_verification.bank_income.enable_multiple_items',
];

// Might as well leave this logic here because we might need it at some point
const stringsThatShouldBeDates = [];

const formatArray = (
  path,
  elements,
  indentLevel = 0,
  maxElementsPerLine = 1,
  maxCharsPerLine = 50,
) => {
  // Convert `plaid.Products.Investments` to `plaid.PRODUCTS_INVESTMENTS`
  let indents = '  '.repeat(indentLevel);
  let enumType = goEnumTypes[path];
  let originalEnumType = enumType;
  if (enumType) {
    const splitEnumType = enumType.split('.');
    if (splitEnumType.length > 1) {
      enumType =
        splitEnumType[0] + '.' + splitEnumType.slice(1).join('.').toUpperCase();
    }
  }
  const pointerMarker = inlinePointerTypes.includes(path) ? '&' : '';
  const arrayElements = elements
    .map(
      (v) =>
        `${(goEnumStripCommonPrefix[path]
          ? v.toUpperCase().replace(goEnumStripCommonPrefix[path], '')
          : v.toUpperCase()
        ).replace(/ /g, '_')}`,
    )
    .map((v) => `${enumType ? enumType + '_' : ''}${v}`);
  if (
    elements.length <= maxElementsPerLine &&
    JSON.stringify(elements).length <= maxCharsPerLine
  ) {
    // Will it fit on one line?
    const valuesStr = arrayElements.join(', ');
    return `${pointerMarker}[]${originalEnumType}{${valuesStr}}`;
  } else {
    // Each element goes on its own line, add a trailing comma
    const valuesStr = arrayElements.join(',\n    ').concat(',');
    return `${pointerMarker}[]${originalEnumType}{\n${indents}${indents}${valuesStr}\n${indents}}`;
  }
};

export const createGoSampleCode = (config: Config, comment: string) => {
  const createdIntermediaries: any = {};
  const intermediaryAddresses: string[] = [];

  const formatValue = (
    value: any,
    path: string,
    requireIntermediary: boolean = false,
    indentLevel = 0,
  ) => {
    const typeName = goTypes[path] || toCamelCase(path.split('.').pop());
    if (pointerTypes[path]) {
      const typeName = pointerTypes[path];
      const varName = toCamelCase(path.split('.').pop());
      createdIntermediaries[path] = varName;
      if (typeName === 'bool') {
        intermediaryAddresses.push(`var ${varName} ${typeName} = ${value}`);
      } else {
        intermediaryAddresses.push(`var ${varName} ${typeName} = "${value}"`);
      }
      if (nullableBooleans.includes(path)) {
        return `*plaid.NewNullableBool(&${varName})`;
      } else {
        return `&${varName}`;
      }
    }
    if (
      stringsThatShouldBeDates.includes(path) &&
      createdIntermediaries[path]
    ) {
      return createdIntermediaries[path];
    }
    if (Array.isArray(value)) {
      return formatArray(path, value, indentLevel + 1);
    } else if (typeof value === 'object' && goTypes[path]) {
      const varName = createdIntermediaries[path];
      if (varName === undefined && requireIntermediary) {
        throw new Error(`No intermediary created for ${path}`);
      }
      // Return the address of the intermediary if required
      return requireIntermediary ? `&${varName}` : `${varName}` || '';
    } else if (typeof value === 'object') {
      const propsStr = Object.entries(value)
        .map(([k, v]) => {
          // Check if the current key/value pair is an intermediary object
          const isIntermediary = goTypes[`${path}.${k}`];

          return `${capitalizeFirstLetter(toCamelCase(k))}: ${formatValue(
            v,
            `${path}.${k}`,
            isIntermediary,
            indentLevel + 1,
          )}`;
        })
        .join(',\n  ')
        .concat(',');
      return `${capitalizeFirstLetter(typeName)}{\n  ${propsStr}\n}`;
    } else if (goSingleValueEnums[path]) {
      // Gotta go from plaid.ConsumerReportPermissiblePurpose.EXTENSION_OF_CREDIT to plaid.CONSUMERREPORTPERMISSIBLEPURPOSE_EXTENSION_OF_CREDIT
      let enumType = goSingleValueEnums[path];
      const splitEnumType = enumType.split('.');
      if (splitEnumType.length > 1) {
        enumType =
          splitEnumType[0] +
          '.' +
          splitEnumType.slice(1).join('.').toUpperCase();
      }
      return `${enumType}_${value}`;
    } else if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    } else if (typeof value === 'number') {
      return value.toString();
    } else {
      return `"${value}"`;
    }
  };

  // Java (and Go) are complicated because in our sample code, we generally
  // construct intermediary types before we pass them in to the final config
  // object. And it gets further complicated because those intermediary types
  // can have their own intermediary types.
  const createAndFormatIntermediaries = (obj: any, path: string = '') => {
    let declarations = [];
    let initializations = [];

    for (let [key, value] of Object.entries(obj)) {
      const fullPath = `${path}${key}`;

      // Handling date fields specifically
      if (stringsThatShouldBeDates.includes(fullPath)) {
        // Create parsing code and handling for errors
        const varName = toCamelCase(key);
        initializations.push(
          `${varName}, err := time.Parse("2006-01-02", "${value}")\n` +
            `if err != nil {\n    panic(err) // or handle more gracefully\n}\n`,
        );
        createdIntermediaries[fullPath] = varName; // Track the created date variable
        continue; // Skip further processing in this loop iteration
      }

      if (typeof value === 'object') {
        const [
          nestedDeclarations,
          nestedInitializations,
        ] = createAndFormatIntermediaries(value, `${fullPath}.`);
        declarations = [...declarations, ...nestedDeclarations];
        initializations = [...initializations, ...nestedInitializations];
      }

      if (goTypes[fullPath]) {
        const typeName = goTypes[fullPath];
        const varName = toCamelCase(key);
        createdIntermediaries[fullPath] = varName;

        declarations.push(`var ${varName} ${typeName}`);

        const propsStr = Object.entries(value)
          .map(([k, v]) => {
            // Check if the current key/value pair is an intermediary object
            const isIntermediary = goTypes[fullPath];

            return `${capitalizeFirstLetter(toCamelCase(k))}: ${formatValue(
              v,
              `${fullPath}.${k}`,
              isIntermediary,
            )}`;
          })
          .join(',\n  ')
          .concat(',');

        initializations.push(`${varName} := ${typeName}{\n  ${propsStr}\n}`);
      }
    }

    return [declarations, initializations];
  };

  const [_, intermediaryInitializations] = createAndFormatIntermediaries(
    config,
  );

  const addressesStr = intermediaryAddresses.join('\n');
  const initializationsStr = intermediaryInitializations.join('\n');

  const configStr = Object.entries(config)
    .filter(
      ([key, _]) =>
        !['language', 'country_codes', 'client_name', 'user'].includes(key),
    )
    .map(([key, value]) => {
      return `request.${toSetKey(key)}(${formatValue(value, key, false)})`;
    })
    .join(`\n`);

  // Extract the client name, language, and country codes from the config
  const clientName = config.client_name || 'Sample App App';
  const language = config.language || 'en';
  const countryCodes = config.country_codes
    ? config.country_codes
        .map((code) => `plaid.COUNTRYCODE_${code.toUpperCase()}`)
        .join(', ')
    : 'plaid.COUNTRYCODE_US';

  // Generate the initial request creation line
  const requestCreateStr = `request := plaid.NewLinkTokenCreateRequest(
  "${clientName}",
  "${language}",
  []plaid.CountryCode{${countryCodes}},
  user,
)`;

  return `${formatComment(comment, '// ')}
${addressesStr}
${initializationsStr}

${requestCreateStr}
${configStr}

linkTokenCreateResp, _, err := client.PlaidApi.LinkTokenCreate(ctx).LinkTokenCreateRequest(*request).Execute()
if err != nil {
  panic(err)
}
linkToken := linkTokenCreateResp.GetLinkToken();
`.trim();
};
