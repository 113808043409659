import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import dynamic from 'next/dynamic';
import { DocsProvider } from '../../../contexts/docs';
import { OpenAPIProvider } from '../../../contexts/docs/openapi';
import { LinkOpenAPIProvider } from '../../../contexts/docs/openapi/link';
import ErrorBoundary from './ErrorBoundary';
import KeypressWrapper from '../KeypressWrapper';
import MDXComponents from './MDXComponents';
import RouterHooks from './RouterHooks';
import MetaTags from './MetaTags';
import LegacyDocsRedirects from './LegacyDocsRedirects';
import { GetPageComponent } from './util';
import { filterTableOfContents } from '../utilities';
import { generateBreadcrumbStructuredData } from 'src/lib/utils';

import './index.module.scss';
import { FlagWrapper } from '../Layout/FlagWrapper';

// dynamic import so we dont load script tags when server side rendering
const ScriptTags = dynamic(() => import('./ScriptTags'), { ssr: false });

const DocsBeta = ({ Component, pageProps, pathname }) => {
  const Page = GetPageComponent(pathname);
  const tableOfContents = Component.layoutProps.tableOfContents;
  const metadata = Component.layoutProps.metadata;

  return (
    <>
      <LegacyDocsRedirects />
      <ScriptTags />
      <MetaTags metadata={metadata} />
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            generateBreadcrumbStructuredData({ pathname }),
          ),
        }}
      />
      <DocsProvider
        // instead of using a dispatch to set TOC, set value
        // because the TOC was being set to null momentarily upon route change
        value={{
          tableOfContents: filterTableOfContents(tableOfContents, metadata),
          metadata,
        }}
      >
        <OpenAPIProvider>
          <LinkOpenAPIProvider>
            <RouterHooks />
            <KeypressWrapper>
              <MDXProvider components={MDXComponents}>
                <ErrorBoundary>
                  <Page>
                    <FlagWrapper flag={metadata.flag}>
                      <Component {...pageProps} />
                    </FlagWrapper>
                  </Page>
                </ErrorBoundary>
              </MDXProvider>
            </KeypressWrapper>
          </LinkOpenAPIProvider>
        </OpenAPIProvider>
      </DocsProvider>
    </>
  );
};

DocsBeta.displayName = 'DocsBeta';

export default DocsBeta;
