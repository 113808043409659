import React, { useContext } from 'react';
import Link from 'next/link';
import cx from 'classnames';

import { NavItem } from '../constants/routeMap';
import Context from '../../../contexts/docs';

import styles from './SearchHit.module.scss';

interface Props {
  title: string;
  subtitle: string;
  icon: NavItem['icon'];
  href: string;
}

const RelatedArticleHit: React.FC<Props> = (props: Props) => {
  const { dispatch } = useContext(Context);

  // These aren't "real" algolia results, so we don't send anything to algolia
  const handleSelect = () => {
    dispatch({
      type: 'DOCS_SEARCH_RESULT',
      payload: {
        searchResultText: props.title + ' / ' + props.subtitle,
        searchResultPath: props.href,
        searchResultPosition: -1,
      },
    });
  };

  return (
    <Link href={props.href} passHref>
      <a
        href={props.href}
        className={cx(styles.hit, styles.relatedHit)}
        onClick={handleSelect}
      >
        {props.icon && <props.icon className={styles.icon} />}
        <span className={styles.container}>
          <span className={styles.noShrink}>{props.title}</span>
          {props.subtitle && (
            <>
              <span className={styles.separator}>{' / '}</span>
              <span className={styles.subtitle}>{props.subtitle}</span>
            </>
          )}
        </span>
      </a>
    </Link>
  );
};

RelatedArticleHit.displayName = 'RelatedArticleHit';

export default RelatedArticleHit;
