import { animated, easings, useSpring, AnimatedProps } from '@react-spring/web';
import { useContext } from 'react';
import DocsContext from 'src/contexts/docs';
import { Box as VBox } from 'src/components-v2/Layout';
import { CSSProperties } from 'react';

// We have the following variations
// 1. User Logged Out - logged out component
// 2. User Logged In - logged in component
/**
 * @description LoggedInWrapper is a wrapper component for all the components that are conditionally rendered on docs using Dashboard data
 * @param args
 * @param {ReactComponent} args.loggedInComponent - Renders to avoid flash of content if experiment is enabled and user is logged in
 * @param {ReactComponent} args.loggedOutComponent - Renders to avoid flash of content if experiment enabled and user is logged out
 */

interface Props {
  loggedInComponent: JSX.Element;
  loggedOutComponent: JSX.Element;
}

const Box = animated(VBox);

const cache = {};

const LoggedInWrapper = (props: Props) => {
  const { loggedInComponent, loggedOutComponent } = props;
  const { isLoggedIn } = useContext(DocsContext);
  let enabledComponent = <></>;
  if (isLoggedIn) {
    enabledComponent = loggedInComponent;
  } else {
    enabledComponent = loggedOutComponent;
  }

  const fadeInAnimation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      duration: 250,
      easing: easings.easeInQuad,
    },
  });

  const boxStyle: AnimatedProps<{ style: CSSProperties }>['style'] = {
    ...fadeInAnimation,
    position: 'relative',
  };

  return <Box style={boxStyle}>{enabledComponent}</Box>;
};

export default LoggedInWrapper;
