import Page from './Page';
import Content from './Content';
import Spacer from './Spacer';
import SideBySide from './SideBySide';
import FullWidth from './FullWidth';
import Header from './Header';
import Divider from './Divider';

export default {
  Page,
  Content,
  Spacer,
  SideBySide,
  FullWidth,
  Header,
  Divider,
};
