import React from 'react';
import cx from 'classnames';

import styles from './SchemaRow.module.scss';

const EmptySchemaRow: React.FC = () => (
  <div className={cx(styles.schemaRow)}>
    <div className={styles.contentRow}>
      <div className={styles.attributes}>
        This endpoint takes an empty request body.
      </div>
    </div>
  </div>
);

EmptySchemaRow.displayName = 'EmptySchemaRow';

export default EmptySchemaRow;
