import React from 'react';

import { WELCOME_21, PROGRAM_21 } from '../constants/routeMap2021';
import { WELCOME_22, PROGRAM_22 } from '../constants/routeMap2022';
import DocsSidebar from '../../docs/Sidebar';
import { NavGroup } from '../../docs/constants/routeMap';

import styles from './index.module.scss';

const navGroup21: NavGroup = [...WELCOME_21, ...PROGRAM_21];
const navGroup22: NavGroup = [...WELCOME_22, ...PROGRAM_22];

interface Props {
  baseUrl: string;
}

const Sidebar: React.FC<Props> = ({ baseUrl }) => {
  let topNav: NavGroup;

  if (baseUrl.includes('/2021')) {
    topNav = navGroup21;
  } else if (baseUrl.includes('/2022')) {
    topNav = navGroup22;
  }

  return (
    <>
      <DocsSidebar
        dark
        navigation={topNav}
        headerText={<span className={styles.headerText}>Accelerate</span>}
        baseUrl={baseUrl}
        logo='/assets/img/navbar/logo--is-white.svg'
      />
    </>
  );
};

Sidebar.displayName = 'Sidebar';
export default Sidebar;
