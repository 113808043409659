import React from 'react';
import { OpenAPIObject } from 'openapi3-ts';
import { OpenAPILatest } from 'plaid-openapi-spec';

import DocsContext from '../index';

const OpenAPIContext = React.createContext<OpenAPIObject>(OpenAPILatest);

export const { Consumer, Provider } = OpenAPIContext;

export const OpenAPIProvider = (props) => {
  const [state, setState] = React.useState<OpenAPIObject>(() => OpenAPILatest);
  const { apiVersion } = React.useContext(DocsContext);

  const tryImport = async (path) => {
    try {
      return await import(`${path}`);
    } catch (err) {
      return null;
    }
  };

  React.useEffect(() => {
    async function parseDefinition() {
      let api;
      switch (apiVersion) {
        case 'local-preview':
          api = await tryImport('./local-preview.yml').then((m) => {
            if (m != null) {
              return m.default;
            }
            // eslint-disable-next-line no-console
            console.error(
              `Couldn't load local-preview file. Using published file`,
            );
            return OpenAPILatest;
          });
          break;
        case '2020-09-14':
          api = OpenAPILatest;
          break;
        default:
          api = OpenAPILatest;
          break;
      }
      setState(api);
    }
    parseDefinition();
  }, [apiVersion]);

  return <Provider value={state}>{props.children}</Provider>;
};

export default OpenAPIContext;
