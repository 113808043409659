import React, { useContext } from 'react';
import SelectGroup from 'plaid-threads/SelectGroup';

import { getSelectValue } from '../utilities';

import DocsContext from '../../../contexts/docs';
import { ClientPlatform } from '../types';

import styles from './index.module.scss';

interface Props {}

const options: Array<{ value: ClientPlatform; label: string }> = [
  {
    value: 'web',
    label: 'Web',
  },
  {
    value: 'ios',
    label: 'iOS',
  },
  {
    value: 'android',
    label: 'Android',
  },
];

const ClientPlatformSwitcher: React.FC<Props> = () => {
  const { dispatch, clientPlatform } = useContext(DocsContext);
  return (
    <>
      <SelectGroup
        small
        className={styles.select}
        options={options}
        value={getSelectValue(options, clientPlatform)}
        onChange={o =>
          dispatch({
            type: 'SET_CLIENT_PLATFORM',
            payload: {
              clientPlatform: o.value,
            },
          })
        }
      />
    </>
  );
};

ClientPlatformSwitcher.displayName = 'ClientPlatformSwitcher';

export default ClientPlatformSwitcher;
