import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import Image from 'src/components/Image';

import { Icon } from 'src/components-v2/DataDisplay';
import styles from './FlippyIcon.module.scss';

const FlippyIcon = () => {
  const [isFlipping, setIsFlipping] = useState(false);
  const [icon, setIcon] = useState('Search');
  const flipIntervalDelayRef = useRef(15000); // Start with a 15-second delay
  const flipTimeoutRef = useRef<number | null>(null);
  const animationTime = 1000;

  const flipIcon = () => {
    setIsFlipping(true); // Begin the flip effect

    // Change the icon halfway through the flip when the icon is not visible
    setTimeout(() => {
      setIcon((prevIcon) => (prevIcon === 'Search' ? 'Message' : 'Search'));
    }, animationTime / 2);

    // End the flip effect after the full animation duration
    setTimeout(() => {
      setIsFlipping(false);
    }, animationTime);
  };

  useEffect(() => {
    // Function to start the flipping and schedule the next one with an increased delay
    const scheduleFlip = () => {
      if (flipTimeoutRef.current !== null) {
        clearTimeout(flipTimeoutRef.current);
      }

      flipTimeoutRef.current = window.setTimeout(() => {
        flipIcon();
        // Increase the delay for the next flip
        if (flipIntervalDelayRef.current < 60000) {
          flipIntervalDelayRef.current += 5000;
        }
        // Schedule the next flip
        scheduleFlip();
      }, flipIntervalDelayRef.current);
    };

    // Start the first flip
    scheduleFlip();

    // Cleanup function to clear the timeout when the component unmounts
    return () => {
      if (flipTimeoutRef.current !== null) {
        clearTimeout(flipTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <div
        className={cx(
          styles.flipIconContainer,
          isFlipping ? styles.isFlipping : null,
        )}
      >
        {icon === 'Search' ? (
          <Icon icon='Search' />
        ) : (
          <Image
            src='/assets/img/docs/bill-happy.png'
            alt='Ask Bill!'
            isLazy={false}
            width={25}
            height={25}
          />
        )}
      </div>
    </>
  );
};

FlippyIcon.displayName = 'FlippyIcon';
export default FlippyIcon;
