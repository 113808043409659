import React from 'react';
import Link from 'next/link';
import InlineLink from 'plaid-threads/InlineLink';
import { OpenAPIObject } from 'openapi3-ts';

import { getDocumentationUrlFromDefinition } from '../utilities';
import OpenAPIContext from '../../../contexts/docs/openapi';

interface Props {
  children: React.ReactNode;
}

const LinkedCode: React.FC<Props> = (props: Props) => {
  const definition: OpenAPIObject | null = React.useContext(OpenAPIContext);

  const Default = <code>{props.children}</code>;

  // OpenAPI schema is not loaded into context yet
  if (definition == null) {
    return Default;
  }

  const documentationUrl = getDocumentationUrlFromDefinition(
    props.children,
    definition,
  );

  if (documentationUrl != null) {
    return (
      <Link href={documentationUrl} passHref>
        <a>
          <code>{props.children}</code>
        </a>
      </Link>
    );
  }
  return Default;
};

LinkedCode.displayName = 'LinkedCode';

export default React.memo(LinkedCode);
