import React, { useContext, useEffect, useState } from 'react';
import CloseS1 from 'plaid-threads/Icons/CloseS1';
import IconButton from 'plaid-threads/IconButton';
import Reset from 'plaid-threads/Icons/Reset';
import TextInput from 'plaid-threads/TextInput';
import { useSearchBox } from 'react-instantsearch';

import Context from '../../../contexts/docs';
import { useDebounce } from '../utilities';

import styles from './SearchInput.module.scss';

const Input = ({ currentRefinement, refine }) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, 200);
  const { dispatch, openSearchClicked } = useContext(Context);

  useEffect(() => {
    // TODO: this could be done with a direct ref on the input,
    // but that is not supported by threads yet
    document.getElementById('search-input').focus();
  }, []);

  useEffect(() => {
    // Remove "/" from input field only if the first press of "/" opens the search modal.
    // This distinguishes between opening the search modal by clicking it, versus using "/".
    if (openSearchClicked) {
      setIsOpen(true);
    }

    if (inputValue && inputValue === '/' && !isOpen) {
      setInputValue('');
      setIsOpen(true);
      return;
    }
  }, [inputValue, isOpen, openSearchClicked]);

  // Effect for API call
  useEffect(() => {
    // Ignore empty search queries
    if (!debouncedInputValue) {
      return;
    }

    // ignore search queries shorter than 3 characters
    if (debouncedInputValue && debouncedInputValue.length < 3) {
      return;
    }

    // Refine Algolia results
    refine(debouncedInputValue);

    // send search query to analytics
    dispatch({
      type: 'DOCS_SEARCH_INPUT',
      payload: { searchQuery: debouncedInputValue },
    });
  }, [debouncedInputValue, dispatch, refine]);

  return (
    <div className={styles.searchInput}>
      <TextInput
        autoComplete='off'
        className={styles.input}
        id='search-input'
        label='Search'
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
  );
};

const SearchInput = connectSearchBox(Input);

// SearchInput.displayName = 'SearchInput';

export default SearchInput;

// TODO (Codemod generated): ensure your usage correctly maps the props from the connector to the hook
function connectSearchBox(Component) {
  const SearchBox = (props) => {
    const data = useSearchBox(props);

    return <Component {...props} {...data} />;
  };
  SearchBox.displayName = 'searchInput';

  return SearchBox;
}
