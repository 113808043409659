import React from 'react';

import Image from '../Image';

import styles from './index.module.scss';

export interface Props {
  header: string;
  subheader?: string | React.ReactNode;
  body: string | React.ReactNode;
  image?: string | null;
}

const ErrorPage: React.FC<Props> = (props: Props) => (
  <div className={styles.errorPage}>
    <div className={styles.container}>
      {props.image != null ? (
        <Image className={styles.image} src={props.image} alt={props.header} />
      ) : null}
      <h4>{props.header}</h4>
      {props.subheader != null ? (
        <h5 className={styles.subheader}>{props.subheader}</h5>
      ) : null}
      <p className={styles.body}>{props.body}</p>
    </div>
  </div>
);

ErrorPage.displayName = 'ErrorPage';

export default ErrorPage;
