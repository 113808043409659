import React from 'react';
import Router from 'next/router';

import ErrorPage from '../ErrorPage';
import Layout from '../Layout';

interface State {
  hasError: boolean;
  error?: Error;
}

// @TODO: LP team to determine if this interface should be removed
// disabling to prevent error for now
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    Router.events.on(
      'routeChangeStart',
      () => this.setState({ hasError: false }), // reset error if user navigates
    );
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error(`Failed to render page: ${error} ${errorInfo}`);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout.Page>
          <ErrorPage
            header='Something went wrong'
            body={`Our team has been notified and is working on the issue`}
            image='/assets/img/backgrounds/500-error-state.png'
          />
          <pre style={{ visibility: 'hidden' }}>
            {JSON.stringify({
              errorMessage: this.state.error.message,
              errorStack: this.state.error.stack,
            })}
          </pre>
        </Layout.Page>
      );
    }

    return this.props.children;
  }
}
