import React, { cloneElement, useContext } from 'react';

import {
  CodeBlockType,
  CodeLanguage,
  IOSCodeLanguage,
  AndroidCodeLanguage,
  ReactNativeiOSCodeLanguage,
  FrontendCodeLanguage,
} from '../types';
import Context from '../../../contexts/docs';
import { languageFromPrismClassName } from '../utilities';
import {
  SERVER_LANGUAGES,
  IOS_LANGUAGES,
  ANDROID_LANGUAGES,
  REACT_NATIVE_IOS_LANGUAGES,
  FRONTEND_LANGUAGES,
} from '../constants';

export interface Props {
  type: CodeBlockType;
  title?: string;
  children?: Array<React.ReactElement>;
  codeBlocks?: Partial<Record<CodeLanguage, React.ReactElement>>;
  templateProps?: object;
}

// replaceTemplateProps :: ( {{{ token }}} , { token: Any } ) -> String
// template string replacement given templateProps
const replaceTemplateProps = (s: string, props: object) => {
  const pattern = /{{{(\w+?)}}}/g;
  return s.replace(pattern, (_, token) => props[token] || '');
};

const MultiCodeBlock: React.FC<Props> = (props) => {
  const {
    androidLanguage,
    iOSLanguage,
    serverCodeLanguage,
    reactNativeiOSLanguage,
    frontendCodeLanguage,
  } = useContext(Context);

  let codeBlocks: Partial<Record<CodeLanguage, React.ReactElement>> = {};

  const type = props.type != null ? props.type : 'server';

  // map of language to code block react components
  if (props.codeBlocks) {
    codeBlocks = props.codeBlocks;
  } else {
    props.children.forEach((child) => {
      codeBlocks = {
        ...codeBlocks,
        // oof
        [languageFromPrismClassName(
          child.props.children.props.className,
          type,
        )]: child,
      };
    });
  }

  // every language provided in the code sample
  const providedLanguages = Object.keys(codeBlocks);

  const typeToLanguagesMap: Record<
    Props['type'],
    Array<
      | AndroidCodeLanguage
      | IOSCodeLanguage
      | CodeLanguage
      | ReactNativeiOSCodeLanguage
      | FrontendCodeLanguage
    >
  > = {
    server: SERVER_LANGUAGES,
    ios: IOS_LANGUAGES,
    android: ANDROID_LANGUAGES,
    react_native_ios: REACT_NATIVE_IOS_LANGUAGES,
    frontend: FRONTEND_LANGUAGES,
  };

  const typeToContextMap: Record<
    Props['type'],
    | AndroidCodeLanguage
    | IOSCodeLanguage
    | CodeLanguage
    | ReactNativeiOSCodeLanguage
    | FrontendCodeLanguage
  > = {
    server: serverCodeLanguage,
    ios: iOSLanguage,
    android: androidLanguage,
    react_native_ios: reactNativeiOSLanguage,
    frontend: frontendCodeLanguage,
  };

  // default to server
  const languagesToEnforce: Array<
    | CodeLanguage
    | IOSCodeLanguage
    | AndroidCodeLanguage
    | ReactNativeiOSCodeLanguage
    | FrontendCodeLanguage
  > = typeToLanguagesMap[props.type != null ? props.type : 'server'];

  const everyLanguagePresent = languagesToEnforce.every((lang) =>
    providedLanguages.includes(lang),
  );

  if (
    !props.codeBlocks &&
    (providedLanguages.length === 0 || !everyLanguagePresent)
  ) {
    const missing = languagesToEnforce.filter(
      (lang) => !providedLanguages.includes(lang),
    );

    throw new Error(
      `MultiCodeBlock: Every ${type} code language must be represented in the example. Missing: ${missing}`,
    );
  }

  const MaybeMDXElement = codeBlocks[typeToContextMap[type]];

  if (props.codeBlocks) {
    return <>{MaybeMDXElement}</>;
  } else {
    // replace all template strings with templateProps
    const templatedChildren = replaceTemplateProps(
      MaybeMDXElement.props.children.props.children,
      props.templateProps,
    );

    // if MDX element, inject props.title into the children, but if props.codeBlocks was passed just use that component
    const CodeBlock = cloneElement(
      MaybeMDXElement,
      {},
      cloneElement(MaybeMDXElement.props.children, {
        title: props.title,
        type: props.type,
        children: templatedChildren,
      }),
    );
    return <>{CodeBlock}</>;
  }
};

MultiCodeBlock.displayName = 'MultiCodeBlock';
export default MultiCodeBlock;
