import React, { useContext, useRef, useEffect } from 'react';
import Link from 'next/link';
import cx from 'classnames';

import Context from '../../../contexts/docs';
import { getRoute, getSection } from '../utilities';

import styles from './SearchHit.module.scss';
import { Highlight } from 'react-instantsearch';

const pathsWithPageTitle = ['/docs/link'];

const GlossaryHit = ({ hit }) => (
  <span className={styles.container}>
    <div className={cx(styles.header, styles.glossaryHeader)}>
      <Highlight
        className={styles.noShrink}
        hit={hit}
        attribute='glossaryTitle'
      />
      <span className={styles.view}>View definition</span>
    </div>
    <div className={styles.hitDescription}>
      {hit.text || hit.glossaryDescription}
    </div>
  </span>
);

const APISchemaHit = ({ hit }) => {
  const pageTitle = pathsWithPageTitle.some((p) => hit.path.startsWith(p))
    ? getRoute(hit.path)?.title
    : null;

  return (
    <>
      <span className={styles.container}>
        <div className={styles.header}>
          <div className={styles.routeAndText}>
            {hit.apiEndpoint != null && (
              <span className={styles.route}>
                <Highlight
                  className={styles.noShrink}
                  hit={hit}
                  attribute='apiEndpoint'
                />
              </span>
            )}
            <span className={styles.schemaText}>
              <Highlight
                className={styles.noShrink}
                hit={hit}
                attribute='schemaText'
              />
            </span>
            {pageTitle != null && (
              <span className={styles.pageText}> {` - ${pageTitle}`}</span>
            )}
          </div>{' '}
          <span className={styles.view}>View object</span>
        </div>
        <div className={styles.hitDescription}>
          {/* misspelling "schmeaDescription" in algolia */}
          {hit.schmeaDescription || hit.schemaDescription}
        </div>
      </span>
    </>
  );
};

const OtherCategoryHit = ({ hit }) => (
  <span className={styles.container}>
    <div className={styles.categoryHeader}>
      <Highlight
        className={styles.noShrink}
        hit={hit}
        attribute='sectionSubtitle'
      />
      {hit._highlightResult.header && (
        <span className={styles.separator}>{' > '}</span>
      )}
      <Highlight className={styles.headerHits} hit={hit} attribute='header' />
    </div>
    <Highlight
      className={styles.hitDescription}
      hit={hit}
      attribute='description'
    />
  </span>
);

const SearchHit = ({ setURL, isSelected, hit, sendEvent }) => {
  const linkRef = useRef(null);

  useEffect(() => {
    if (linkRef.current != null && isSelected) {
      linkRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      setURL(urlWithAnchor); // sends selected URL to set url state in parent to use React router (for arrow keys selection)
    }
  });
  const { dispatch } = useContext(Context);
  const Route = getRoute(hit.path);
  const Section = getSection(hit.path);

  // If the search hit is not found in the ROUTE_MAP
  // it likely means the Algolia search index is out of sync
  // with the code. We will not treat this as a fatal error and
  // instead just omit that search hit.
  if (Route == null || Section == null) {
    return null;
  }
  const maybeAnchor = hit.anchor ? `#${hit.anchor}` : '';
  const urlWithAnchor = `${hit.path}${maybeAnchor}`;

  const handleSelect = () => {
    sendEvent('click', hit, 'Hit Clicked');
    dispatch({
      type: 'DOCS_SEARCH_RESULT',
      payload: {
        searchResultText: hit.sectionTitle + ' / ' + hit.sectionSubtitle,
        searchResultPath: urlWithAnchor,
        searchResultPosition: hit.__position,
      },
    });
  };

  return (
    <>
      <Link href={urlWithAnchor} key={hit.objectID} passHref>
        <a
          href={urlWithAnchor}
          tabIndex={hit.__position}
          className={cx(isSelected && styles.selected, styles.hit)}
          onClick={handleSelect}
          ref={linkRef}
        >
          {hit.type === 'glossary' && <GlossaryHit hit={hit} />}
          {hit.type === 'api' && <APISchemaHit hit={hit} />}
          {hit.type !== 'glossary' && hit.type !== 'api' && (
            <OtherCategoryHit hit={hit} />
          )}
        </a>
      </Link>
    </>
  );
};

SearchHit.displayName = 'SearchHit';

export default SearchHit;
