import React, { useContext } from 'react';
import algoliasearch from 'algoliasearch';
import cx from 'classnames';
import { InstantSearch } from 'react-instantsearch';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import DocsContext from 'src/contexts/docs';
import styles from './SearchDialog.module.scss';
import { MultipleQueriesResponse } from '@algolia/client-search';

declare global {
  interface Window {
    aa: (command: string, ...args: any[]) => void;
  }
}

/* --- Algolia - Plaid Docs Crawler - Testing --- */
// const searchIndex = 'crawler_plaid_docs_testing';
// const searchClient = algoliasearch(
//   '862NOJ201C',
//   '80c584b60e95780132289b38ef3b4963',
// );

/* --- Algolia - Plaid Docs Crawler - Production --- */
const searchIndex = 'crawler_plaid_docs';
const searchClient = algoliasearch(
  '862NOJ201C',
  '80c584b60e95780132289b38ef3b4963',
);

const searchClientWrapper = {
  ...searchClient,
  search<TObject = any>(requests) {
    if (requests.every(({ params }) => !params.query)) {
      // Empty request! Ignore it because we use the <EmptyState /> component
      // instead.
      // TODO: Maybe hits could be a call to a generateFakeHits() function
      // that returns a list of articles similar to what's being generated in
      // our EmptyState component.
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      }) as Promise<MultipleQueriesResponse<TObject>>;
    }
    return searchClient.search<TObject>(requests);
  },
};

const SearchDialog = ({ isVisible }) => {
  const [searchBox, setSearchBox] = React.useState(null);
  const { isLoggedIn, user } = useContext(DocsContext);

  React.useEffect(() => {
    let anonId = window.localStorage.getItem('ajs_anonymous_id') ?? '';
    anonId = anonId.replace(/^"(.*)"$/, '$1');
    if (window.aa && isVisible && anonId !== '') {
      window.aa('setUserToken', anonId);
    }
  }, [isVisible]);

  React.useEffect(() => {
    if (isLoggedIn && isVisible && user != null && window.aa) {
      window.aa('setAuthenticatedUserToken', user.userId);
    }
  }, [isLoggedIn, user, isVisible]);

  // Because algolia charges us with a search call every time the search box
  // is created, we want to lazily instantiate it the first time the box is set
  // to visible.
  React.useEffect(() => {
    if (isVisible && searchBox == null) {
      setSearchBox(
        <InstantSearch
          searchClient={searchClientWrapper}
          indexName={searchIndex}
          insights={true}
        >
          <SearchInput />
          <div className={styles.resultsContainer}>
            <SearchResults />
          </div>
        </InstantSearch>,
      );
    }
  }, [isVisible]);

  return (
    <>
      <div
        role='dialog'
        className={cx(styles.searchDialog, !isVisible && styles.hidden)}
      >
        {searchBox}
      </div>
    </>
  );
};

SearchDialog.displayName = 'SearchDialog';
export default SearchDialog;
