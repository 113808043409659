import Link from 'next/link';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import Touchable from 'plaid-threads/Touchable';

import Context from '../../../contexts/docs';

import styles from './HeaderLinks.module.scss';
import { addWordBreaks } from '../utilities';

export interface Props {
  onClose: () => void;
  mobile: boolean;
}

const HeaderLinks: React.FC<Props> = (props: Props) => {
  // use Docs context
  const {
    tableOfContents,
    tableOfContentsActiveItem,
    metadata,
    contentSelected,
  } = useContext(Context);
  const [currentCategory, setCurrentCategory] = useState(null); // for secondLevelToc pages
  const [allItems, setAllItems] = useState(null); // for secondLevelToc pages
  const [toc, setToc] = useState(tableOfContents);
  const lineLength = 22;

  const createAllItemsObject = useCallback(
    (toc) => {
      // create object with item arrays for each category
      const categoryItemsObject = {};
      let category;
      for (const item of toc) {
        if (item.level === metadata.parentTocLevel) {
          category = item.id;
          categoryItemsObject[category] = [item.id];
        } else if (item.level === metadata.childTocLevel) {
          if (categoryItemsObject[category] != null) {
            categoryItemsObject[category].push(item.id);
          }
        }
      }
      return categoryItemsObject;
    },
    [metadata],
  );
  // use setTimeout because the DOM needs to render BEFORE
  // setting state after contentswitcher is activated.
  useEffect(() => {
    if (tableOfContents != null) {
      setTimeout(
        () =>
          setToc(
            tableOfContents.filter((t) => {
              return document.getElementById(t.id) != null;
            }),
          ),
        0,
      );
    }
  }, [contentSelected, tableOfContents]);

  // useEffects for second-level TOC for large pages.  Items only shown for specific current category, based on active item.
  useEffect(() => {
    // set currenty category for second-level TOC
    if (tableOfContentsActiveItem === '') {
      setCurrentCategory(null);
    } else {
      for (const category in allItems) {
        if (allItems[category].includes(tableOfContentsActiveItem)) {
          setCurrentCategory(category);
        }
      }
    }
  }, [allItems, tableOfContentsActiveItem]);

  useEffect(() => {
    // create items object for second-level TOC
    if (metadata != null) {
      if (metadata.secondLevelToc) {
        setAllItems(createAllItemsObject(toc));
      }
    }
  }, [createAllItemsObject, metadata, toc]);

  return (
    <>
      {tableOfContents != null && tableOfContents.length > 0 && (
        <ul className={styles.headers}>
          {toc.map(
            (t, idx) =>
              (allItems == null || // single level case
                (allItems != null &&
                  (t.level === metadata.parentTocLevel || // category names
                    (metadata.alwaysExpand === true && // Always show parent and child TOCs
                      (t.level === metadata.childTocLevel ||
                        t.level === metadata.parentTocLevel)) ||
                    (currentCategory != null &&
                      allItems[currentCategory].includes(t.id))))) && ( // show all items in current category
                <li
                  className={styles.listItem}
                  aria-current={
                    tableOfContentsActiveItem === t.id ? 'location' : undefined
                  }
                  key={idx}
                >
                  <Touchable
                    onClick={props.mobile ? () => props.onClose() : null}
                  >
                    <Link href={`#${t.id}`} as={`#${t.id}`}>
                      <a
                        aria-current={
                          tableOfContentsActiveItem === t.id
                            ? 'location'
                            : undefined
                        }
                        className={cx(
                          styles.link,
                          tableOfContentsActiveItem === t.id && styles.active, // active item
                          metadata != null &&
                            t.level === metadata.childTocLevel &&
                            styles.endpoint,
                        )}
                      >
                        {metadata != null &&
                        t.level === metadata.parentTocLevel ? (
                          ''
                        ) : (
                          <span className={styles.hashmark}># </span>
                        )}
                        <span className={styles.title}>
                          {addWordBreaks(t.title)}
                        </span>
                      </a>
                    </Link>
                  </Touchable>
                </li>
              ),
          )}
        </ul>
      )}
    </>
  );
};

export default HeaderLinks;
