import React, { useState, useEffect, useContext } from 'react';
import DocsContext from '../../../contexts/docs';
import styles from './index.module.scss';
import Button from '../Button';
interface PopUpBannerProps {
  id: string;
  children: React.ReactNode;
}

const PopUpBanner: React.FC<PopUpBannerProps> = (props: PopUpBannerProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { dispatch, dismissedBannerIds } = useContext(DocsContext);

  // Check local storage to see if the banner was previously dismissed
  useEffect(() => {
    if (!dismissedBannerIds.includes(props.id)) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [dismissedBannerIds]);

  // add current banner id to the dismissed banner ids array.
  const dismissBanner = () => {
    dispatch({
      type: 'DISMISS_BANNER',
      payload: {
        id: props.id,
      },
    });
  };

  if (!isVisible) return null;

  return (
    <div className={styles.banner}>
      <div className={styles.contentWrapper}>{props.children}</div>
      <Button
        secondary
        centered
        size={40}
        className={styles.dontShowButton}
        onClick={dismissBanner}
      >
        Don't show again
      </Button>
    </div>
  );
};

PopUpBanner.displayName = 'PopUpBanner';

export default PopUpBanner;
