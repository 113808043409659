export const toCamelCase = (str) => {
  return str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  );
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatComment = (comment, commentChar, lineLength = 55) => {
  if (!comment || comment.length === 0) {
    return '';
  }
  // Split the comment into lines that are less than lineLength characters
  const lines = [];
  let currentLine = '';
  comment.split(' ').forEach((word) => {
    if (currentLine.length + word.length > lineLength - commentChar.length) {
      lines.push(currentLine);
      currentLine = '';
    }
    currentLine += `${word} `;
  });
  lines.push(currentLine);

  // Return the lines with a double slash before each one
  return lines
    .map((line) => `${commentChar}${line}`)
    .join('\n')
    .concat('\n');
};
