import React from 'react';
import cx from 'classnames';
import Image from '../../Image';

import styles from './index.module.scss';

interface Props {
  asset: string;
  children: React.ReactNode;
  hideBorder?: boolean;
  step?: number;
}

const StepWithDiagram: React.FC<Props> = (props: Props) => (
  <div className={styles.container}>
    <div
      className={cx(
        styles.stepContent,
        props.hideBorder && styles.hideBorder,
        props.step === 1 && styles.firstStep,
      )}
    >
      {props.step && (
        <span className={styles.stepNumber}>
          <b>{props.step}</b>
        </span>
      )}
      <span>{props.children}</span>
    </div>
    <div className={styles.rightColumn}>
      <Image
        className={styles.asset}
        src={props.asset}
        alt={`Step ${props.step == null ? '' : props.step} diagram`}
      />
    </div>
  </div>
);

StepWithDiagram.displayName = 'StepWithDiagram';

export default StepWithDiagram;
