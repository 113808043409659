import { ErrorReference } from '../../types';

// Invalid Input errors are hard-coded at:
// https://github.plaid.com/plaid/go/blob/master/lib/apischemas/apierrors/invalid_input.go

export const INVALID_API_KEYS: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_API_KEYS',
    error_message: 'invalid client_id or secret provided',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

// TODO: verify that this is only for RUX
export const USER_MFA_EXPIRED: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'USER_MFA_EXPIRED',
    error_message:
      'The MFA code has expired and can no longer be used for verification. Request a new code to restart verification.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const USER_PERMISSION_REVOKED: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'USER_PERMISSION_REVOKED',
    error_message:
      'the holder of this account has revoked their permission for your application to access it',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const UNAUTHORIZED_ENVIRONMENT: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'UNAUTHORIZED_ENVIRONMENT',
    error_message:
      'you are not authorized to create items in this api environment. Go to the Dashboard (https://dashboard.plaid.com) to see which environments you are authorized for.',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'Unauthorized environment',
    link_error_message:
      'Your Client ID is not authorized to access this API environment. Contact Support to gain access',
    link_error_icon: 'Error',
    link_error_button: 'Exit',
    error_code: 'UNAUTHORIZED_ENVIRONMENT',
  },
};

export const INVALID_ACCESS_TOKEN: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_ACCESS_TOKEN',
    error_message: 'could not find matching access token',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_PUBLIC_TOKEN: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_PUBLIC_TOKEN',
    error_message: 'could not find matching public token',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_PRODUCT: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_PRODUCT',
    error_message:
      'client is not authorized to access the following products: ["identity_verification"]',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const ADDITIONAL_CONSENT_REQUIRED: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'ADDITIONAL_CONSENT_REQUIRED',
    error_message:
      'client does not have user consent to access the PRODUCT_AUTH product',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_ACCOUNT_ID: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_ACCOUNT_ID',
    error_message: 'failed to find requested account ID for requested item',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_STRIPE_ACCOUNT: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_STRIPE_ACCOUNT',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_LINK_TOKEN: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_LINK_TOKEN',
    error_message: 'invalid link_token provided',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'Username or password incorrect',
    link_error_icon: 'close',
    link_error_message:
      'Check that your credentials are the same that you use for this institution',
    link_error_button: 'Try again',
    error_code: 'INVALID_LINK_TOKEN',
  },
};

export const UNAUTHORIZED_ROUTE_ACCESS: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'UNAUTHORIZED_ROUTE_ACCESS',
    error_message:
      'you are not authorized to access this route in this api environment.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const DIRECT_INTEGRATION_NOT_ENABLED: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'DIRECT_INTEGRATION_NOT_ENABLED',
    error_message:
      'your client ID is only authorized to use Plaid Link. head to the docs (https://plaid.com/docs) to get started.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_AUDIT_COPY_TOKEN: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_AUDIT_COPY_TOKEN',
    error_message: null,
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_PROCESSOR_TOKEN: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_PROCESSOR_TOKEN',
    error_message: null,
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_CREDENTIAL_FIELDS: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_CREDENTIAL_FIELDS',
    error_message: null,
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INCORRECT_DEPOSIT_VERIFICATION = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INCORRECT_DEPOSIT_VERIFICATION',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'Code is incorrect, 2 attempts remaining',
    link_error_message:
      'Check the bank statement of your account ending in •••0000 for the $0.01 deposit and try again.',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'INCORRECT_DEPOSIT_VERIFICATION',
  },
};

export const TOO_MANY_VERIFICATION_ATTEMPTS = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'TOO_MANY_VERIFICATION_ATTEMPTS',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'No attempts remaining',
    link_error_message:
      'You’ve used up all of your attempts. To continue, connect a different bank',
    link_error_icon: 'close',
    link_error_button: 'Exit',
    error_code: 'TOO_MANY_VERIFICATION_ATTEMPTS',
  },
};

export const INVALID_INSTITUTION: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_INSTITUTION',
    error_message: 'invalid institution_id provided',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const PLAID_DIRECT_INTEGRATION_NOT_ENABLED: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'PLAID_DIRECT_INTEGRATION_NOT_ENABLED',
    error_message:
      'Your client ID is not set up to import items. Contact your account manager to get started.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_WEBHOOK_VERIFICATION_KEY_ID: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_WEBHOOK_VERIFICATION_KEY_ID',
    error_message:
      'invalid key_id provided. note that key_ids are specific to Plaid environments, and verification requests must be made to the same environment that the webhook was sent from',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_LINK_CUSTOMIZATION: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_LINK_CUSTOMIZATION',
    error_message:
      'requested link customization is not set to update account selection',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_USER_TOKEN: ErrorReference = {
  server: {
    error_type: 'INVALID_INPUT',
    error_code: 'INVALID_USER_TOKEN',
    error_message: 'could not find matching user token',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
