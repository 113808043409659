import React from 'react';

import { ErrorType } from '../../types';
import { withBasePath } from '../../utilities';
import Table from '..';
import styles from './Error.module.scss';

interface Props {
  name: string; // an endpoint
  type: ErrorType; // used to create full path
  url?: string; // use explicit url if name is not an endpoint
  description: string;
}

const Error: React.FC<Props> = (props) => {
  const path = props.url != null ? props.url : `#${props.name.toLowerCase()}`;
  const fullPath = withBasePath(`/errors/${props.type}/${path}`);

  return (
    <Table.ContentItem
      name={props.name}
      href={fullPath}
      description={props.description}
      className={styles.error}
    />
  );
};

Error.displayName = 'Error';

export default Error;
