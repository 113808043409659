import React from 'react';

import { Partnerships as PartnershipsT } from '../../types';

interface PartnerProps {
  p: PartnershipsT;
}

const Partner: React.FC<PartnerProps> = (props: PartnerProps) => <>{props.p}</>;

export default Partner;
