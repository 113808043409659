import { NavItem, NavGroup } from '../../docs/constants/routeMap';

import Guide from 'plaid-threads/Icons/Guide';
import Help from 'plaid-threads/Icons/Help';
import Home from 'plaid-threads/Icons/Home';
import Changelog from 'plaid-threads/Icons/Changelog';
import Terminal from 'plaid-threads/Icons/Terminal';
import Book from 'plaid-threads/Icons/Book';
import Key from 'plaid-threads/Icons/Key';
import Clipboard from 'plaid-threads/Icons/Clipboard';
import User from 'plaid-threads/Icons/User';
import List from 'plaid-threads/Icons/List';

export const API_OVERVIEW: NavGroup = [
  {
    children: [
      {
        title: 'API overview',
        path: '/overview',
      },
    ],
  },
];

export const API_REFERENCE: NavGroup = [
  {
    children: [
      {
        title: 'v6.1',
        path: '/reference/6.1',
      },
      {
        title: 'v6.0',
        path: '/reference/6.0',
      },
      {
        title: 'v5.3',
        path: '/reference/5.3',
      },
      {
        title: 'v5.2',
        path: '/reference/5.2',
      },
      {
        title: 'v5.1',
        path: '/reference/5.1',
      },
      {
        title: 'v5.0',
        path: '/reference/5.0',
      },
      {
        title: 'v4.6',
        path: '/reference/4.6',
      },
    ],
  },
];

export const EXPERIENCE: NavGroup = [
  {
    children: [
      {
        title: 'User experience',
        path: '/user-experience',
        icon: Key,
        highlight: true,
      },
    ],
  },
];

export const AUTH: NavGroup = [
  {
    children: [
      {
        title: 'Authentication',
        path: '/authentication',
        icon: Key,
        highlight: true,
      },
    ],
  },
];

const SUPPORT: NavGroup = [
  {
    children: [{ title: 'Overview', path: '/support' }],
  },
];

export const ROUTES: NavGroup = [
  {
    subNav: false,
    highlight: false,
    children: [
      { title: 'Home', path: '/', icon: Home, highlight: true, subNav: false },
      {
        title: 'Implementation checklist',
        path: '/implementation-checklist',
        icon: Clipboard,
        highlight: true,
      },
      {
        title: 'Authentication',
        path: '/authentication',
        icon: Key,
      },
      {
        title: 'User experience',
        path: '/user-experience',
        icon: User,
      },
      {
        title: 'Mock server',
        path: '/mock-server',
        icon: Terminal,
      },
      {
        title: 'API reference',
        path: '/reference',
        icon: Guide,
        children: API_REFERENCE,
      },
      {
        title: 'Changelog',
        path: '/changelog',
        icon: Changelog,
        highlight: true,
      },
    ],
  },
];

// everything
export const ROUTE_MAP: NavGroup = [...ROUTES];

const flattenNavGroup = (group: NavGroup): Array<NavItem> =>
  group.reduce((acc, item) => {
    const res: Array<NavItem> = [];
    // if this item has properties,
    // push item to results
    if (item.path != null && item.title != null) {
      res.push(item);
    }
    // process children if they exist
    if (item.children != null) {
      res.push(...flattenNavGroup(item.children));
    }
    return acc.concat(res);
  }, []);

export const ROUTE_MAP_FLAT = flattenNavGroup(ROUTE_MAP);
