import { SchemaObject } from 'openapi3-ts';

// Decreases the left margin for objects that are deeply nested
const ADJUST_INDENT_AFTER_DEPTH = 2;
const DEFAULT_INDENT = 2.0;
const MINIMUM_INDENT = 0.5;
const SHRINK_INCREMENT = 0.4;
const UNIT_MULTIPLIER_FROM_CSS_VAR = 0.8;

/**
 * Determines just how much we should be indenting each schema row based on its
 * depth level and the overall depth of the object. This lets us subtly
 * decrease the indent size for very large objects
 *
 *
 * @param currentDepth The current depth of the schema row
 * @param maximumDepth The maximum depth of the schema object, as determined by calling "getSchemaDepth"
 * @returns The amount, in rems, that we should
 */
const getIndentLevel = (currentDepth: number, maximumDepth: number) => {
  const indentInRems =
    maximumDepth > ADJUST_INDENT_AFTER_DEPTH && currentDepth > 0
      ? Math.max(
          MINIMUM_INDENT,
          DEFAULT_INDENT -
            SHRINK_INCREMENT * (maximumDepth - ADJUST_INDENT_AFTER_DEPTH),
        )
      : DEFAULT_INDENT;
  return indentInRems * UNIT_MULTIPLIER_FROM_CSS_VAR;
};

/**
 * A utility function that analyzes our schema objects to find out just how
 * deeply nested they are -- primarily used to determine how aggressive we are
 * with our indents.
 * TODO: Should we memoize this?
 *
 * @param schemaItem The schema request or response object we're analyzing
 * @returns How deep the schema object is nested
 */
const getSchemaDepth = (schemaItem: SchemaObject): number => {
  let maxSoFar = 0;

  if (schemaItem.properties != null) {
    maxSoFar = Math.max(
      maxSoFar,
      ...Object.values(schemaItem.properties).map(
        (nextVal) => 1 + getSchemaDepth(nextVal),
      ),
    );
  } else {
    const schemaItemSubItems: SchemaObject = schemaItem.items;
    if (schemaItemSubItems != null && schemaItemSubItems.properties != null) {
      maxSoFar = Math.max(
        maxSoFar,
        ...Object.values(schemaItemSubItems.properties).map(
          (nextVal) => 1 + getSchemaDepth(nextVal),
        ),
      );
    }
  }
  return maxSoFar;
};

export { getIndentLevel, getSchemaDepth };
