import React from 'react';
import Link from 'next/link';

import { withTrailingSlash } from '../utilities';

import Image from '../Image';

import styles from './ProductCard.module.scss';
interface Props {
  title: string;
  subtitle: string;
  link: string;
  src: string;
}

const ProductCard: React.FC<Props> = (props: Props) => (
  <div className={styles.productCardWrapper}>
    <Link href={withTrailingSlash(props.link)}>
      <a className={styles.card}>
        <Image className={styles.icon} src={props.src} alt='' />
        <div className={styles.text}>
          <h4 className={styles.productCardTitle}>{props.title}</h4>
          <p className={styles.productCardSubtitle}>{props.subtitle}</p>
        </div>
      </a>
    </Link>
  </div>
);

ProductCard.displayName = 'ProductCard';

export default ProductCard;
