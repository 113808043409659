import { ErrorReference } from '../../types';

export const INTERNAL_SERVER_ERROR: ErrorReference = {
  server: {
    error_type: 'API_ERROR',
    error_code: 'INTERNAL_SERVER_ERROR',
    error_message: 'an unexpected error occurred',
    display_message: null,
    http_code: 500,
  },
  link: {
    link_error_heading: 'Something went wrong',
    link_error_message:
      "There was a problem and we couldn't connect your account. Try entering your username and password again.",
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'INTERNAL_SERVER_ERROR',
  },
};

export const PLANNED_MAINTENANCE: ErrorReference = {
  server: {
    error_type: 'API_ERROR',
    error_code: 'PLANNED_MAINTENANCE',
    error_message:
      'the Plaid API is temporarily unavailable due to planned maintenance. visit https://status.plaid.com/ for more information',
    display_message: null,
    http_code: 503,
  },
  link: {
    link_error_heading: 'Please try connecting a different account',
    link_error_message:
      'There was a problem processing your request. Your account could not be connected at this time',
    link_error_icon: 'close',
    link_error_button: 'Restart',
    error_code: 'PLANNED_MAINTENANCE',
  },
};
