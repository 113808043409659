import Layout from '../Layout';
import AcceleratePage from 'src/components/accelerate/Page';
import ExchangePage from 'src/components/exchange-docs/Page';
import CoreExchangePage from 'src/components/core-exchange-docs/Page';
import { ACCELERATE_BASE_URL } from 'src/components/accelerate/constants';
import { EXCHANGE_DOCS_BASE_URL } from 'src/components/exchange-docs/constants';
import { CORE_EXCHANGE_DOCS_BASE_URL } from 'src/components/core-exchange-docs/constants';

export const GetPageComponent = (pathname: string) => {
  if (pathname.startsWith(ACCELERATE_BASE_URL)) {
    return AcceleratePage;
  } else if (pathname.startsWith(EXCHANGE_DOCS_BASE_URL)) {
    return ExchangePage;
  } else if (pathname.startsWith(CORE_EXCHANGE_DOCS_BASE_URL)) {
    return CoreExchangePage;
  }
  return Layout.Page;
};
