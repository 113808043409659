import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './Row.module.scss';

interface Props {
  subtitle: string;
  title: string;
  titleLink?: string;
  children?: Array<React.ReactNode>;
  columns?: number;
}

const Row: React.FC<Props> = (props) => {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, [setWidth]);

  let firstHalf = [];
  let secondHalf = [];
  if (props.children && props.columns > 2) {
    const numberOfItems = props.children.length;
    if (numberOfItems > 1) {
      const childrenArray = [...props.children];
      firstHalf = childrenArray.splice(0, Math.ceil(numberOfItems / 2));
      secondHalf = childrenArray;
    } else {
      firstHalf = props.children;
      secondHalf = [];
    }
  }

  return (
    <tr
      className={cx(
        styles.row,
        width > 1200 && props.columns === 3 && styles.threeColRow,
      )}
    >
      <td className={styles.description}>
        <div className={styles.title}>
          {props.titleLink ? (
            <a className={styles.titleLink} href={props.titleLink}>
              {props.title}
            </a>
          ) : (
            props.title
          )}
        </div>
        <div className={styles.subtitle}>{props.subtitle}</div>
      </td>
      {width > 1200 && props.columns === 3 && (
        <>
          <td className={styles.contents}>{firstHalf}</td>
          <td className={styles.contents}>{secondHalf}</td>
        </>
      )}
      {/* default is no columns prop and there are two columns */}
      {(props.columns! !== 3 || width <= 1200) && (
        <td className={styles.contents}>{props.children}</td>
      )}
    </tr>
  );
};

Row.displayName = 'Row';

export default Row;
