import { useRouter } from 'next/router';
import useUser from 'src/contexts/docs/useUser';
import ErrorPage from '../ErrorPage';
import { TableOfContents } from '../types';
import LoggedInWrapper from '../LoggedInWrapper';
import Link from 'next/link';

interface Props {
  children: JSX.Element;
  flag: string | undefined;
}

export const FlagWrapper = (props: Props) => {
  const { getFlagValue, isLoggedIn } = useUser();
  const flagNotPresent = props.flag === undefined;

  const errorPage = (
    <ErrorPage
      header='This page is not available'
      subheader={
        isLoggedIn ? (
          'You do not have access to this page'
        ) : (
          <Link href='https://dashboard.plaid.com/signin?redirect=docs'>
            Log in to view this page
          </Link>
        )
      }
      body='If you believe this is a mistake, please contact your Account Manager'
    />
  );

  return (
    <LoggedInWrapper
      loggedInComponent={
        getFlagValue(props.flag) || flagNotPresent ? props.children : errorPage
      }
      loggedOutComponent={flagNotPresent ? props.children : errorPage}
    />
  );
};
