import React from 'react';
import { SchemaObject } from 'openapi3-ts';

interface Props {
  schemaItem: SchemaObject;
  simplify?: boolean;
}

const AdditionalProperties: React.FC<Props> = (props) => {
  const additionalPropertyLabels = {
    minimum: 'Minimum',
    maximum: 'Maximum',
    default: 'Default',
    minItems: 'Min items',
    maxItems: 'Max items',
    multipleOf: 'Multiple of',
    exclusiveMinimum: 'Exclusive min',
    exclusiveMaximum: 'Exclusive max',
    format: 'Format',
    minLength: 'Min length',
    maxLength: 'Max length',
    pattern: 'Pattern',
    enum: 'Possible values',
  };
  //the following schemaItemSubItems variable needs to be declared or typescript will not let you access
  //props.schemaItem.items.enum it when rendering
  const schemaItemSubItems: SchemaObject = props.schemaItem.items;
  const additionalPropertiesArray = [];
  const itemsArray = [];
  //populate arrays with keys
  for (const key in props.schemaItem) {
    if (key === 'items') {
      for (const itemsKey in schemaItemSubItems) {
        if (additionalPropertyLabels[itemsKey]) {
          itemsArray.push(itemsKey);
        }
      }
    }
    if (additionalPropertyLabels[key]) {
      additionalPropertiesArray.push(key);
    }
  }

  if (props.simplify) {
    // Remove any "format" property from the list of additional properties
    const formatIndex = additionalPropertiesArray.indexOf('format');
    if (formatIndex !== -1) {
      additionalPropertiesArray.splice(formatIndex, 1);
    }

    // Also, let's make some of the labels more verbose!
    additionalPropertyLabels.enum = 'This is an enum. Possible values are';
    additionalPropertyLabels.minimum = 'The minimum value is';
    additionalPropertyLabels.maximum = 'The maximum value is';
    additionalPropertyLabels.default = 'The default value is';
  }

  const enumOverrideArray =
    props.schemaItem['x-override-enum-values-shown'] || [];
  const shouldOverrideEnum = enumOverrideArray.length !== 0;
  let anyAdditionalProps = additionalPropertiesArray.length !== 0;
  const anyItems = itemsArray.length !== 0;
  const isThereAnEnumSomewhere =
    additionalPropertiesArray.includes('enum') || itemsArray.includes('enum');
  if (shouldOverrideEnum && !isThereAnEnumSomewhere) {
    // We have a non-enum value with an enum-override. We'll add an enum
    // property so the "Possible values" list gets displayed.
    additionalPropertiesArray.push('enum');
    anyAdditionalProps = true;
  }

  if (!anyAdditionalProps && !anyItems) {
    return null;
  }
  return (
    <>
      <br />
      {anyAdditionalProps && <br />}
      {anyAdditionalProps &&
        additionalPropertiesArray.map((property) => {
          if (property !== 'enum') {
            return (
              additionalPropertyLabels[property] && (
                <div key={additionalPropertyLabels[property]}>
                  {`${additionalPropertyLabels[property]}: `}
                  <code>{props.schemaItem[property].toString()}</code>{' '}
                  {/* toString() addresses boolean values */}
                </div>
              )
            );
          }

          return (
            <div key={additionalPropertyLabels[property]}>
              {`${additionalPropertyLabels[property]}: `}
              {(shouldOverrideEnum
                ? enumOverrideArray
                : props.schemaItem.enum
              ).map((enumVal, idx) => {
                return (
                  <span key={idx}>
                    {idx === 0 ? '' : ', '}
                    <code>{enumVal === null ? 'null' : enumVal}</code>
                  </span>
                );
              })}
            </div>
          );
        })}
      {!anyAdditionalProps && anyItems && <br />}
      {anyItems &&
        itemsArray.map((property) => {
          if (property !== 'enum') {
            return (
              additionalPropertyLabels[property] && (
                <div key={additionalPropertyLabels[property]}>
                  {`${additionalPropertyLabels[property]}: `}
                  <code>
                    {props.schemaItem.items[property].toString()}
                  </code>{' '}
                </div>
              )
            );
          }
          return (
            <div key={additionalPropertyLabels[property]}>
              {`${additionalPropertyLabels[property]}: `}
              {(shouldOverrideEnum
                ? enumOverrideArray
                : schemaItemSubItems.enum
              ).map((enumVal, idx) => {
                return (
                  <span key={idx}>
                    {idx === 0 ? '' : ', '}
                    <code>{enumVal === null ? 'null' : enumVal}</code>
                  </span>
                );
              })}
            </div>
          );
        })}
    </>
  );
};

AdditionalProperties.displayName = 'AdditionalProperties';

export default AdditionalProperties;
