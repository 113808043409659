import React, { useState, useEffect, useContext } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cx from 'classnames';
import NavigationLink from 'plaid-threads/NavigationLink';

import HeaderLinks from './HeaderLinks';
import { NavItem } from '../constants/routeMap';
import { withBasePath } from '../utilities';

import styles from './SidebarSubnav.module.scss';
import useUser from 'src/contexts/docs/useUser';

import { DOCS_BASE_URL } from '../constants';
import DocsContext from 'src/contexts/docs';

// case where item is simply a page and not a dropdown

interface ListItemProps {
  pathname: string;
  href: string;
  basePath: string;
  item: NavItem;
  onClose: () => void;
  mobile?: boolean;
  flag?: string;
  title: string;
}
const ListItem: React.FC<ListItemProps> = (props: ListItemProps) => {
  const [open, setOpen] = useState(
    withBasePath(props.item.path, props.basePath) === props.pathname,
  );
  const { isLoggedIn } = useContext(DocsContext);
  const { getFlagValue } = useUser();

  useEffect(() => {
    const isIndexRouteActive =
      withBasePath(props.item.path, props.basePath) === props.pathname;
    setOpen(isIndexRouteActive);
  }, [props.basePath, props.item.path, props.pathname]);
  return (
    <>
      {(props.flag == null || (isLoggedIn && getFlagValue(props.flag))) && (
        <>
          <Link href={props.href} as={`${props.href}`}>
            <a
              className={cx(styles.listItem, open && styles.active)}
              aria-current={open ? 'page' : undefined}
            >
              {props.title}
            </a>
          </Link>
          {open && (
            <HeaderLinks onClose={props.onClose} mobile={props.mobile} />
          )}
        </>
      )}
    </>
  );
};

// if item has children, render a ListDropdown
interface ListDropdownProps {
  pathname: string;
  basePath?: string;
  item: NavItem;
  onClose: () => void;
  mobile?: boolean;
}

const ListDropdown: React.FC<ListDropdownProps> = (
  props: ListDropdownProps,
) => {
  const [open, setOpen] = useState(false);

  const basePath = props.basePath ?? DOCS_BASE_URL;
  // on route-change / page-load, set dropdown as open or closed
  useEffect(() => {
    const isSubrouteActive = props.item.children
      .map((r) => withBasePath(r.path, basePath))
      .includes(props.pathname);
    const isIndexRouteActive =
      props.pathname.indexOf(withBasePath(props.item.path, basePath)) > -1;
    // if there is a section title with no path, dropdown should always be open
    const isSectionDropDown = props.item.path == null ? true : false;

    setOpen(isSubrouteActive || isIndexRouteActive || isSectionDropDown);
  }, [basePath, props.item.children, props.item.path, props.pathname]);

  return (
    <div>
      {props.item.path == null && ( // section title with no path; dropdown always open
        <h5 className={styles.subNavTitle}>{props.item.title}</h5>
      )}

      {props.item.path != null && ( // title with path; when clicked, setOpen(true) is triggered
        <Link href={withBasePath(props.item.path, basePath)}>
          <a
            className={cx(
              styles.listItem,
              withBasePath(props.item.path, basePath) === props.pathname &&
                styles.active,
            )}
            aria-current={
              withBasePath(props.item.path, basePath) === props.pathname
                ? 'page'
                : undefined
            }
          >
            {props.item.title}
          </a>
        </Link>
      )}
      {open && withBasePath(props.item.path, basePath) === props.pathname && (
        <HeaderLinks onClose={props.onClose} mobile={props.mobile} />
      )}
      {open && (
        <ul
          className={cx(
            props.item.path != null && styles.listDropdown,
            props.item.path == null && styles.noPadding,
          )}
        >
          {props.item.children.map((r, idx) => (
            <li key={`link-${idx}`}>
              {r.children == null ? ( // renders either just a link or another ListDropdown
                <>
                  <Link href={withBasePath(r.path, basePath)}>
                    <a
                      className={cx(
                        styles.listItem,
                        withBasePath(r.path, basePath) === props.pathname &&
                          styles.active,
                      )}
                      aria-current={
                        withBasePath(r.path, basePath) === props.pathname
                          ? 'page'
                          : undefined
                      }
                    >
                      {r.title}
                    </a>
                  </Link>

                  {withBasePath(r.path, basePath) === props.pathname && (
                    <HeaderLinks
                      onClose={props.onClose}
                      mobile={props.mobile}
                    />
                  )}
                </>
              ) : (
                <ListDropdown
                  pathname={props.pathname}
                  item={r}
                  key={idx}
                  onClose={props.onClose}
                  mobile={props.mobile}
                />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

interface Props {
  currentRoute: NavItem;
  basePath?: string;
  onClose: () => void;
  mobile?: boolean;
}

const Section: React.FC<Props> = (props: Props) => {
  const { pathname } = useRouter();

  return (
    <section className={styles.section}>
      {props.currentRoute.title != null && (
        <h5 className={styles.sectionTitle}>{props.currentRoute.title}</h5>
      )}

      <ul className={styles.sectionList}>
        {props.currentRoute.children &&
          props.currentRoute.children.map((item, idx) =>
            item.disabled !== true ||
            pathname === withBasePath(item.path, props.basePath) ? (
              <li
                key={idx}
                aria-current={
                  pathname === withBasePath(item.path, props.basePath)
                    ? 'page'
                    : undefined
                }
              >
                {item.children == null ? (
                  <ListItem
                    pathname={pathname}
                    title={item.title}
                    flag={item.flag}
                    basePath={props.basePath}
                    href={withBasePath(item.path, props.basePath)}
                    key={idx}
                    item={item}
                    onClose={props.onClose}
                    mobile={props.mobile}
                  />
                ) : (
                  <ListDropdown
                    onClose={props.onClose}
                    mobile={props.mobile}
                    pathname={pathname}
                    basePath={props.basePath}
                    item={item}
                    key={idx}
                  />
                )}
              </li>
            ) : null,
          )}
      </ul>
    </section>
  );
};

interface SidebarSubnavProps {
  currentRoute: NavItem;
  baseUrl: string;
  onClose: () => void;
  mobile?: boolean;
}

const SidebarSubnav: React.FC<SidebarSubnavProps> = (
  props: SidebarSubnavProps,
) => (
  <div className={styles.container}>
    <Link href={props.baseUrl} passHref>
      <NavigationLink className={styles.backLink}>ALL DOCS</NavigationLink>
    </Link>

    <h4 className={styles.title}>
      {props.currentRoute && props.currentRoute.icon && (
        <props.currentRoute.icon className={styles.titleIcon} />
      )}
      {props.currentRoute && props.currentRoute.title}
    </h4>

    {props.currentRoute &&
      props.currentRoute.children &&
      props.currentRoute.children.map((item, i) => (
        <Section
          onClose={props.onClose}
          mobile={props.mobile}
          currentRoute={item}
          basePath={props.baseUrl}
          key={i}
        />
      ))}
    {(!props.currentRoute ||
      props.currentRoute.children == null ||
      props.currentRoute.children.length === 0) && (
      <HeaderLinks onClose={props.onClose} mobile={props.mobile} />
    )}
  </div>
);

SidebarSubnav.displayName = 'SidebarSubnav';
export default SidebarSubnav;
