import React from 'react';
import Link from 'next/link';

import InlineLink from 'plaid-threads/InlineLink';

import { isAbsoluteUrl, isExternalUrl, withTrailingSlash } from '../utilities';

interface Props {
  href: string;
  children: string;
  className?: string;
}

const AnchorLink = (props: Props) => {
  const target = '_blank';
  const rel = 'noopener';
  const alternateProtocols = ['mailto', 'tel'];

  // https://, tel:// etc
  const protocol = props.href.slice(0, props.href.indexOf(':'));
  // is this a link to another website or an internal link to other plaid docs
  const isExternalLink = isExternalUrl(props.href);
  // only add trailing slash to internal links
  const href = isExternalLink ? props.href : withTrailingSlash(props.href);

  // if a[href] is an external link, set target + rel props
  if (isExternalLink && isAbsoluteUrl(href)) {
    return (
      <Link href={href}>
        <a target={target} rel={rel} className={props.className}>
          {props.children}
        </a>
      </Link>
    );
  }
  if (alternateProtocols.includes(protocol)) {
    return <a href={href}>{props.children}</a>;
  }
  // default: internal docs link with no special props
  return (
    <Link href={href} passHref>
      <a href={href}>{props.children}</a>
    </Link>
  );
};

AnchorLink.displayName = 'AnchorLink';
export default AnchorLink;
