import { ErrorReference } from '../../types';

export const PLAID_DIRECT_ITEM_IMPORT_RETURNED_INVALID_MFA: ErrorReference = {
  server: {
    error_type: 'INVALID_RESULT',
    error_code: 'PLAID_DIRECT_ITEM_IMPORT_RETURNED_INVALID_MFA',
    error_message: 'Plaid Direct Item Imports should not result in MFA.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const LAST_UPDATED_DATETIME_OUT_OF_RANGE: ErrorReference = {
  server: {
    error_type: 'INVALID_RESULT',
    error_code: 'LAST_UPDATED_DATETIME_OUT_OF_RANGE',
    error_message:
      'requested datetime out of range, most recently updated balance 2021-01-01T00:00:00Z',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
