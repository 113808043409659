
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';
import ContentSwitcher from '../../ContentSwitcher';

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <MultiCodeBlock type='server' mdxType="MultiCodeBlock">
      <pre><code parentName="pre" {...{
          "className": "language-node",
          "metastring": "#Generating a Link token with the transfer intent ID",
          "#Generating": true,
          "a": true,
          "Link": true,
          "token": true,
          "with": true,
          "the": true,
          "transfer": true,
          "intent": true,
          "ID": true
        }}>{`// Using Express
app.post('/api/create_link_token', async function (request, response) {
  const configs = {
    user: {
      // This should correspond to a unique id for the current user.
      client_user_id: 'user-id',
    },
    client_name: 'Plaid Test App',
    products: [Products.Transfer],
    language: 'en',
    webhook: 'https://webhook.sample.com',
    country_codes: ['US'],
    transfer: {
      intent_id: transferIntentId,
    },
    link_customization_name: process.env.LINK_CUSTOMIZATION_NAME,
  };
  try {
    const createTokenResponse = await client.linkTokenCreate(configs);
    response.json(createTokenResponse.data);
  } catch (error) {
    // handle error
  }
});
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`curl -X POST https://sandbox.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "Plaid Test App",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "products": ["transfer"],
  "country_codes": ["US"],
  "language": "en",
  "webhook": "https://webhook.sample.com",
  "transfer": { "intent_id": "\${INTENT_ID}" },
  "link_customization_name": "\${LINK_CUSTOMIZATION_NAME}",
}'
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-ruby",
          "metastring": "#Generating a Link token with the transfer intent ID",
          "#Generating": true,
          "a": true,
          "Link": true,
          "token": true,
          "with": true,
          "the": true,
          "transfer": true,
          "intent": true,
          "ID": true
        }}>{`require 'sinatra'
require 'plaid'

post '/api/create_link_token' do
  # Create a link_token for the given user
  transfer_intent_id = Plaid::LinkTokenCreateRequestTransfer.new(
    {
      intent_id: "TRANSFER_INTENT_ID"
    }
  )
  link_token_create_request = Plaid::LinkTokenCreateRequest.new(
    {
      user: { client_user_id: 'user-id' },
      client_name: 'Plaid Test App',
      products: ['transfer'],
      country_codes: ['US'],
      language: "en",
      redirect_uri: nil_if_empty_envvar('PLAID_REDIRECT_URI'),
      webhook: 'https://webhook.sample.com',
      transfer: transfer_intent_id,
      link_customization_name: process.env.LINK_CUSTOMIZATION_NAME,
    }
  )
  response = client.link_token_create(link_token_create_request)
  content_type :json
  response.to_json
end
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-java",
          "metastring": "#Generating a Link token with the transfer intent ID",
          "#Generating": true,
          "a": true,
          "Link": true,
          "token": true,
          "with": true,
          "the": true,
          "transfer": true,
          "intent": true,
          "ID": true
        }}>{`import com.plaid.client.model.LinkTokenCreateRequest;
import com.plaid.client.model.LinkTokenCreateRequestUser;
import com.plaid.client.model.LinkTokenCreateResponse;


static class GetLinkToken implements HttpHandler {
  private static PlaidClient plaidClient;

  public void handle(HttpExchange t) throws IOException {
    // Create your Plaid client
    HashMap<String, String> apiKeys = new HashMap<String, String>();
    apiKeys.put("clientId", CLIENT_ID);
    apiKeys.put("secret", SECRET);
    apiKeys.put("plaidVersion", "2020-09-14");
    apiClient = new ApiClient(apiKeys);
    apiClient.setPlaidAdapter(ApiClient.Sandbox);

    plaidClient = apiClient.createService(PlaidApi.class);

    // Simplified psuedo-code for obtaining a user_id
    User userFromDB = db.find(...);
    String clientUserId = userFromDB.id;
    LinkTokenCreateRequestUser user = new LinkTokenCreateRequestUser()
      .clientUserId(clientUserId);

    // Create a link_token for the given user
    LinkTokenCreateRequestTransfer transfer = new LinkTokenCreateRequestTransfer()
      .intentId("TRANSFER_INTENT_ID");

    LinkTokenCreateRequest request = new LinkTokenCreateRequest()
      .user(user)
      .clientName("Plaid Test App")
      .products(['transfer']))
      .countryCodes(['US']))
      .language("en")
      .redirectUri(redirectUri)
      .webhook("https://sample.webhook.com")
      .transfer(transfer)
      .linkCustomizationName("single-account-select-transfer-ui")


    Response<LinkTokenCreateResponse> response = plaidClient
      .linkTokenCreate(request)
      .execute();

    // Send the data to the client
    return response.body();
  }
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-python",
          "metastring": "#Generating a Link token with the transfer intent ID",
          "#Generating": true,
          "a": true,
          "Link": true,
          "token": true,
          "with": true,
          "the": true,
          "transfer": true,
          "intent": true,
          "ID": true
        }}>{`# Using Flask
@app.route("/create_link_token", methods=['POST'])
def create_link_token(): # Get the client_user_id by searching for the current user
user = User.find(...)
client_user_id = user.id

    # Create a link_token for the given user
    request = LinkTokenCreateRequest(
            products=[Products('transfer')],
            client_name="Plaid Test App",
            country_codes=[CountryCode('US')],
            language='en',
            webhook='https://webhook.sample.com',
             transfer=LinkTokenCreateRequestTransfer(
                intent_id="TRANSFER_INTENT_ID"
            ),
            link_customization_name='single-account-select-transfer-ui',
            user=LinkTokenCreateRequestUser(
                client_user_id=client_user_id
            )
        )
    response = client.link_token_create(request)
    link_token = response['link_token']

    # Send the data to the client
    return jsonify(response.to_dict())
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-go",
          "metastring": "#Generating a Link token with the transfer intent ID",
          "#Generating": true,
          "a": true,
          "Link": true,
          "token": true,
          "with": true,
          "the": true,
          "transfer": true,
          "intent": true,
          "ID": true
        }}>{`import (
    "context"
    "net/http"
    "os"

    "github.com/gin-gonic/gin"
    "github.com/plaid/plaid-go/plaid"
)

func createLinkToken(c *gin.Context) {
  ctx := context.Background()

  // Get the client_user_id by searching for the current user
  user, _ := usermodels.Find(...)
  clientUserId := user.ID.String()

  // Create a link_token for the given user
  transfer := plaid.LinkTokenCreateRequestTransfer{
    IntentId: "TRANSFER_INTENT_ID",
  }
  request := plaid.NewLinkTokenCreateRequest("Plaid Test App", "en", []plaid.CountryCode{plaid.COUNTRYCODE_US}, *plaid.NewLinkTokenCreateRequestUser(clientUserId))
  request.SetWebhook("https://webhook.sample.com")
  request.SetProducts([]plaid.Products{plaid.PRODUCTS_TRANSFER})
  request.SetTransfer(transfer)
  request.SetLinkCustomizationName("single-account-select-transfer-ui")

    resp, _, err := testClient.PlaidApi.LinkTokenCreate(ctx).LinkTokenCreateRequest(*request).Execute()

  // Send the data to the client
  c.JSON(http.StatusOK, gin.H{
    "link_token": resp.GetLinkToken(),
  })
}

`}</code></pre>
    </MultiCodeBlock>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
