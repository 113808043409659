import React from 'react';
import cx from 'classnames';

import styles from './Content.module.scss';

interface Props {
  children: React.ReactNode;
  wide?: boolean;
  className?: string;
}

const Content: React.FC<Props> = (props: Props) => (
  <div className={cx(styles.content, props.className)}>{props.children}</div>
);

Content.displayName = 'Content';

export default Content;
