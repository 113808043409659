import { ErrorReference } from '../../types';

export const RECAPTCHA_REQUIRED: ErrorReference = {
  server: {
    error_type: 'RECAPTCHA_ERROR',
    error_code: 'RECAPTCHA_REQUIRED',
    error_message:
      'This request requires additional verification. Please resubmit the request after completing the challenge',
    display_message: null,
    http_code: 400,
  },
  // TODO: display the Link Recaptcha pane as the preview (not an error)
  link: null,
};

export const RECAPTCHA_BAD: ErrorReference = {
  server: {
    error_type: 'RECAPTCHA_ERROR',
    error_code: 'RECAPTCHA_BAD',
    error_message:
      'The provided challenge response was denied. Please try again',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_button: 'Restart',
    link_error_heading: 'Please try connecting a different account',
    link_error_icon: 'close',
    link_error_message:
      'There was a problem processing your request. Your account could not be connected at this time',
    error_code: 'RECAPTCHA_BAD',
  },
};
