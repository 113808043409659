import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import criticalCss from 'plaid-threads/utils/criticalCss';

import { getSection, getRoute, withTrailingSlash } from '../utilities';
import { ArticleMetadata } from '../types';
import { isProduction } from 'src/lib/constants';
import { optimizeImage } from 'src/lib/image-helpers';

const fonts = [
  'cern-regular.woff2',
  'cern-extrabold.woff2',
  'cern-semibold.woff2',
  'cern-italic.woff2',
  'Inconsolata-Regular.woff2',
  'Inconsolata-Bold.woff2',
];

interface Props {
  metadata: ArticleMetadata;
}

const MetaTags: React.FC<Props> = (props) => {
  const { pathname } = useRouter();
  const [section, setSection] = React.useState(getSection(pathname));
  const [route, setRoute] = React.useState(getRoute(pathname));

  React.useEffect(() => {
    setSection(getSection(pathname));
    setRoute(getRoute(pathname));
  }, [pathname]);

  // For partial MDX files, we don't want to override the main Head meta-tags
  if (props.metadata == null) {
    return null;
  }

  if (props?.metadata?.description == null) {
    throw new Error(`${pathname}: metadata.description is required!`);
  }

  const title = section?.title ? section?.title : props.metadata.section;
  const subtitle = route?.title ? route?.title : props.metadata.title;
  const description = props.metadata.description;
  const canonicalUrl = `https://plaid.com${withTrailingSlash(pathname)}`;
  const noIndex = props.metadata.noindex;

  return (
    <Head>
      <meta charSet='utf-8' />
      <meta httpEquiv='x-ua-compatible' content='ie=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff' />
      <title>
        {`${title ?? ''}${
          title && subtitle && title !== subtitle
            ? ' - ' + subtitle
            : subtitle && !title
            ? subtitle
            : ''
        } | Plaid Docs`}
      </title>
      <meta name='section-title' content={title} />
      <meta name='section-subtitle' content={subtitle} />
      <meta name='section-type' content={props.metadata.layout} />
      <meta name='description' content={props.metadata.description} />
      {noIndex && <meta name='robots' content='noindex, nofollow' />}
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/assets/img/favicons/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/assets/img/favicons/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/assets/img/favicons/favicon-16x16.png'
      />
      <link rel='manifest' href='/assets/img/favicons/site.webmanifest' />
      <link
        rel='mask-icon'
        href='/assets/img/favicons/safari-pinned-tab.svg'
        color='#5bbad5'
      />

      {fonts.map((font) => (
        <link
          key={font}
          rel='preload'
          as='font'
          href={`/assets/fonts/${font}`}
          crossOrigin='anonymous'
        />
      ))}

      {props.metadata.preloadImages?.map((image, i) => (
        <link
          key={`image-${i}`}
          rel='preload'
          as='image'
          href={optimizeImage(image.src)}
          type={image.type}
        />
      ))}
      <link rel='canonical' href={canonicalUrl} />
      <meta property='og:title' content={title} />
      <meta property='og:url' content={canonicalUrl} />
      <meta property='og:description' content={description} />
      <meta property='og:type' content='website' />
      <meta
        property='og:image'
        content='https://plaid.com/assets/img/social-card-landscape.jpg'
      />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@plaid' />
      <meta name='twitter:title' content='Documentation | Plaid' />
      <meta property='twitter:description' content={description} />
      <meta
        name='twitter:image'
        content='https://plaid.com/assets/img/social-card.jpg'
      />
      <meta
        name='google-site-verification'
        content='92umdhJ1LufsliBdSAFEltp1osgG_ehru4C__nMCJpQ'
      />
      <style
        type='text/css'
        dangerouslySetInnerHTML={{
          __html: criticalCss([
            'Icon',
            'Button',
            'Note',
            'Tooltip',
            'Dropdown',
            'IconButton',
            'ActionCard',
            'InlineLink',
            'NavigationLink',
            'Callout',
            'CodeBlock',
            'Checkbox',
          ]),
        }}
      />
      {isProduction && <script src='/assets/js/hire.js'></script>}
    </Head>
  );
};

export default MetaTags;
