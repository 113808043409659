import React from 'react';
import ActionCard from 'plaid-threads/ActionCard';

import { withTrailingSlash } from '../utilities';
import LinkWrapper from '../LinkWrapper';

import styles from './DoubleCard.module.scss';

// Card that stacks 2 actions. Equal in height to a ActionCard.

export interface Action {
  icon?: React.ReactNode;
  title?: string;
  link?: string;
  linkText?: string;
}

export interface Props {
  header?: string;
  firstAction: Action;
  secondAction?: Action;
}

const DoubleCard: React.FC<Props> = (props: Props) => (
  <div>
    {props.header && <h6>{props.header}</h6>}
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <ActionCard
          component={LinkWrapper}
          componentProps={{ href: props.firstAction.link }}
          icon={props.firstAction.icon}
          subtitle={props.firstAction.title}
          href={withTrailingSlash(props.firstAction.link)}
          ctaText={props.firstAction.linkText}
        ></ActionCard>
      </div>
      <div className={styles.card}>
        {props.secondAction.link ? (
          <ActionCard
            component={LinkWrapper}
            componentProps={{ href: props.secondAction.link }}
            icon={props.secondAction.icon}
            title={null}
            subtitle={props.secondAction.title}
            href={withTrailingSlash(props.secondAction.link)}
            ctaText={props.secondAction.linkText}
          ></ActionCard>
        ) : (
          <div className={styles.static}>
            {props.secondAction.icon}
            <p>{props.secondAction.title}</p>
          </div>
        )}
      </div>
    </div>
  </div>
);

DoubleCard.displayName = 'DoubleCard';

export default DoubleCard;
