import React from 'react';

import { PartnerProps } from '../../types';
import Partner from './Partner';

type Props = Pick<
  PartnerProps,
  'registrationUrl' | 'apiUrl' | 'isPaymentProcessor' | 'partner'
>;

const GettingStarted: React.FC<PartnerProps> = (props: Props) => {
  return (
    <p>
      You'll first want to familiarize yourself with{' '}
      <a href='/plaid-link/'>Plaid Link</a>, a drop-in client-side integration
      for the Plaid API that handles input validation, error handling, and
      multi-factor authentication. You will also need to have a verified{' '}
      <a href={props.registrationUrl} children={`${props.partner} account`} />{' '}
      to add a bank funding source.{' '}
      {props.isPaymentProcessor ? (
        <>
          Your customers will use Link to authenticate with their financial
          institution and select the bank account they wish to connect. From
          there, you'll receive a Plaid <code>access_token</code> and a{' '}
          <Partner p={props.partner} /> <code>processor_token</code>, which
          allows you to quickly and securely verify a bank funding source via{' '}
          <a
            href={props.apiUrl}
            children={`${props.partner}'s API`}
            rel='noopener'
            target='\_blank'
          />{' '}
          without having to store any sensitive banking information. Utilizing
          Plaid + <Partner p={props.partner} /> enables a seamless workflow for
          connecting external financial accounts to{' '}
          <Partner p={props.partner} />.
        </>
      ) : (
        <>
          Your customers will use Link to authenticate with their financial
          institution and select the bank account they wish to connect. From
          there, you'll receive a Plaid <code>access_token</code> and a{' '}
          <Partner p={props.partner} /> <code>processor_token</code>, which
          allows you to quickly and securely retrieve the user's financial data
          via{' '}
          <a
            href={props.apiUrl}
            children={`${props.partner}'s API`}
            rel='noopener'
            target='\_blank'
          />
          . Utilizing Plaid + <Partner p={props.partner} /> enables a seamless
          workflow for connecting external financial accounts to{' '}
          <Partner p={props.partner} />.
        </>
      )}
    </p>
  );
};

export default GettingStarted;
