import { ErrorReference, LinkError } from '../../types';

export const PAYMENT_BLOCKED: ErrorReference = {
  server: {
    error_type: 'PAYMENT_ERROR',
    error_code: 'PAYMENT_BLOCKED',
    error_message: 'payment blocked',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const PAYMENT_CANCELLED: ErrorReference = {
  server: {
    error_type: 'PAYMENT_ERROR',
    error_code: 'PAYMENT_CANCELLED',
    error_message: 'user cancelled the payment',
    display_message:
      'Try making your payment again or select a different bank to continue.',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Payment cancelled',
    link_error_message:
      'Try making your payment again or select a different bank to continue.',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'PAYMENT_CANCELLED',
  },
};

export const PAYMENT_INSUFFICIENT_FUNDS: ErrorReference = {
  server: {
    error_type: 'PAYMENT_ERROR',
    error_code: 'PAYMENT_INSUFFICIENT_FUNDS',
    error_message: 'insufficient funds to complete the request',
    display_message:
      "There isn't enough money in this account to complete the payment. Try again, or select another account or bank.",
    http_code: 400,
  },
  link: {
    link_error_heading: 'Insufficient funds',
    link_error_message:
      "There isn't enough money in this account to complete the payment. Try again, or select another account or bank.",
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'PAYMENT_INSUFFICIENT_FUNDS',
  },
};

const paymentFailedLinkError: LinkError = {
  link_error_heading: 'Payment failed',
  link_error_message:
    'Try making your payment again or select a different bank to continue.',
  link_error_icon: 'close',
  link_error_button: 'Try again',
  error_code: 'PAYMENT_INVALID_RECIPIENT',
};

export const PAYMENT_INVALID_RECIPIENT: ErrorReference = {
  server: {
    error_type: 'PAYMENT_ERROR',
    error_code: 'PAYMENT_INVALID_RECIPIENT',
    error_message: 'payment recipient invalid',
    display_message:
      'The payment recipient is invalid for the selected institution. Create a new payment with a different recipient.',
    http_code: 400,
  },
  link: paymentFailedLinkError,
};

export const PAYMENT_INVALID_REFERENCE: ErrorReference = {
  server: {
    error_type: 'PAYMENT_ERROR',
    error_code: 'PAYMENT_INVALID_REFERENCE',
    error_message: 'payment reference invalid',
    display_message:
      'The payment reference is invalid for the selected institution. Create a new payment with a different reference.',
    http_code: 400,
  },
  link: paymentFailedLinkError,
};

export const PAYMENT_INVALID_SCHEDULE: ErrorReference = {
  server: {
    error_type: 'PAYMENT_ERROR',
    error_code: 'PAYMENT_INVALID_SCHEDULE',
    error_message: 'payment schedule invalid',
    display_message:
      'The payment schedule is invalid for the selected institution. Create a new payment with a different schedule.',
    http_code: 400,
  },
  link: paymentFailedLinkError,
};

export const PAYMENT_REJECTED: ErrorReference = {
  server: {
    error_type: 'PAYMENT_ERROR',
    error_code: 'PAYMENT_REJECTED',
    error_message: 'payment rejected',
    display_message:
      'The payment was rejected by the institution. Try again, or select another account or bank.',
    http_code: 400,
  },
  link: paymentFailedLinkError,
};

export const PAYMENT_SCHEME_NOT_SUPPORTED: ErrorReference = {
  server: {
    display_message:
      'The payment scheme is not supported by the institution. Either change scheme or select another institution.',
    error_code: 'PAYMENT_SCHEME_NOT_SUPPORTED',
    error_message: 'payment scheme not supported',
    error_type: 'PAYMENT_ERROR',
    http_code: 400,
  },
  link: paymentFailedLinkError,
};

export const PAYMENT_CONSENT_INVALID_CONSTRAINTS: ErrorReference = {
  server: {
    display_message:
      'The payment consent constraints are missing or not supported by the institution. Either update constraints or select another institution.',
    error_code: 'PAYMENT_CONSENT_INVALID_CONSTRAINTS',
    error_message: 'payment consent constraints invalid',
    error_type: 'PAYMENT_ERROR',
    http_code: 400,
  },
  link: paymentFailedLinkError,
};

export const PAYMENT_CONSENT_CANCELLED: ErrorReference = {
  server: {
    display_message:
      'Authorise your payment consent again or select a different bank to continue.',
    error_code: 'PAYMENT_CONSENT_CANCELLED',
    error_message: 'user cancelled the payment consent',
    error_type: 'PAYMENT_ERROR',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Payment cancelled',
    link_error_message:
      'Try making your payment again or select a different bank to continue.',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'PAYMENT_CONSENT_CANCELLED',
  },
};
