
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <MultiCodeBlock type='server' title='Terminal' mdxType="MultiCodeBlock">
      <pre><code parentName="pre" {...{
          "className": "language-node"
        }}>{`// Install via npm
npm install --save plaid
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`## Not applicable with curl calls
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`# Available as a gem
gem install plaid
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-java"
        }}>{`/*
For Gradle, add the following dependency to your build.gradle and replace {VERSION} with the version number you want to use from
- https://github.com/plaid/plaid-java/releases/latest
*/
implementation "com.plaid:plaid-java:{VERSION}"

/*
For Maven, add the following dependency to your POM and replace {VERSION} with the version number you want to use from
- https://github.com/plaid/plaid-java/releases/latest
*/
<dependency>
  <groupId>com.plaid</groupId>
  <artifactId>plaid-java</artifactId>
  <version>{VERSION}</version>
</dependency>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-python"
        }}>{`# Install through pip, only supports Python 3
pip install --upgrade plaid-python
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-go"
        }}>{`go get github.com/plaid/plaid-go
`}</code></pre>
    </MultiCodeBlock>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
