import { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const DropModal = (props: {
  children: React.ReactNode;
  onClickOutside?: (event?: MouseEvent) => void;
  isOpen: boolean;
  side?: 'right' | 'left' | 'center';
  className?: string | Array<string>;
  hiddenClassName?: string;
}) => {
  const side = props.side ?? 'right'; // default to right side
  const wrapperRef = useRef<HTMLElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        event.target != null &&
        wrapperRef.current != null &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        props.onClickOutside?.(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <span ref={wrapperRef}>
      <div className={styles.dropModal}>
        <div
          className={classNames(props.className, styles.actionCard, {
            [styles.isHidden]: !props.isOpen,
            [styles.rightSide]: side === 'right',
            [styles.leftSide]: side === 'left',
            [props.hiddenClassName!]: !props.isOpen,
            // if side === 'center', expect styles to be passed in via className
          })}
        >
          {props.children}
        </div>
      </div>
    </span>
  );
};

export default DropModal;
