import React from 'react';

import { ROUTES } from '../constants/routeMap';
import DocsSidebar, { Props } from '../../docs/Sidebar';
import { NavGroup } from '../../docs/constants/routeMap';

const TOP_NAV: NavGroup = [...ROUTES];

const Sidebar = (props: Props) => (
  <DocsSidebar
    {...props}
    navigation={TOP_NAV}
    headerText={'Exchange'}
    baseUrl={props.baseUrl}
  />
);

Sidebar.displayName = 'Sidebar';
export default Sidebar;
