import React from 'react';

import Callout from 'plaid-threads/Callout';
import NavigationLink from 'plaid-threads/NavigationLink';
import Note from 'plaid-threads/Note';
import cx from 'classnames';

import APIVersion from '../APIVersion';
import Actions from '../Actions';
import AnchorLink from '../AnchorLink';
import Checkbox from '../Checkbox';
import ClientPlatformSwitcher from '../ClientPlatformSwitcher';
import CodeBlock from '../CodeBlock';
import ContainerWithHeader from '../ContainerWithHeader';
import ContentSwitcher from '../ContentSwitcher';
import Endpoint from '../Table/UseCases/Endpoint';
import Error from '../Table/UseCases/Error';
import ErrorReference from '../plaidoms/ErrorReference';
import Feedback from '../Feedback';
import Image from '../Image';
import Layout from '../Layout';
import LinkedCode from '../LinkedCode';
import LinkedHeading from '../LinkedHeading';
import MultiCodeBlock from '../MultiCodeBlock';
import Partnership from '../plaidoms/Partnership';
import Header from '../Header';
import RunQuickstart from '../plaidoms/RunQuickstart';
import SampleCode from '../plaidoms/SampleCode';
import Schema from '../Schema';
import YouTubeEmbed from '../YouTubeEmbed';
import AggregatorSchema from '../../exchange-docs/AggregatorSchema';
import StepWithDiagram from '../plaidoms/StepWithDiagram';
import TokenExchangeFlow from '../plaidoms/TokenExchangeFlow';
import PaymentInitiationFlowOverview from '../plaidoms/PaymentInitiationFlowOverview';
import PaymentInitiationSetupFlow from '../plaidoms/PaymentInitiationSetupFlow';

import styles from './MDXComponents.module.scss';

const SHORTCODE_IMPORTS = {
  // plaid-threads
  Checkbox,
  Note,
  NavigationLink,
  // docs components
  APIVersion,
  Actions,
  ClientPlatformSwitcher,
  ContainerWithHeader,
  ContentSwitcher,
  Endpoint,
  Error,
  ErrorReference,
  Feedback,
  Image,
  Layout,
  MultiCodeBlock,
  Partnership,
  Header,
  RunQuickstart,
  SampleCode,
  Schema,
  YouTubeEmbed,
  AggregatorSchema,
  StepWithDiagram,
  TokenExchangeFlow,
  PaymentInitiationFlowOverview,
  PaymentInitiationSetupFlow,
};

export default {
  ...SHORTCODE_IMPORTS,
  pre: (outerProps) => {
    const props = outerProps.children.props;
    const code = props.children.trim();

    const language =
      props.className != null ? props.className.split('-')[1] : null;

    // usage: ```json #this is the title
    const title = props.title
      ? props.title
      : props.metastring != null && props.metastring.includes('#')
      ? props.metastring.split('#').pop()
      : ' ';

    return (
      <CodeBlock
        title={title}
        code={code}
        lang={language}
        disableCopy={props.copy === 'false'}
        disableSwitcher={props.switcher === 'false'}
        type={props.type}
      />
    );
  },
  h1: (props) => <LinkedHeading {...props} Component={'h1'} />,
  h2: (props) => <LinkedHeading {...props} Component={'h2'} />,
  h3: (props) => <LinkedHeading {...props} Component={'h3'} />,
  h4: (props) => <LinkedHeading {...props} Component={'h4'} />,
  h5: (props) => <LinkedHeading {...props} Component={'h5'} />,
  inlineCode: (props) => <LinkedCode {...props} />,
  a: (props) => <AnchorLink {...props} />,
  table: (props) => (
    <table className={cx(styles.table, props.children.className)}>
      {props.children}
    </table>
  ),
  section: (props) => (
    <section className={cx(styles.section, props.children.className)}>
      {props.children}
    </section>
  ),
  ul: (props) => (
    <ul className={cx(styles.ul, props.children.className)}>
      {props.children}
    </ul>
  ),
  ol: (props) => (
    <ol className={cx(styles.ol, props.children.className)}>
      {props.children}
    </ol>
  ),
  blockquote: (props) => (
    <Callout className={styles.callout}>
      {props.children.props.children}
    </Callout>
  ),
  Callout: ({ children, ...restProps }) => (
    // small workaround to prevent a <p> inside a <p>
    <Callout {...restProps} className={styles.callout}>
      {children?.props?.mdxType === 'p' ? children.props.children : children}
    </Callout>
  ),
};
