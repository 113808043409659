import React, { useState } from 'react';
import snarkdown from 'snarkdown';

import SelectGroup from 'plaid-threads/SelectGroup';

import CodeBlock from '../../CodeBlock';
import Feedback from '../../Feedback';
import Layout from '../../Layout';
import LinkPreview from '../../LinkPreview';
import errors from '../../constants/errors';
import { ServerError } from '../../types';

import styles from './index.module.scss';

interface Props {
  type: string;
  error: string;
  subtitle: string;
  client: boolean;
  server: boolean;
  children: React.ReactNode;
}

// formatErrorJson :: Object -> String
// guarantee that the order of the json object is correct and append request_id
const formatErrorJson = (serverError: ServerError) => {
  if (serverError == null) {
    return `// no server error`;
  }
  const json = {
    error_type: serverError.error_type,
    error_code: serverError.error_code,
    error_message: serverError.error_message,
    display_message: serverError.display_message,
    causes: serverError.causes,
    request_id: 'HNTDNrA8F1shFEW',
  };
  return `http code ${serverError.http_code}
${JSON.stringify(json, null, ' ')}`;
};

const ErrorReference: React.FC<Props> = (props: Props) => {
  const [preview, setPreview] = useState({
    label: 'API error response',
    value: false,
  });
  const serverError = errors[props.type][props.error].server;
  const linkError = errors[props.type][props.error].link;
  const name = `error: ${props.error}`;

  return (
    <div className={styles.errorReference}>
      {props.subtitle && (
        <h5
          dangerouslySetInnerHTML={{ __html: snarkdown(props.subtitle) }}
          className={styles.subtitle}
        ></h5>
      )}
      <Layout.SideBySide className={styles.wrapper}>
        <div>
          {linkError && (
            <div className={styles.linkErrorInHeader}>
              <h5>Sample user-facing error message</h5>{' '}
              {linkError.link_error_heading}: {linkError.link_error_message}
            </div>
          )}
          <div>{props.children}</div>
        </div>
        <div>
          {linkError && (
            <SelectGroup
              buttonClassName={styles.buttonStyle}
              small
              inline
              className={styles.toggle}
              onChange={(e) => setPreview(e)}
              options={[
                { label: 'API error response', value: false },
                { label: 'Link error preview', value: true },
              ]}
              value={preview}
            />
          )}
          {!preview.value && (
            <CodeBlock
              title='API error response'
              lang='json'
              code={formatErrorJson(serverError)}
            />
          )}
          {preview.value && <LinkPreview error={linkError} />}
        </div>
      </Layout.SideBySide>
      <Feedback name={name} source='error' />
    </div>
  );
};

ErrorReference.displayName = 'ErrorReference';
export default ErrorReference;
