
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';
import ContentSwitcher from '../../ContentSwitcher';

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ContentSwitcher.Container id='libraries' names={['Current libraries', 'Legacy libraries']}>
      <ContentSwitcher.Content>
        <MultiCodeBlock title="Generating a Link token" mdxType="MultiCodeBlock">
          <pre><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`curl -X POST https://production.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "Plaid Test App",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "products": ["transactions"],
  "country_codes": ["US"],
  "language": "en",
  "webhook": "https://sample.webhook.com",
  "redirect_uri": "https://example.com/callback"
}'
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-go"
            }}>{`user := plaid.LinkTokenCreateRequestUser{
    ClientUserId: "USER_ID_FROM_YOUR_DB",
}
request := plaid.NewLinkTokenCreateRequest(
  "Plaid Test",
  "en",
  []plaid.CountryCode{plaid.COUNTRYCODE_US},
  user,
)
request.SetProducts([]plaid.Products{plaid.PRODUCTS_TRANSACTIONS})
request.SetLinkCustomizationName("default")
request.SetWebhook("https://webhook-uri.com")
request.SetRedirectUri("https://example.com/callback")
resp, _, err := testClient.PlaidApi.LinkTokenCreate(ctx).LinkTokenCreateRequest(*request).Execute()

linkToken := resp.GetLinkToken()
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-node"
            }}>{`const request = {
  user: {
    // This should correspond to a unique id for the current user.
    client_user_id: 'user-id',
  },
  client_name: 'Plaid Test App',
  products: [Products.Transactions],
  country_codes: [CountryCode.Us],
  language: 'en',
  webhook: 'https://sample-web-hook.com',
  redirect_uri: 'https://example.com/callback',
};

try {
  const createTokenResponse = await client.linkTokenCreate(request);
  const linkToken = response.data.link_token;
} catch (error) {
  // handle error
}
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-python"
            }}>{`user = User.find(...)
client_user_id = user.id

    # Create a link_token for the given user
    request = LinkTokenCreateRequest(
            products=[Products('transactions')],
            client_name="Plaid Test App",
            country_codes=[CountryCode('US')],
            redirect_uri='https://example.com/callback',
            language='en',
            webhook='https://webhook.sample.com',
            user=LinkTokenCreateRequestUser(
                client_user_id=client_user_id
            )
        )
    response = client.link_token_create(request)
    link_token = response['link_token']
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-ruby"
            }}>{`link_token_create_request = Plaid::LinkTokenCreateRequest.new(
    {
      user: { client_user_id: 'user-id' },
      client_name: 'Plaid Test App',
      products: ['transactions'],
      country_codes: ['US'],
      language: "en",
      redirect_uri: 'https://example.com/callback',
      webhook: 'https://webhook.sample.com'
    }
  )
  response = client.link_token_create(link_token_create_request)
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-java"
            }}>{`User userFromDB = db.find(...);
String clientUserId = userFromDB.id;
LinkTokenCreateRequestUser user = new LinkTokenCreateRequestUser()
  .clientUserId(clientUserId);

// Create a link_token for the given user
LinkTokenCreateRequest request = new LinkTokenCreateRequest()
  .user(user)
  .clientName("Plaid Test App")
  .products(Arrays.asList(Products.TRANSACTIONS))
  .countryCodes(Arrays.asList(CountryCode.US, CountryCode.CA))
  .language("en")
  .redirectUri('https://example.com/callback')
  .webhook("https://sample.webhook.com");

Response<LinkTokenCreateResponse> response = plaidClient
  .linkTokenCreate(request)
  .execute();

String linkToken = response.body().getLinkToken();
`}</code></pre>
        </MultiCodeBlock>
  </ContentSwitcher.Content>
      <ContentSwitcher.Content>
        <MultiCodeBlock title="Generate a Link token and configure it with a redirect URI" mdxType="MultiCodeBlock">
          <pre><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`curl -X POST https://production.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "\${CLIENT_NAME}",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "products": ["transactions"],
  "country_codes": ["US"],
  "language": "en",
  "webhook": "https://sample.webhook.com",
  "redirect_uri": "https://domainname.com/oauth-page.html"
}'
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-go"
            }}>{`linkTokenResp, err := client.CreateLinkToken(LinkTokenConfigs{
    User: &LinkTokenUser{
      ClientUserID:             "123-test-user-id",
    },
    ClientName:            "Plaid Test App",
    Products:              []string{"transactions"},
    CountryCodes:          []string{"US"},
    Language:              "en",
    Webhook:               "https://webhook-uri.com",
    LinkCustomizationName: "default",
    RedirectUri:           "https://domainname.com/oauth-page.html"
  })

  linkToken := linkTokenResp.LinkToken

`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-node"
            }}>{`const response = await client.createLinkToken({
  user: {
    client_user_id: '123-test-user-id',
  },
  client_name: 'Plaid Test App',
  products: [transactions'],
  country_codes: ['US'],
  language: 'en',
  webhook: 'https://sample-web-hook.com',
  redirect_uri: 'https://domainname.com/oauth-page.html'
}) .catch((err) => {
  // handle error
});

const linkToken = response.link_token;
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-python"
            }}>{`configs = {
  'user': {
      'client_user_id': '123-test-user-id',
  },
  'products': ['auth', 'transactions'],
  'client_name': "Plaid Test App",
  'country_codes': ['GB'],
  'language': 'en',
  'webhook': 'https://sample-webhook-uri.com',
  'link_customization_name': 'default',
  'redirect_uri': 'https://domainname.com/oauth-page.html'

}

# create link token
response = client.LinkToken.create(configs)
link_token = response['link_token']
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-ruby"
            }}>{`link_token_response = client.link_token.create(
  user: {
    client_user_id: '123-test-user-id'
  },
  client_name: 'Plaid Test App',
  products: %w[auth transactions],
  country_codes: ['GB'],
  language: 'en',
  webhook: 'https://sample-webhook.com',
  link_customization_name: 'default',
  redirect_uri: 'https://domainname.com/oauth-page.html'
)
link_token = link_token_response.link_token
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-java"
            }}>{`String clientUserId = "123-test-user-id";

LinkTokenCreateRequest.User user = new LinkTokenCreateRequest.User(clientUserId);
LinkTokenCreateRequest request = new LinkTokenCreateRequest(
  user,
  "Plaid Test App",
  Collections.singletonList("auth"))
  .withCountryCodes(Collections.singletonList("US"))
  .withLanguage("en")
  .withWebhook("https://example.com/webhook")
  .withLinkCustomizationName("default")
  .withRedirectUri("https://domainname.com/oauth-page.html");

Response<LinkTokenCreateResponse> response =
  client().service().linkTokenCreate(
    request).execute();

String linkToken = response.body().getLinkToken();
`}</code></pre>
        </MultiCodeBlock>
      </ContentSwitcher.Content>
      <ContentSwitcher.Content>
        <MultiCodeBlock title="Generate a Link token and configure it with a redirect URI" mdxType="MultiCodeBlock">
          <pre><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`curl -X POST https://production.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "Plaid Test App",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "products": ["transactions"],
  "country_codes": ["US"],
  "language": "en",
  "webhook": "https://sample.webhook.com",
  "redirect_uri": "https://domainname.com/oauth-page.html"
}'
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-go"
            }}>{`linkTokenResp, err := client.CreateLinkToken(LinkTokenConfigs{
    User: &LinkTokenUser{
      ClientUserID:             "user-id",
    },
    ClientName:            "Plaid Test App",
    Products:              []string{"transactions"},
    CountryCodes:          []string{"US"},
    Language:              "en",
    Webhook:               "https://webhook-uri.com",
    LinkCustomizationName: "default",
    RedirectUri:           "https://domainname.com/oauth-page.html"
  })

  linkToken := linkTokenResp.LinkToken

`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-node"
            }}>{`const request = {
  user: {
    // This should correspond to a unique id for the current user.
    client_user_id: 'user-id',
  },
  client_name: 'Plaid Test App',
  products: ['transactions'],
  country_codes: ['US'],
  language: 'en',
  webhook: 'https://sample-web-hook.com',
  redirect_uri: 'https://domainname.com/oauth-page.html',
};

try {
  const createTokenResponse = await client.linkTokenCreate(request);
  const linkToken = response.data.link_token;
} catch (error) {
  // handle error
}
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-python"
            }}>{`user = User.find(...)
client_user_id = user.id

    # Create a link_token for the given user
    request = LinkTokenCreateRequest(
            products=['transactions'],
            client_name="Plaid Test App",
            country_codes=['US'],
            redirect_uri='https://domainname.com/oauth-page.html',
            language='en',
            webhook='https://webhook.sample.com',
            user=LinkTokenCreateRequestUser(
                client_user_id=client_user_id
            )
        )
    response = client.link_token_create(request)
    link_token = response['link_token']
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-ruby"
            }}>{`link_token_create_request = Plaid::LinkTokenCreateRequest.new(
    {
      user: { client_user_id: 'user-id' },
      client_name: 'Plaid Test App',
      products: ['transactions'],
      country_codes: ['US'],
      language: "en",
      redirect_uri: 'https://domainname.com/oauth-page.html',
      webhook: 'https://webhook.sample.com'
    }
  )
  response = client.link_token_create(link_token_create_request)
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-java"
            }}>{`User userFromDB = db.find(...);
String clientUserId = userFromDB.id;
LinkTokenCreateRequestUser user = new LinkTokenCreateRequestUser()
  .clientUserId(clientUserId);

// Create a link_token for the given user
LinkTokenCreateRequest request = new LinkTokenCreateRequest()
  .user(user)
  .clientName("Plaid Test App")
  .products(['transactions']))
  .countryCodes(['US', 'CA']))
  .language("en")
  .redirectUri('https://domainname.com/oauth-page.html')
  .webhook("https://sample.webhook.com");

Response<LinkTokenCreateResponse> response = plaidClient
  .linkTokenCreate(request)
  .execute();

String linkToken = response.body().getLinkToken();
`}</code></pre>
        </MultiCodeBlock>
      </ContentSwitcher.Content>
    </ContentSwitcher.Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
