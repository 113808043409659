import { ErrorReference } from '../../types';

// Deposit Switch errors are hard-coded at:
// https://github.plaid.com/plaid/go/blob/master/lib/apischemas/apierrors/deposit_switch.go

export const DEPOSIT_SWITCH_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'DEPOSIT_SWITCH_ERROR',
    error_code: 'DEPOSIT_SWITCH_NOT_FOUND',
    error_message: 'deposit switch not found',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const DEPOSIT_SWITCH_TOKEN_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'DEPOSIT_SWITCH_ERROR',
    error_code: 'DEPOSIT_SWITCH_TOKEN_NOT_FOUND',
    error_message: 'deposit switch token expired',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const DEPOSIT_SWITCH_INVALID_ACCOUNT: ErrorReference = {
  server: {
    error_type: 'DEPOSIT_SWITCH_ERROR',
    error_code: 'DEPOSIT_SWITCH_INVALID_ACCOUNT',
    error_message:
      'invalid deposit switch target account field (fieldname): (requirements)',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const DEPOSIT_SWITCH_VALID_AUTH_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'DEPOSIT_SWITCH_ERROR',
    error_code: 'DEPOSIT_SWITCH_VALID_AUTH_NOT_FOUND',
    error_message: 'target account is missing account and routing numbers',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const DEPOSIT_SWITCH_VALID_IDENTITY_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'DEPOSIT_SWITCH_ERROR',
    error_code: 'DEPOSIT_SWITCH_VALID_IDENTITY_NOT_FOUND',
    error_message: 'target account is missing account and routing numbers',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const DEPOSIT_SWITCH_ALREADY_COMPLETED: ErrorReference = {
  server: {
    error_type: 'DEPOSIT_SWITCH_ERROR',
    error_code: 'DEPOSIT_SWITCH_ALREADY_COMPLETED',
    error_message:
      'cannot generate token because deposit switch associated with this deposit switch id has already been completed',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_DEPOSIT_SWITCH_ID: ErrorReference = {
  server: {
    error_type: 'DEPOSIT_SWITCH_ERROR',
    error_code: 'INVALID_DEPOSIT_SWITCH_ID',
    error_message: 'deposit switch id was invalid',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
