import Users from 'plaid-threads/Icons/Users';
import Webhooks from 'plaid-threads/Icons/Webhooks';
import BookOpen from 'plaid-threads/Icons/BookOpen';
import Learn from 'plaid-threads/Icons/Learn';
// import Document from 'plaid-threads/Icons/Document'; // to be used when we add back Additional resources
import Help from 'plaid-threads/Icons/Help';

import { NavItem, NavGroup } from '../../docs/constants/routeMap';

export const WELCOME_22: NavGroup = [
  {
    children: [
      {
        title: 'Program Overview',
        path: '/',
        icon: BookOpen,
        highlight: true,
        subNav: false,
      },
      {
        title: 'Meet your cohort',
        path: '/cohort',
        icon: Users,
        highlight: true,
        subNav: false,
      },
    ],
  },
];

export const PROGRAM_22: NavGroup = [
  {
    children: [
      {
        title: 'Workshops and Resources',
        path: '/workshops',
        icon: Learn,
        highlight: true,
        subNav: false,
      },
      {
        title: 'The Plaid Accelerate Network',
        path: '/network',
        icon: Webhooks,
        highlight: true,
        subNav: false,
      },
      {
        title: 'Ongoing Support',
        path: '/ongoing-support',
        icon: Help,
        highlight: true,
        subNav: false,
      },
    ],
  },
];

// everything
export const ROUTE_MAP: NavGroup = [...WELCOME_22, ...PROGRAM_22];

const flattenNavGroup = (group: NavGroup): Array<NavItem> =>
  group.reduce((acc, item) => {
    const res: Array<NavItem> = [];
    // if this item has properties,
    // push item to results
    if (item.path != null && item.title != null) {
      res.push(item);
    }
    // process children if they exist
    if (item.children != null) {
      res.push(...flattenNavGroup(item.children));
    }
    return acc.concat(res);
  }, []);

export const ROUTE_MAP_FLAT = flattenNavGroup(ROUTE_MAP);
