import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { OpenAPIObject } from 'openapi3-ts';

import Context from 'src/contexts/docs';
import OpenAPILinkContext from 'src/contexts/docs/openapi/link';
import OpenAPIContext from 'src/contexts/docs/openapi';
import OpenAPIExchangeContext, {
  ExchangeOpenApiSchemas,
} from 'src/contexts/docs/openapi/exchange';
import OpenAPICoreExchangeContext, {
  CoreExchangeOpenApiSchemas,
} from 'src/contexts/docs/openapi/core-exchange';
import { getCookie } from 'public/assets/js/analytics/cookie';
import { DASHBOARD_API_URL } from '../constants';

const RouterHooks: React.FC = () => {
  const router = useRouter();
  const { dispatch, tableOfContents } = React.useContext(Context);
  const definition: OpenAPIObject | null = React.useContext(OpenAPIContext);
  const linkDefinition: OpenAPIObject | null = React.useContext(
    OpenAPILinkContext,
  );
  const pxDefinition: ExchangeOpenApiSchemas | null = React.useContext(
    OpenAPIExchangeContext,
  );
  const cxDefinition: CoreExchangeOpenApiSchemas | null = React.useContext(
    OpenAPICoreExchangeContext,
  );
  const [definitionsLoaded, setDefinitionsLoaded] = React.useState(false);
  const [activeTeamCookie, setActiveTeamCookie] = React.useState(null);

  useEffect(() => {
    if (typeof document !== 'undefined' && window) {
      setActiveTeamCookie(getCookie('activeTeam'));
    }
  }, []);

  // these next two hooks are just to fix deeplinks on Chrome. All other browsers work as expected
  React.useEffect(() => {
    // handle one-time override of Chrome scrollRestoration "feature" on
    // initial page load. subsequent navigation using nextjs links or
    // hrefs to other IDs on the page will not trigger this and work OOTB
    if ('scrollRestoration' in window.history) {
      // Back off, browser, I got this...
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  // This hook fires after context is loaded so we dont scroll to the
  // wrong place due to some components like schema tables not being rendered yet
  React.useEffect(() => {
    // only fire on initial loads of OpenAPI + Link definitions but not on
    // subsequent changes such as API version change fired by the user.
    if (definitionsLoaded) {
      return;
    }
    if (
      definition != null &&
      linkDefinition != null &&
      pxDefinition != null &&
      cxDefinition != null
    ) {
      setDefinitionsLoaded(true);
    }

    const hash = window.location.hash.replace('#', '');

    if (hash.length === 0) {
      return;
    }

    // find and scroll to element
    const elem = document.getElementById(hash);
    if (elem != null) {
      // FORCE scroll cause Chrome is dumb
      setTimeout(() => {
        elem.scrollIntoView();
      }, 0);
    }
  }, [
    definition,
    linkDefinition,
    pxDefinition,
    cxDefinition,
    definitionsLoaded,
  ]);

  // handle hash changes like subnav navigation or table of contents navigation
  React.useEffect(() => {
    const setTableOfContentsWaypoint = (url) => {
      const hash = url.split('#').pop();

      if (tableOfContents == null) {
        return;
      }

      const tableOfContentsIds = tableOfContents.map((t) => t.id);

      // ignore hash changes that dont effect the ToC waypoints
      if (!tableOfContentsIds.includes(hash)) {
        return;
      }
      // set timeout used to overwrite the scroll when user click on items at bottom of page.
      setTimeout(() => {
        dispatch({
          type: 'SET_TOC_WAYPOINT',
          payload: {
            tableOfContentsActiveItem: hash,
          },
        });
      }, 0);
    };

    router.events.on('hashChangeComplete', setTableOfContentsWaypoint);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('hashChangeComplete', setTableOfContentsWaypoint);
    };
  }, [dispatch, router.events, tableOfContents]);

  // moved from old analyticsWrapper component
  React.useEffect(() => {
    dispatch({ type: 'PAGE_VIEW', payload: {} });
    // Feedback widget needs to be reset on page changes as well so that new feedback analytics
    // can be submitted
    dispatch({ type: 'RESET_FEEDBACK' });
  }, []);

  React.useEffect(() => {
    fetch(DASHBOARD_API_URL, {
      credentials: 'include',
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Login failed');
        }
        res.json().then((data) => {
          dispatch({ type: 'LOGIN' });
          dispatch({ type: 'UPDATE_USER', payload: data });
        });
      })
      .catch((data) => {
        dispatch({ type: 'LOGOUT' });
      });
  }, [activeTeamCookie]);

  // this component doesnt render anything, its here simply to handle router events
  return <></>;
};

RouterHooks.displayName = 'RouterHooks ';

export default RouterHooks;
