import React from 'react';
import Link from 'next/link';
import cx from 'classnames';

import styles from './ContentItem.module.scss';

interface Props {
  name: string;
  href: string;
  description?: string;
  isOptional?: boolean;
  className?: string;
}

const ContentItem: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <span className={cx(styles.link, props.className)}>
        <Link href={props.href} passHref>
          <a href={props.href}>{props.name}</a>
        </Link>
      </span>{' '}
      <span className={styles.description}>
        {props.description} {props.isOptional && '(optional)'}
      </span>
    </div>
  );
};

ContentItem.displayName = 'ContentItem';

export default ContentItem;
