import React, { useState, useContext, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { useRouter } from 'next/router';
import { useUniqueId } from 'plaid-threads/utils';

import ThreadsCheckbox from 'plaid-threads/Checkbox';

import Context from '../../../contexts/docs';

import styles from './index.module.scss';

interface Props {
  checklistId?: string;
  children: React.ReactNode;
}

// takes any valid react children and turns them into a simple string of characters
// e.g. <p>Hello <span>World!</span></p> => helloworld
const childrenAsIdString = (children: Props['children']): string => {
  // removes non alpha numeric characters
  const nonAlphaNumerics = /\W/g;
  // removes html tags
  const tags = /(<([^>]+)>)/gi;
  // extremely simple hash function that reduces any string to an int
  // we do this so that our inputs dont end up having ids that are giant blobs of text
  const simpleHash = (val: number, curr: string): number =>
    (val = curr.charCodeAt(0) + (val << 6) + (val << 16) - val);

  // handle case where children can be singleton or array of children
  const childrenAsFlatArray = [children].flat();
  // renders each element as a string
  const childrenAsArrayOfString = childrenAsFlatArray.map(renderToString);
  // sanitize and simplify the string so that capitalization, formatting, etc do not change the ID
  return (
    childrenAsArrayOfString
      // create one string from the array of strings
      .join()
      // sanitize
      .replace(tags, '')
      .replace(nonAlphaNumerics, '')
      .toLowerCase()
      .split('')
      // hash
      .reduce(simpleHash, 0)
      .toString()
  );
};

const Checkbox: React.FC<Props> = (props: Props) => {
  const { pathname } = useRouter();
  const [value, setValue] = useState(false);
  const { checklists, dispatch } = useContext(Context);
  const uniqueId = useUniqueId('checkboxId');
  const checklistId = props.checklistId
    ? `${props.checklistId}:${childrenAsIdString(props.children)}`
    : childrenAsIdString(props.children);

  useEffect(() => {
    // checklist loads from localstorage - check if it's available
    if (checklists != null && checklists[pathname]) {
      const checked = checklists && checklists[pathname].includes(checklistId);
      setValue(checked);
    }
  }, [checklists, checklistId, pathname]);

  const onChange = (e) => {
    const checked = e.currentTarget.checked;
    setValue(checked);
    if (checked) {
      dispatch({
        type: 'ADD_CHECKLIST',
        payload: { key: pathname, value: checklistId },
      });
    } else {
      dispatch({
        type: 'REMOVE_CHECKLIST',
        payload: { key: pathname, value: checklistId },
      });
    }
  };

  return (
    <ThreadsCheckbox
      id={uniqueId}
      value={value}
      onChange={onChange}
      className={styles.checkbox}
    >
      {props.children}
    </ThreadsCheckbox>
  );
};

Checkbox.displayName = 'Checkbox';
export default Checkbox;
