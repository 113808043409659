import { ErrorReference } from '../../types';

export const ITEM_LOGIN_REQUIRED: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'ITEM_LOGIN_REQUIRED',
    error_message:
      "the login details of this item have changed (credentials, MFA, or required user action) and a user login is required to update this information. use Link's update mode to restore the item to a good state",
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'Username or password incorrect',
    link_error_icon: 'close',
    link_error_message:
      "If you've recently updated your account with this institution, be sure you're entering your updated credentials",
    link_error_button: 'Try again',
    error_code: 'ITEM_LOGIN_REQUIRED',
  },
};

export const INSTANT_MATCH_FAILED: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INSTANT_MATCH_FAILED',
    error_message:
      'Item cannot be verified through Instant Match. Ensure you are correctly enabling all auth features in Link.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const PRODUCT_NOT_ENABLED: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'PRODUCT_NOT_ENABLED',
    error_message:
      'A requested product was not enabled for the current access token. Please ensure it is included when when initializing Link and create the Item again.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const ITEM_CONCURRENTLY_DELETED: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'ITEM_CONCURRENTLY_DELETED',
    error_message: 'This item was deleted while the operation was in progress',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const ITEM_PRODUCT_NOT_READY: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'PRODUCT_NOT_READY',
    error_message:
      'the requested product is not yet ready. please provide a webhook or try the request again later',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_UPDATED_USERNAME: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INVALID_UPDATED_USERNAME',
    error_message:
      'the username provided to /item/credentials/update does not match the original username for the item',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'Error',
    link_error_icon: 'close',
    link_error_message:
      'Try entering your bank account username again. If you recently changed it, you may need to un-link your account and then re-link.',
    link_error_button: 'Try again',
    error_code: 'INVALID_UPDATED_USERNAME',
  },
};

export const INSUFFICIENT_CREDENTIALS: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INSUFFICIENT_CREDENTIALS',
    error_message:
      'insufficient authorization was provided to complete the request',
    display_message: 'INSUFFICIENT_CREDENTIALS',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Access Denied',
    link_error_icon: 'close',
    link_error_message: 'The authorization flow did not complete',
    link_error_button: 'Exit',
    error_code: 'INSUFFICIENT_CREDENTIALS',
  },
};

export const INVALID_CREDENTIALS: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INVALID_CREDENTIALS',
    error_message: 'the provided credentials were not correct',
    display_message:
      'The provided credentials were not correct. Please try again.',
    http_code: 400,
  },
  link: {
    link_error_heading: 'The credentials you provided were incorrect',
    link_error_message:
      'Check that your credentials are the same that you use for this institution',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'INVALID_CREDENTIALS',
  },
};

export const INVALID_MFA_DEVICE: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INVALID_MFA',
    error_message: 'the provided MFA response(s) were not correct',
    display_message:
      'The provided MFA responses were not correct. Please try again.',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Security code incorrect',
    link_error_message:
      'Check that the code you entered is the same code that was sent to you',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'INVALID_MFA',
    mfa_type: 'device',
  },
};

export const INVALID_MFA: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INVALID_MFA',
    error_message: 'the provided MFA response(s) were not correct',
    display_message:
      'The provided MFA responses were not correct. Please try again.',
    http_code: 400,
  },
  link: {
    link_error_heading: 'The credentials you provided were incorrect',
    link_error_message:
      'For security reasons, your account may be locked after several unsuccessful attempts',
    link_error_icon: 'close',
    link_error_button: 'Retry login',
    error_code: 'INVALID_MFA',
    mfa_type: 'flexible_input',
  },
};

export const INVALID_PHONE_NUMBER: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INVALID_PHONE_NUMBER',
    error_message: 'the provided phone number was invalid',
    display_message: 'The provided phone number was invalid. Please try again.',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Invalid Phone Number',
    link_error_message:
      "We couldn't verify that 4151231234 is a valid number. Please re-enter your phone number to try again.",
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'INVALID_PHONE_NUMBER',
  },
};

export const INVALID_OTP: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INVALID_OTP',
    error_message: 'the provided OTP was invalid',
    display_message: 'The provided OTP was invalid. Please try again.',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Security code incorrect',
    link_error_message:
      'Check that the code you entered is the same code that was sent to you',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'INVALID_OTP',
  },
};

export const INVALID_SEND_METHOD: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INVALID_SEND_METHOD',
    error_message: 'the provided MFA send_method was invalid',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const ITEM_LOCKED: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'ITEM_LOCKED',
    error_message:
      "the account is locked. prompt the user to visit the institution's site and unlock their account",
    display_message:
      "The given account has been locked by the financial institution. Please visit your financial institution's website to unlock your account.",
    http_code: 400,
  },
  link: {
    link_error_heading: 'Too many attempts',
    link_error_message:
      'Your account is locked for security reasons. Reset your bank username and password, and then try again.',
    link_error_icon: 'lock',
    link_error_button: 'Continue',
    error_code: 'ITEM_LOCKED',
  },
};

export const ITEM_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'ITEM_NOT_FOUND',
    error_message:
      'The Item you requested cannot be found. This Item does not exist, has been previously removed via /item/remove, or has had access removed by the user.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const ITEM_NOT_SUPPORTED = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'ITEM_NOT_SUPPORTED',
    error_message: 'this account is currently not supported',
    display_message:
      'The given account is not currently supported for this financial institution. We apologize for the inconvenience.',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Account not currently supported',
    link_error_message:
      'Your account is not currently supported. Please log in using a different account',
    link_error_icon: 'close',
    link_error_button: 'Setup account',
    error_code: 'ITEM_NOT_SUPPORTED',
  },
};

export const INCORRECT_DEPOSIT_VERIFICATION = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INCORRECT_DEPOSIT_VERIFICATION',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'Code is incorrect, 2 attempts remaining',
    link_error_message:
      'Check the bank statement of your account ending in •••0000 for the <b>$0.01 deposit</b> and try again.',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'INCORRECT_DEPOSIT_VERIFICATION',
  },
};

export const INCORRECT_DEPOSIT_AMOUNTS = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'INCORRECT_DEPOSIT_AMOUNTS',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: {
    ink_error_heading: 'Amounts were incorrect, 2 attempts remaining',
    link_error_message:
      'Check the account statement of your account ending in 0000 for the two small deposits and try again',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'INCORRECT_DEPOSIT_AMOUNTS',
  },
};

export const TOO_MANY_VERIFICATION_ATTEMPTS = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'TOO_MANY_VERIFICATION_ATTEMPTS',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'No attempts remaining',
    link_error_message:
      'You’ve used up all of your attempts. To continue, connect a different bank',
    link_error_icon: 'close',
    link_error_button: 'Exit',
    error_code: 'TOO_MANY_VERIFICATION_ATTEMPTS',
  },
};

export const USER_SETUP_REQUIRED = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'USER_SETUP_REQUIRED',
    error_message:
      "the account has not been fully set up. prompt the user to visit the issuing institution's site and finish the setup process",
    display_message:
      "The given account is not fully setup. Please visit your financial institution's website to setup your account.",
    http_code: 400,
  },
  link: {
    link_error_heading: 'Action required with your account',
    link_error_message:
      'Log in to your bank and update your account. Then, return to Plaid to continue.',
    link_error_icon: 'lock',
    link_error_button: 'Continue',
    error_code: 'USER_SETUP_REQUIRED',
  },
};

export const MFA_NOT_SUPPORTED = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'MFA_NOT_SUPPORTED',
    error_message:
      'this account requires a MFA type that we do not currently support for the institution',
    display_message:
      'The multi-factor security features enabled on this account are not currently supported for this financial institution. We apologize for the inconvenience.',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Your account settings are incompatible',
    link_error_message:
      'Your account could not be connected because the multi-factor authentication method it uses is not currently supported. Please try a different account.',
    link_error_icon: 'close',
    link_error_button: 'Return',
    error_code: 'MFA_NOT_SUPPORTED',
  },
};

export const NO_ACCOUNTS = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'NO_ACCOUNTS',
    error_message: 'no valid accounts were found for this item',
    display_message:
      "No valid accounts were found at the financial institution. Please visit your financial institution's website to confirm accounts are available.",
    http_code: 400,
  },
  link: {
    link_error_heading: 'No compatible accounts',
    link_error_message:
      'Your credentials are correct, but we couldn’t find any accounts with this institution that are compatible with this application. Try another account, financial institution, or check for another connection method.',
    link_error_icon: 'close',
    link_error_button: 'Return',
    error_code: 'NO_ACCOUNTS',
  },
};

export const NO_AUTH_ACCOUNTS = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'NO_AUTH_ACCOUNTS',
    error_message:
      'There are no valid checking or savings account(s) associated with this Item. See https://plaid.com/docs/api/#item-errors for more.',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'No eligible accounts',
    link_error_message:
      "We didn't find any checking or savings accounts at this institution. Please try linking another institution",
    link_error_icon: 'close',
    link_error_button: 'Return',
    error_code: 'NO_AUTH_ACCOUNTS',
  },
};

export const NO_INVESTMENT_ACCOUNTS = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'NO_INVESTMENT_ACCOUNTS',
    error_message:
      'There are no valid investment account(s) associated with this Item. See https://plaid.com/docs/api/#item-errors for more information.',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'No investment accounts',
    link_error_message:
      'None of your accounts are investment accounts. Please connect using a different bank',
    link_error_icon: 'close',
    link_error_button: 'Return',
    error_code: 'NO_INVESTMENT_ACCOUNTS',
  },
};

export const NO_INVESTMENT_AUTH_ACCOUNTS = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'NO_INVESTMENT_AUTH_ACCOUNTS',
    error_message:
      'There are no valid investment account(s) associated with this Item. See https://plaid.com/docs/api/#item-errors for more information.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const NO_LIABILITY_ACCOUNTS = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'NO_LIABILITY_ACCOUNTS',
    error_message:
      'There are no valid liability account(s) associated with this Item. See https://plaid.com/docs/api/#item-errors for more information.',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'No liability accounts',
    link_error_message:
      'None of your accounts are liability accounts. Please connect using a different bank',
    link_error_icon: 'close',
    link_error_button: 'Return',
    error_code: 'NO_LIABILITY_ACCOUNTS',
  },
};

export const PRODUCTS_NOT_SUPPORTED = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'PRODUCTS_NOT_SUPPORTED',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const ACCESS_NOT_GRANTED: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'ACCESS_NOT_GRANTED',
    error_message: 'access to this product was not granted for the account',
    display_message:
      'The user did not grant the necessary permissions for this product on their account.',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Insufficient Sharing Permissions',
    link_error_message:
      'There was an error connecting to your account. Try linking your account again by selecting the required information to share with this application.',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'ACCESS_NOT_GRANTED',
  },
};

export const USER_INPUT_TIMEOUT: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'USER_INPUT_TIMEOUT',
    error_message: 'user must retry this operation',
    display_message: 'The application timed out waiting for user input',
    http_code: 400,
  },
  link: {
    link_error_heading: 'Session expired',
    link_error_message:
      'Please re-enter your information to link your accounts.',
    link_error_icon: 'close',
    link_error_button: 'Try again',
    error_code: 'USER_INPUT_TIMEOUT',
  },
};

export const PASSWORD_RESET_REQUIRED: ErrorReference = {
  server: {
    error_type: 'ITEM_ERROR',
    error_code: 'PASSWORD_RESET_REQUIRED',
    error_message: 'user must reset their password',
    display_message: 'The user needs to reset their password for their account',
    http_code: 400,
  },
  link: null,
};
