import React from 'react';
import { OpenAPIObject } from 'openapi3-ts';

import fourDotSix from './4.6/corex.yml';
import fiveDotZero from './5.0/corex.yml';
import fiveDotOne from './5.1/corex.yml';
import fiveDotTwo from './5.2/corex.yml';
import fiveDotThree from './5.3/corex.yml';
import sixDotZero from './6.0/corex.yml';
import sixDotOne from './6.1/corex.yml';

export interface CoreExchangeOpenApiSchemas {
  '4.6': OpenAPIObject;
  '5.0': OpenAPIObject;
  '5.1': OpenAPIObject;
  '5.2': OpenAPIObject;
  '5.3': OpenAPIObject;
  '6.0': OpenAPIObject;
  '6.1': OpenAPIObject;
}

const schemas: CoreExchangeOpenApiSchemas = {
  '4.6': (fourDotSix as unknown) as OpenAPIObject,
  '5.0': (fiveDotZero as unknown) as OpenAPIObject,
  '5.1': (fiveDotOne as unknown) as OpenAPIObject,
  '5.2': (fiveDotTwo as unknown) as OpenAPIObject,
  '5.3': (fiveDotThree as unknown) as OpenAPIObject,
  '6.0': (sixDotZero as unknown) as OpenAPIObject,
  '6.1': (sixDotOne as unknown) as OpenAPIObject,
};

const OpenAPICoreExchangeContext = React.createContext<
  CoreExchangeOpenApiSchemas
>(schemas);

export const { Consumer, Provider } = OpenAPICoreExchangeContext;

export const CoreExchangeOpenAPIProvider: React.FC = (props) => {
  return <Provider value={schemas}>{props.children}</Provider>;
};

export default OpenAPICoreExchangeContext;
