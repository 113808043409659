import React from 'react';

import StepWithDiagram from '../StepWithDiagram';

interface Props {}

const PaymentInitiationFlowOverview: React.FC<Props> = (props: Props) => (
  <>
    <StepWithDiagram
      asset='/assets/img/docs/payment_initiation/PIS-overview-row-0.png'
      hideBorder={true}
    >
      <b>The Plaid flow</b> begins when your user wants to initiate a payment to
      fund their account.
    </StepWithDiagram>
    <StepWithDiagram
      step={1}
      asset='/assets/img/docs/payment_initiation/PIS-overview-row-1.png'
    >
      Your backend sets up the payment and creates a{' '}
      <a href='/docs/api/products/payment-initiation/'>
        <code>payment_initiation</code>{' '}
      </a>
      <a href='/docs/api/link/#create-link-token'>
        <code>link_token</code>{' '}
      </a>
      by interfacing with Plaid endpoints. It then passes the temporary token to
      your client app.
    </StepWithDiagram>
    <StepWithDiagram
      step={2}
      asset='/assets/img/docs/payment_initiation/PIS-overview-row-2.png'
    >
      Your client app uses the <code>link_token</code> to initiate a Link flow
      for your user. The{' '}
      <a href='/docs/link/web/#onsuccess'>
        <code>onSuccess</code> callback
      </a>{' '}
      signals that the payment has been initiated.
    </StepWithDiagram>
    <StepWithDiagram
      step={3}
      asset='/assets/img/docs/payment_initiation/PIS-overview-row-3.png'
    >
      Your backend listens for{' '}
      <a href='/docs/api/products/payment-initiation#Webhooks'>
        <code>PAYMENT_STATUS_UPDATE</code>
      </a>{' '}
      webhooks to keep track of the payment's status.
    </StepWithDiagram>
  </>
);

PaymentInitiationFlowOverview.displayName = 'PaymentInitiationFlowOverview';

export default PaymentInitiationFlowOverview;
