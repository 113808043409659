import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useHits, useInstantSearch } from 'react-instantsearch';
import { useRouter } from 'next/router';

import { ROUTE_MAP_FLAT, NavItem } from '../constants/routeMap';
import { getSection, withBasePath } from '../utilities';
import Context from '../../../contexts/docs';
import SearchHit from './SearchHit';
import RelatedArticleHit from './RelatedArticleHit';
import { SEARCH_PRIORITIES } from '../constants';

import styles from './SearchResults.module.scss';

const MAX_RELATED_ARTICLES = 8;

const EmptyState = () => {
  const { pathname } = useRouter();
  const [relatedArticles, setRelatedArticles] = useState<Array<NavItem>>([]);

  useEffect(() => {
    const section = getSection(pathname);
    // will be null if on the homepage or other section with no children
    const maybeRelatedArticles = section?.children?.flatMap((s) => s.children);
    // articles we feature as a fallback
    const fallbackRelatedArticles = ROUTE_MAP_FLAT.filter((r) => r.featured);
    setRelatedArticles(
      maybeRelatedArticles != null
        ? maybeRelatedArticles
        : fallbackRelatedArticles,
    );
  }, [pathname]);

  return (
    <div className={styles.emptyState}>
      <h6 className={styles.emptyStateHeading}>Related</h6>
      <ul className={styles.relatedArticles}>
        {relatedArticles.slice(0, MAX_RELATED_ARTICLES).map((article, idx) => (
          <li key={idx}>
            <RelatedArticleHit
              key={idx}
              title={getSection(article.path).title}
              subtitle={article.title}
              href={withBasePath(article.path)}
              icon={article.icon}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const NoResults = ({ query }) => (
  <div className={styles.noResults} role='alert'>
    <p className={styles.noResultsText}>
      <span className={styles.semibold}>
        Try searching for another word or phrase, or{' '}
        <a href={window.location.href + '?showChat=true'}>Ask Bill!</a>
      </span>
      <br />
      No matches were found for <span className={styles.semibold}>{query}</span>
    </p>
  </div>
);
interface SearchHit {
  // this is just partial type that does not show all keys in object; only those necessary for code to work
  type: string;
  path?: string;
  heirarchy: {
    lvl0: string;
  };
}

interface HitsProps {
  hits: Array<SearchHit>;
  query: string;
  sendEvent: Function;
}

const glossaryCat = 'Glossary';
const apiSchemaCat = 'API Schema';
const apiRefCat = 'API Reference';

const isHitInCategory = (hit, category) => {
  if (
    (hit.type === 'glossary' && category === glossaryCat) ||
    (hit.type === 'api' && category === apiSchemaCat) ||
    (hit.type !== 'api' &&
      hit.heirarchy.lvl0 === 'API' &&
      category === apiRefCat) ||
    hit.heirarchy.lvl0 === category
  ) {
    return true;
  }
  return false;
};

const prioritizeHits = (hitsArray) => {
  const first = [];
  const last = [];
  const other = [];
  hitsArray.forEach((hit) => {
    if (SEARCH_PRIORITIES.first.includes(hit.path)) {
      first.push(hit);
    } else if (SEARCH_PRIORITIES.last.includes(hit.path)) {
      last.push(hit);
    } else {
      other.push(hit);
    }
  });
  return first.concat(other).concat(last);
};

const Hits: React.FC<HitsProps> = ({ hits, query, sendEvent }) => {
  const [selectedElement, setSelectedElement] = useState(-1);
  const [selectedURL, setSelectedURL] = useState(null);
  const router = useRouter();
  const { dispatch } = useContext(Context);
  // Define pre-defined categories

  const sortedHits = useMemo(() => {
    // separate hits into "Glossary", "API Schema","API Reference"  and other categories.
    const limit = 8; // number of results shown per category
    const categoryCounter = {};

    let glossary: Array<SearchHit> = hits
      .filter((hit) => hit.type === 'glossary')
      .slice(0, limit);

    glossary = prioritizeHits(glossary);

    let apiSchema: Array<SearchHit> = hits // all hits of hit.type="api" are put in "API Schema" category
      .filter((hit) => hit.type === 'api')
      .slice(0, limit);

    apiSchema = prioritizeHits(apiSchema);

    let apiReference: Array<SearchHit> = hits // all hits of hit.heirarchy.lvl0="API" are put in "API Reference" category
      .filter((hit) => hit.type !== 'api' && hit.heirarchy.lvl0 === 'API')
      .slice(0, limit);

    apiReference = prioritizeHits(apiReference);

    let theRest: Array<SearchHit> = hits.filter((hit) => {
      // if not in glossary or API categories, the rest of the hits are put in theRest array.
      const category = hit.heirarchy.lvl0;
      if (hit.type !== 'glossary' && hit.type !== 'api' && category !== 'API') {
        categoryCounter[category] = categoryCounter[category] || 0;
        categoryCounter[category]++;
        return categoryCounter[category] <= limit; // to cap the number of hits in each category to the limit
      }
      return false;
    });

    theRest = prioritizeHits(theRest);

    const sorted: Array<SearchHit> = theRest.sort((a, b) =>
      a.heirarchy.lvl0 >= b.heirarchy.lvl0 ? 1 : -1,
    );
    return query.toLowerCase().trim() === 'link' // if "link", make link subject results come before glossary
      ? sorted.concat(glossary).concat(apiReference).concat(apiSchema)
      : glossary.concat(apiReference).concat(apiSchema).concat(sorted);
  }, [hits, query]);

  const categories = useMemo(() => {
    // builds array of categories from sortedHits to use to map hits into ordered list by category
    const uniqueCategories = new Set();
    sortedHits.forEach((hit) => {
      if (hit.type === 'glossary') {
        uniqueCategories.add(glossaryCat);
      } else if (hit.type !== 'api' && hit.heirarchy?.lvl0 === 'API') {
        uniqueCategories.add(apiRefCat);
      } else if (hit.type === 'api') {
        uniqueCategories.add(apiSchemaCat);
      } else {
        uniqueCategories.add(hit.heirarchy.lvl0);
      }
    });

    const catsArray = Array.from(uniqueCategories);

    // move errors to the end of the categories
    if (catsArray.includes('Errors')) {
      return [...catsArray.filter((c) => c !== 'Errors'), 'Errors'];
    }
    return catsArray;
  }, [sortedHits]);

  const onEvent = useCallback(
    // navigation keys for search results
    (key) => {
      switch (key.keyCode) {
        case 38: // up arrow
          setSelectedElement(Math.max(0, selectedElement - 1));
          break;
        case 40: // down arrow
          setSelectedElement(
            Math.min(sortedHits.length - 1, selectedElement + 1),
          );
          break;
        case 78: // ctrl n
          if (key.ctrlKey) {
            setSelectedElement(
              Math.min(sortedHits.length - 1, selectedElement + 1),
            );
          }
          break;
        case 80: //ctrl p
          if (key.ctrlKey) {
            setSelectedElement(Math.max(0, selectedElement - 1));
          }
          break;
        case 13: // Enter
          if (selectedURL != null) {
            router.push(selectedURL);
            dispatch({
              type: 'SET_SEARCH_VISIBILITY',
              payload: { visibility: false, openSearchClicked: false },
            });
          }
          break;
        default:
          if (!key.ctrlKey) {
            setSelectedElement(0);
          }
      }
    },
    [dispatch, router, selectedElement, selectedURL, sortedHits.length],
  );

  useEffect(() => {
    window.addEventListener('keydown', onEvent);
    return () => window.removeEventListener('keydown', onEvent);
  }, [onEvent]);

  return (
    <>
      {categories.map((category, catIndex) => (
        <React.Fragment key={catIndex}>
          <div className={styles.category}>{category}</div>
          <ul className={styles.listContainer}>
            {sortedHits.map((hit, index) => {
              if (isHitInCategory(hit, category)) {
                return (
                  <li
                    role='option'
                    aria-selected={index === selectedElement}
                    key={index}
                  >
                    <SearchHit
                      hit={hit}
                      isSelected={index === selectedElement}
                      setURL={setSelectedURL}
                      sendEvent={sendEvent}
                    />
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </React.Fragment>
      ))}
    </>
  );
};

const CustomHits = connectHits(Hits);

const SearchResults = () => {
  const { indexUiState, results } = useInstantSearch();
  if (!indexUiState.query) {
    return <EmptyState />;
  }
  if (results && results.nbHits !== 0) {
    return <CustomHits query={indexUiState.query} />;
  } else {
    return <NoResults query={indexUiState.query} />;
  }
};

SearchResults.displayName = 'SearchResults';

export default SearchResults;

function connectHits(Component) {
  const Hits = (props) => {
    const { hits, results, sendEvent } = useHits(props);

    return (
      <Component
        {...props}
        hits={hits}
        results={results}
        sendEvent={sendEvent}
      />
    );
  };

  return Hits;
}
