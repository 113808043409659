import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { getCookie } from 'public/assets/js/analytics/cookie';

interface Props {}

const probablyPointsToReference = (path: string) => {
  const pathStarts = [
    `docs/api/products`,
    'docs/api/items',
    'docs/api/accounts',
    'docs/api/institutions',
    'docs/api/tokens',
    'docs/api/processors',
    'docs/api/sandbox',
  ];
  return pathStarts.some((pathStart) => path.startsWith(pathStart));
};

const probablyPointsToIndexFile = (path: string) => {
  const pathEnds = [
    'docs/account',
    'docs/api',
    'docs/api/webhooks',
    'docs/assets',
    'docs/assets/waitlist',
    'docs/assets/partnerships',
    'docs/auth',
    'docs/auth/coverage',
    'docs/auth/partnerships',
    'docs/balance',
    'docs/bank-transfers',
    'docs/deposit-switch',
    'docs/errors',
    'docs/identity',
    'docs/identity-verification',
    'docs/income',
    'docs/investments',
    'docs/launch-checklist',
    'docs/liabilities',
    'docs/link',
    'docs/link/android',
    'docs/link/ios',
    'docs/link/react-native',
    'docs/monitor',
    'docs/payment-initiation',
    'docs/quickstart',
    'docs/sandbox',
    'docs/signal',
    'docs/support',
    'docs/transactions',
    'docs/transfer',
    'docs/virtual-accounts',
    'docs',
  ];
  return pathEnds.some((pathEnd) => path.endsWith(pathEnd));
};

const extractBaseFileFromPathname = (path: string) => {
  if (path.endsWith('/')) {
    path = path.slice(0, -1);
  }
  const baseFile = probablyPointsToIndexFile(path)
    ? `src/pages/${path}/index.mdx`
    : `src/pages/${path}.mdx`;
  return baseFile;
};

/**
 * If you're on the VPN and we detect the likely-internal-user cookie:
 * - We show the plocs menu by default
 * - Selecting "Hide this" will hide the menu for the duration of the session
 * - Typing "plocs" will show the menu again
 *
 * If you're not on the VPN and we don't see the likely-internal-user cookie:
 * - We hide the plocs menu by default
 * - Typing "plocs" will show the menu -- generally not helpful, but might be
 * if there's a false negative in the "are you an internal user" check
 * - Selecting "Hide this" will hide the menu again
 */

const BugFiler: React.FC<Props> = () => {
  const MAGIC_WORD = 'PLOCS';
  const THE_PHRASE_THAT_PAYS = 'fancier-bustard-trope-warring-nuisance';
  const wordLength = MAGIC_WORD.length;
  const [recentChars, _setRecentChars] = useState('x'.repeat(wordLength));
  const [showInterface, setShowInterface] = useState(false);

  // A bit of a workaround to access "recentChars" within our event handler
  const recentCharsRef = useRef(recentChars);
  const setRecentChars = (data) => {
    recentCharsRef.current = data;
    _setRecentChars(data);
  };

  const getJiraReference = () => {
    const targetRef = new URL(
      'https://plaidinc.atlassian.net/secure/CreateIssueDetails!init.jspa',
    );
    targetRef.searchParams.append('issuetype', '10012');
    targetRef.searchParams.append('pid', '10056');
    targetRef.searchParams.append('summary', '(Summary goes here)');
    targetRef.searchParams.append('priority', '3');
    const pathName = window.location.pathname.slice(1);
    const baseFilePath = extractBaseFileFromPathname(pathName);
    const fileLocation = probablyPointsToReference(pathName)
      ? `The openAPI file in the go.git repository, or ${baseFilePath}`
      : baseFilePath;
    let description = `*URL:* ${window.location.href}\n*Source doc:* ${fileLocation}`;
    if (window.getSelection().toString() !== '') {
      description += `\n*Selected text:* "${window
        .getSelection()
        .toString()
        .slice(0, 1800)}"`;
    } else {
      description += `\n(Pro-tip: Selected text will appear in this description, too!)`;
    }
    targetRef.searchParams.append('description', description);
    return targetRef;
  };

  const getGitHubReference = () => {
    const pathName = window.location.pathname.slice(1);
    let targetRef;
    if (probablyPointsToReference(pathName)) {
      targetRef = new URL(`https://eng-docs.plaid.io/tools/openapi/editing/`);
    } else {
      const file = extractBaseFileFromPathname(pathName);
      targetRef = new URL(
        `https://github.plaid.com/plaid/plaid-dot-com/edit/master/${file}`,
      );
    }

    return targetRef;
  };

  const handleKeyUp = (e) => {
    const newWord = recentCharsRef.current.slice(1) + e.key.slice(0, 1);
    setRecentChars(newWord);
    if (newWord.toUpperCase() === MAGIC_WORD) {
      setShowInterface(true);
      sessionStorage.removeItem('hideDebugMenu');
    }
  };

  const listenForSecretCode = () => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  };

  const loadPersistentFlag = () => {
    if (
      getCookie('likely-internal-user') === THE_PHRASE_THAT_PAYS &&
      sessionStorage.getItem('hideDebugMenu') !== 'true'
    ) {
      setShowInterface(true);
    }
  };

  const hideDebugMenu = () => {
    sessionStorage.setItem('hideDebugMenu', 'true');
    setShowInterface(false);
  };

  useEffect(() => {
    loadPersistentFlag();
    return listenForSecretCode();
  }, []);

  return (
    showInterface && (
      <>
        <div className={styles.containerDiv}>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column-reverse',
              alignItems: 'stretch',
              alignContent: 'stretch',
              flexShrink: 0,
            }}
          >
            <div className={styles.mainButton}>Problem?</div>
            <div
              className={styles.subButton}
              onClick={() => {
                const jiraRef = getJiraReference();
                window.open(jiraRef, '_blank');
              }}
            >
              File a bug
            </div>
            <div
              className={styles.subButton}
              onClick={() => {
                const gitHubRef = getGitHubReference();
                window.open(gitHubRef, '_blank');
              }}
            >
              Edit
            </div>
            <div
              className={styles.subButton}
              onClick={() => {
                hideDebugMenu();
              }}
            >
              Hide this
            </div>
          </div>
        </div>
      </>
    )
  );
};

BugFiler.displayName = 'BugFiler';

export default BugFiler;
