import React, { useContext } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import ChevronS1Down from 'plaid-threads/Icons/ChevronS1Down';
import ChevronS1Right from 'plaid-threads/Icons/ChevronS1Right';
import Touchable from 'plaid-threads/Touchable';

import { NavItem, NavGroup } from '../constants/routeMap';
import { withBasePath } from '../utilities';

import styles from './SidebarNavGroup.module.scss';
import DocsContext from 'src/contexts/docs';
import useUser from 'src/contexts/docs/useUser';

const Item = ({ route, isActive, isNested, baseUrl }) => {
  const { isLoggedIn } = useContext(DocsContext);
  const { getFlagValue } = useUser();

  return (
    (route.flag == null || (isLoggedIn && getFlagValue(route.flag))) && (
      <li>
        <Link href={withBasePath(route.path, baseUrl)}>
          <a
            className={cx(
              styles.navItem,
              isActive && styles.active,
              isNested && styles.nested,
            )}
          >
            {!isNested && <route.icon className={styles.icon} />}
            <span className={isNested && styles.nestedTitleSpan}>
              {route.title}
            </span>
          </a>
        </Link>
      </li>
    )
  );
};
interface Props {
  currentRoute: NavItem;
  navGroup: NavGroup;
  dark: boolean;
  baseUrl: string;
  isCollapsible?: boolean;
}

const SidebarNavGroup: React.FC<Props> = ({
  currentRoute,
  navGroup,
  dark,
  baseUrl,
  isCollapsible = true,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const { isLoggedIn } = useContext(DocsContext);

  return (
    <>
      {navGroup.map((section, idx) => (
        <section
          className={cx(styles.topNavSection, dark && styles.dark)}
          key={idx}
        >
          {section.title && isCollapsible ? (
            <Touchable
              onClick={() => (isCollapsible ? setIsOpen(!isOpen) : null)}
              className={styles.sectionTitle}
            >
              {section.icon ? (
                <section.icon className={styles.icon} />
              ) : isOpen ? (
                <ChevronS1Down className={styles.icon} />
              ) : (
                <ChevronS1Right className={styles.icon} />
              )}
              <span>{section.title}</span>
            </Touchable>
          ) : section.title && section.path ? (
            <Touchable
              href={withBasePath(section.path, baseUrl)}
              className={cx(styles.sectionTitle, styles.hoverable)}
            >
              {section.icon && <section.icon className={styles.icon} />}
              <span>{section.title}</span>
            </Touchable>
          ) : (
            <div className={styles.sectionTitle}>
              {section.icon && <section.icon className={styles.icon} />}
              <span>{section.title}</span>
            </div>
          )}
          {(!section.title || isOpen) && (
            <ul className={styles.listWrapper} key={idx}>
              {section.children &&
                section.children.map(
                  (route, idx) =>
                    (route.disabled == null || route.disabled === false) &&
                    (!route.loggedIn || isLoggedIn) && (
                      <Item
                        key={`sidebar-nav-item-${idx}`}
                        route={route}
                        isActive={
                          route.highlight &&
                          (currentRoute
                            ? route.path === currentRoute.path
                            : route.title === 'Home')
                        }
                        isNested={section.title}
                        baseUrl={baseUrl}
                      />
                    ),
                )}
            </ul>
          )}
        </section>
      ))}
    </>
  );
};

SidebarNavGroup.displayName = 'SidebarNavSections';
export default SidebarNavGroup;
