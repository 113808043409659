import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';

import styles from './index.module.scss';

import { optimizeImage } from 'src/lib/image-helpers';
import { useSpring, animated, useReducedMotion } from '@react-spring/web';

const QUERY = '(prefers-reduced-motion: no-preference)';

interface Props {
  alt: string;
  src: string;
  width?: string;
  height?: string;
  size?: 'cover' | 'small' | 'medium'; // TODO add more sizes
  position?: 'left' | 'right' | 'center'; // TODO implement these
  className?: string;
  caption?: string;
  breakout?: boolean;
  noMarginBottom?: boolean;
  expandable?: boolean;
}

const WINDOW_MIN_FOR_EXPANSION = 1200;

const Image: React.FC<Props> = ({
  src,
  size,
  width = '',
  height = '',
  className = '',
  position = '',
  alt,
  caption,
  breakout = false,
  noMarginBottom,
  expandable = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const imageRef = useRef(null);
  const [springProps, setSpringProps] = useSpring(() => ({
    config: { mass: 5, tension: 500, friction: 80 },
  }));
  const [divSpringProps, setDivSpringProps] = useSpring(() => ({
    background: 'rgba(0,0,0,0)',
  }));
  const [reducedMotion, setReducedMotion] = useState<boolean>(false);

  function checkForPrefersReducedMotion() {
    const mediaQueryList = window.matchMedia(QUERY);
    setReducedMotion(!window.matchMedia(QUERY).matches);
    // Register our event listener
    const listener = (event) => {
      setReducedMotion(!event.matches);
    };
    mediaQueryList.addEventListener('change', listener);
    return () => {
      mediaQueryList.removeEventListener('change', listener);
    };
  }

  useEffect(() => {
    // This code runs on the client side after mount
    if (expandable && window != undefined) {
      const cleanupFunction = checkForPrefersReducedMotion();
      return cleanupFunction;
    }
  }, [expandable]);

  const toggleExpand = () => {
    if (expandable) {
      const rect = imageRef.current.getBoundingClientRect();
      const xTransform = window.innerWidth / 2 - rect.left - rect.width / 2;
      const yTransform = window.innerHeight / 2 - rect.top - rect.height / 2;
      if (!isExpanded && window.innerWidth >= WINDOW_MIN_FOR_EXPANSION) {
        setIsExpanded(true);
        setSpringProps({
          from: {
            width: rect.width,
            height: rect.height,
            transform: 'translate3d(0px,0px,0) scale(1)',
            top: rect.top,
            left: rect.left,
          },
          to: {
            transform: `translate3d(${xTransform}px, ${yTransform}px,0) scale(2)`,
          },
          immediate: reducedMotion,
        });
        setDivSpringProps({
          to: {
            background: 'rgba(0,0,0,0.5)',
          },
        });
      } else {
        setSpringProps({
          to: {
            transform: 'translate3d(0,0,0) scale(1)',
          },
          onRest: {
            transform: () => {
              setIsExpanded(false);
            },
          },
          immediate: reducedMotion,
        });
        setDivSpringProps({
          to: {
            background: 'rgba(0,0,0,0.0)',
          },
        });
      }
    }
  };

  return (
    <>
      <div
        className={cx(styles.container, {
          [styles.noMarginBottom]: noMarginBottom,
          [styles.breakoutContainer]: breakout,
        })}
        onClick={toggleExpand}
      >
        <img
          className={cx(className, {
            [styles.image]: !className,
            [size === 'small' && styles.small]: !className,
            [size === 'medium' && styles.medium]: !className,
            [position === 'center' && styles.centered]: !className,
            [styles.clickable]: expandable,
          })}
          ref={imageRef}
          src={optimizeImage(src)}
          alt={alt}
          width={width}
          height={height}
        />
        {caption && (
          <figcaption dangerouslySetInnerHTML={{ __html: caption }} />
        )}
        {expandable && isExpanded && (
          <animated.div
            onClick={toggleExpand}
            style={{
              ...divSpringProps,
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 1000,
            }}
          >
            <animated.img
              src={optimizeImage(src)}
              alt={alt}
              width={640}
              height={480}
              style={{
                ...springProps,
                position: 'fixed',
              }}
            />
          </animated.div>
        )}
      </div>
    </>
  );
};

Image.displayName = 'Image';
export default Image;
