import React, { useContext, useEffect } from 'react';

import Context from '../../../contexts/docs';
import { Keys } from '../../../components/docs/types';

interface Props {
  children: any;
}

const KeypressWrapper = (props: Props) => {
  const { dispatch } = useContext(Context);

  const onEvent = (e: KeyboardEvent): any => {
    if (e.defaultPrevented) {
      return; // Do nothing if the event was already processed
    }

    const key = e.key as Keys;

    // TODO: stop e.preventDefault() if `/` and searchbox modal is open
    if (key === Keys.ESCAPE || key === Keys.FORWARD_SLASH) {
      dispatch({
        type: 'GLOBAL_KEY_PRESSED',
        payload: { key: key },
      });
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onEvent);
    return () => window.removeEventListener('keydown', onEvent);
  });

  return props.children;
};

KeypressWrapper.displayName = 'KeypressWrapper';
export default KeypressWrapper;
