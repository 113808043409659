import { ErrorReference } from '../../types';

export const ACCOUNTS_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'ACCOUNTS_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const CREDITS_EXHAUSTED: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'CREDITS_EXHAUSTED',
    error_message:
      'Free usage exhausted, please request full Production access to continue using this product',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const AUTH_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'AUTH_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const INSTITUTIONS_GET_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'INSTITUTIONS_GET_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access "institutions get by id". please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const INSTITUTIONS_GET_BY_ID_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'INSTITUTIONS_GET_BY_ID_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access "institutions get by id". please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const IDENTITY_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'IDENTITY_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const ACCOUNTS_BALANCE_GET_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'ACCOUNTS_BALANCE_GET_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const INCOME_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'INCOME_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const INVESTMENT_HOLDINGS_GET_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'INVESTMENT_HOLDINGS_GET_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const INVESTMENT_TRANSACTIONS_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'INVESTMENT_TRANSACTIONS_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const ITEM_GET_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'ITEM_GET_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const RATE_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'RATE_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const TRANSACTIONS_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'TRANSACTIONS_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const TRANSACTIONS_SYNC_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'TRANSACTIONS_SYNC_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};

export const BALANCE_LIMIT: ErrorReference = {
  server: {
    error_type: 'RATE_LIMIT_EXCEEDED',
    error_code: 'BALANCE_LIMIT',
    error_message:
      'rate limit exceeded for attempts to access this item. please try again later',
    display_message: null,
    http_code: 429,
  },
  link: null,
};
