import React, { FC } from 'react';
import ThreadsButton, { Props } from 'plaid-threads/Button';
export type { Props } from 'plaid-threads/Button';

/**
 * A simple wrapped component around the standard Threads button. Tried using the
 * other one from our components dictionary, but couldn't get the `size` prop working
 */
const Button: FC<Props> = ({ children, ...props }) => {
  return <ThreadsButton {...props}> {children} </ThreadsButton>;
};

Button.displayName = 'Button';
export default Button;
