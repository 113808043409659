import React, { useEffect, useRef, useContext } from 'react';
import ChatInput from './ChatInput';
import { useTrack } from 'src/contexts/AnalyticsContext';
import { CHATBOT_EVENTS } from 'src/lib/trackingEvents';
const DEFAULT_FINN_SERVER = 'wss://hello-finn.herokuapp.com/';
import ChatDialogLib from '@plaid/shared-frontend/build/chatbot/ChatDialog';
import { isDevelopment, isProduction, isTesting } from 'src/lib/constants';
import { getCookie } from 'public/assets/js/analytics/cookie';
import DocsContext from 'src/contexts/docs';

const serverOptions = [
  { label: 'Localhost', value: 'ws://localhost:5000/' },
  { label: 'Staging', value: 'wss://hello-finn-staging.herokuapp.com/' },
  { label: 'Prod', value: 'wss://hello-finn.herokuapp.com/' },
];

const ChatDialog = ({ isVisible }) => {
  const likelyInternal = useRef(false);
  const userId = useRef('');
  const anonId = useRef('');
  const track = useTrack();
  const { isLoggedIn, user } = useContext(DocsContext);

  useEffect(() => {
    // TODO: Duplicate logic! Move this to a utils file or something
    const THE_PHRASE_THAT_PAYS = 'fancier-bustard-trope-warring-nuisance';
    if (getCookie('likely-internal-user') === THE_PHRASE_THAT_PAYS) {
      likelyInternal.current = true;
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      userId.current = user.userId;
    }
    anonId.current = window.localStorage.getItem('ajs_anonymous_id') ?? '';
  }, [isLoggedIn, user]);

  const trackQuestionAsked = (question: string, lastQuestionID: string) => {
    const payload = {
      question: question,
      qid: lastQuestionID,
      likelyInternal: likelyInternal.current,
    };
    sendTrackingEvent(CHATBOT_EVENTS.QUESTION_ASKED, payload);
  };

  const trackFeedbackSubmitted = (feedback: string, lastQuestionID: string) => {
    const payload = {
      qid: lastQuestionID,
      feedback: feedback,
      likelyInternal: likelyInternal.current,
    };
    sendTrackingEvent(CHATBOT_EVENTS.FEEDBACK_GIVEN, payload);
  };

  const trackEventWithTiming = (
    event,
    lastQuestionID: string,
    timeQuestionSubmitted: number,
  ) => {
    const timeDelta = new Date().getTime() - timeQuestionSubmitted;

    const payload = {
      qid: lastQuestionID,
      responseTime: timeDelta,
      likelyInternal: likelyInternal.current,
    };
    sendTrackingEvent(event, payload);
  };

  const trackErrorWithOptionalCode = (
    lastQuestionID: string,
    errCode?: string,
  ) => {
    const payload = {
      qid: lastQuestionID,
      likelyInternal: likelyInternal.current,
    };
    if (errCode) {
      payload['errorCode'] = errCode;
    }
    sendTrackingEvent(CHATBOT_EVENTS.ERROR_RECEIVED, payload);
  };

  const sendTrackingEvent = (event: string, payload: any) => {
    const fullTrackerObject = {
      type: event,
      payload: payload,
    };
    track(fullTrackerObject);
  };

  return (
    <ChatDialogLib
      trackQuestionAsked={trackQuestionAsked}
      trackFeedbackSubmitted={trackFeedbackSubmitted}
      trackEventWithTiming={trackEventWithTiming}
      trackErrorWithOptionalCode={trackErrorWithOptionalCode}
      default_finn_server={DEFAULT_FINN_SERVER}
      isVisible={isVisible}
      isProduction={isProduction}
      isDevelopment={isDevelopment}
      isTesting={isTesting}
      ChatInput={ChatInput}
      serverOptions={serverOptions}
      anonymousId={anonId.current}
      userId={userId.current}
    />
  );
};

ChatDialog.displayName = 'ChatDialog';
export default ChatDialog;
