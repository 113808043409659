import React from 'react';

import styles from './Divider.module.scss';

interface Props {}

const Divider: React.FC<Props> = (props: Props) => (
  <div className={styles.divider}></div>
);

Divider.displayName = 'Divider';

export default Divider;
