import React, { useEffect, useContext, useState, useCallback } from 'react';

import Context from 'src/contexts/docs';
import { ANCHOR_OFFSET } from '../constants';

interface Props {
  scrollContainer: null | HTMLDivElement;
}

const CurrentActiveItem: React.FC<Props> = (props) => {
  const { dispatch, tableOfContents } = useContext(Context);
  const [currentLinkIndex, setCurrentLinkIndex] = useState(-1);

  const setTOCWaypoint = useCallback(
    (id) => {
      dispatch({
        type: 'SET_TOC_WAYPOINT',
        payload: {
          tableOfContentsActiveItem: id,
        },
      });
    },
    [dispatch],
  );

  //scroll function finds all elements from TOC, finds the topElementIndex and sets it to be the currentLinkIndex
  //scroll function needs tableofContents as a prop in order for it to use the most recent tableOfContents
  const onScroll = useCallback((tableOfContents) => {
    if (tableOfContents == null || tableOfContents.length === 0) {
      return;
    }

    const winHeight = window.innerHeight;

    const linkElements = tableOfContents.map((item) =>
      document.getElementById(item.id),
    );
    let topElementIndex = -1;

    if (linkElements.length > 0) {
      //determine which is the top heading on the page
      for (let index = 0; index <= linkElements.length - 1; index++) {
        const item = linkElements[index];
        if (winHeight != null && item != null) {
          if (item.getBoundingClientRect().top > 0) {
            topElementIndex = index;
            break;
          }
        }
      }
      //if top heading is above a certain point, set it as the topElementIndex;
      // else if it is below a certain point, the header above it should be the topElementIndex
      if (topElementIndex > -1) {
        if (
          linkElements[topElementIndex].getBoundingClientRect().top <=
          ANCHOR_OFFSET
        ) {
          setCurrentLinkIndex(topElementIndex);
        } else {
          setCurrentLinkIndex(topElementIndex - 1);
        }
      }
    }
  }, []);

  // upon change in currentLinkIndex, set the tableofContentsActiveItem.
  useEffect(() => {
    // When moving to a new page, set tableofContentsActiveItem to ' ';
    if (currentLinkIndex === -1) {
      setTOCWaypoint('');
    } else if (
      tableOfContents == null ||
      tableOfContents[currentLinkIndex] == null
    ) {
      return;
    } else {
      setTOCWaypoint(tableOfContents[currentLinkIndex].id);
    }
  }, [currentLinkIndex, dispatch, setTOCWaypoint, tableOfContents]);

  // not window.addEventListner because the page is inside a div with ref of scrollContainer.
  // use anonymous callback function in order to pass most recent TOC to scroll function.
  useEffect(() => {
    if (props.scrollContainer == null) {
      return;
    }
    // when user switches to a new section, no currentActiveItem chosen.
    setCurrentLinkIndex(-1);
    const scrollHandler = () => {
      onScroll(tableOfContents);
    };
    props.scrollContainer.addEventListener('scroll', scrollHandler);
    return () =>
      props.scrollContainer.removeEventListener('scroll', scrollHandler);
  }, [onScroll, props.scrollContainer, tableOfContents]);

  // this component doesn't render anything, its here simply to set current Active Item upon scroll
  return <></>;
};

CurrentActiveItem.displayName = 'CurrentActiveItem';

export default CurrentActiveItem;
