import { ErrorReference } from '../../types';

export const BANK_TRANSFER_LIMIT_EXCEEDED: ErrorReference = {
  server: {
    error_type: 'BANK_TRANSFER_ERROR',
    error_code: 'BANK_TRANSFER_LIMIT_EXCEEDED',
    error_message: 'bank transfer daily transfer limit exceeded',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const BANK_TRANSFER_INVALID_ORIGINATION_ACCOUNT: ErrorReference = {
  server: {
    error_type: 'BANK_TRANSFER_ERROR',
    error_code: 'BANK_TRANSFER_INVALID_ORIGINATION_ACCOUNT',
    error_message: 'origination_account_id is invalid',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const BANK_TRANSFER_MISSING_ORIGINATION_ACCOUNT: ErrorReference = {
  server: {
    error_type: 'BANK_TRANSFER_ERROR',
    error_code: 'BANK_TRANSFER_MISSING_ORIGINATION_ACCOUNT',
    error_message:
      'origination_account_id is required if multiple origination accounts are available',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const BANK_TRANSFER_ACCOUNT_BLOCKED: ErrorReference = {
  server: {
    error_type: 'BANK_TRANSFER_ERROR',
    error_code: 'BANK_TRANSFER_ACCOUNT_BLOCKED',
    error_message:
      'bank transfer was blocked due to a previous ACH return on this account',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const BANK_TRANSFER_INSUFFICIENT_FUNDS: ErrorReference = {
  server: {
    error_type: 'BANK_TRANSFER_ERROR',
    error_code: 'BANK_TRANSFER_INSUFFICIENT_FUNDS',
    error_message: 'bank transfer balance is insufficient to make this request',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const BANK_TRANSFER_NOT_CANCELLABLE: ErrorReference = {
  server: {
    error_type: 'BANK_TRANSFER_ERROR',
    error_code: 'BANK_TRANSFER_NOT_CANCELLABLE',
    error_message: 'bank transfer is not cancellable',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const BANK_TRANSFER_UNSUPPORTED_ACCOUNT_TYPE: ErrorReference = {
  server: {
    error_type: 'BANK_TRANSFER_ERROR',
    error_code: 'BANK_TRANSFER_UNSUPPORTED_ACCOUNT_TYPE',
    error_message: 'bank transfer account type not supported',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const BANK_TRANSFER_UNSUPPORTED_ENVIRONMENT: ErrorReference = {
  server: {
    error_type: 'BANK_TRANSFER_ERROR',
    error_code: 'BANK_TRANSFER_UNSUPPORTED_ENVIRONMENT',
    error_message: 'bank transfers is not supported on this environment',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const BANK_TRANSFER_FORBIDDEN_ACH_CLASS: ErrorReference = {
  server: {
    error_type: 'BANK_TRANSFER_ERROR',
    error_code: 'BANK_TRANSFER_FORBIDDEN_ACH_CLASS',
    error_message: 'specified ach_class is forbidden',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
