import React, { useState } from 'react';
import { SchemaObject } from 'openapi3-ts';
import cx from 'classnames';
import mergeAllOf from '@stoplight/json-schema-merge-allof';

import styles from './SchemaRow.module.scss';
import AdditionalProperties from './AdditionalProperties';
import { createId } from '../utilities';

type SchemaObjectEntry = [
  string,
  SchemaObject['properties'] | { 'x-hidden-from-docs'?: boolean },
];

interface Props {
  schemaName: string;
  schemaEntry: SchemaObjectEntry;
  required?: boolean;
  deprecated?: boolean;
  type?: string;
  parentKeys: [] | string[];
  route?: string;
  exchangeSpec?: string;
  coreExchangeSpec?: string;
}

const isExpandable = (schemaItem: SchemaObject): boolean => {
  // this is an object, thereforce allow expanding
  if (schemaItem.properties != null) {
    return true;
  }

  // this is an array of objects, not an array of strings, so allow
  const schemaItemSubItems: SchemaObject = schemaItem.items;
  if (schemaItemSubItems != null && schemaItemSubItems.type === 'object') {
    return true;
  }

  return false;
};

const shouldRenderSchemaItemArrayMembers = (
  schemaItem: SchemaObject,
): boolean => {
  return schemaItem.type === 'array' && schemaItem.items != null;
};

const ChatbotFriendlyRow: React.FC<Props> = (props) => {
  const key: string = props.schemaEntry[0];
  const fullkey: string =
    props.parentKeys.join('.') + (props.parentKeys.length > 0 ? '.' : '') + key;
  const id = createId(props.schemaName, key, props.parentKeys);
  let schemaItem: SchemaObject = props.schemaEntry[1];
  let schemaItemSubItems: SchemaObject = schemaItem.items;

  const descriptionOfObject =
    props.type === 'model'
      ? `${props.schemaName} webhook`
      : `/${props.route} ${props.type}`;

  // handle polymorphic schema items
  // https://swagger.io/docs/specification/data-models/inheritance-and-polymorphism/
  if (schemaItem.allOf != null || schemaItemSubItems?.allOf != null) {
    schemaItem = mergeAllOf(schemaItem, {
      resolvers: {
        defaultResolver: mergeAllOf.options.resolvers.title,
      },
    });
    schemaItemSubItems = schemaItem.items;
  }

  let thisType =
    schemaItemSubItems != null && schemaItemSubItems.type != null
      ? `[${schemaItemSubItems.type}]`
      : schemaItem.type;

  if (schemaItem != null && schemaItem['format'] != null) {
    thisType = thisType.concat(
      `. Specifically, a  ${schemaItem['format']}  ${schemaItem.type}`,
    );
  }

  const uncapitalizeFirstLetter = (str: string) => {
    if (str == null || str.length === 0) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  // if (schemaItem.items == null) {
  //   console.log(props.schemaName + JSON.stringify(props.schemaEntry));
  // }

  return (
    <div className={cx(styles.schemaRow, styles.noSeparator)}>
      <div className={cx(styles.contentRow)} data-algolia='schemaRowTd'>
        <div>=======</div>

        <header className={cx(styles.header)}>
          <div>
            In the {descriptionOfObject}, the the `{fullkey}` field is a{' '}
            {thisType}.{' '}
            {props.required &&
              (props.type === 'request' ||
                props.exchangeSpec != null ||
                props.coreExchangeSpec != null) && (
                <span>It is required. </span>
              )}
            {schemaItem.deprecated && <span>It is deprecated.</span>}
            {schemaItem.nullable &&
              (props.type === 'response' || props.type === 'link') && (
                <span>It can be null. </span>
              )}
            It represents {uncapitalizeFirstLetter(schemaItem.description)}
          </div>
        </header>
        <div className={cx(styles.attributes)}>
          {schemaItem.description && (
            <div
              className={styles.description}
              data-algolia='schemaRowDescription'
            >
              <AdditionalProperties schemaItem={schemaItem} simplify={true} />
            </div>
          )}
        </div>
        {isExpandable(schemaItem) && key !== 'core_attributes' && (
          <div className={styles.nestedTable}>
            <div>
              {Object.entries(
                shouldRenderSchemaItemArrayMembers(schemaItem)
                  ? schemaItemSubItems.properties
                  : schemaItem.properties,
              )
                .filter((entry: SchemaObjectEntry) => {
                  return !(
                    entry[1].hasOwnProperty('x-hidden-from-docs') &&
                    entry[1]['x-hidden-from-docs'] === true
                  );
                })
                .map((entry: SchemaObjectEntry, idx: number) => (
                  <>
                    <ChatbotFriendlyRow
                      type={props.type}
                      schemaName={props.schemaName}
                      schemaEntry={entry}
                      required={
                        schemaItem != null &&
                        (schemaItemSubItems?.required != null ||
                          schemaItem.required != null) &&
                        props.type === 'request' &&
                        (schemaItemSubItems?.required?.includes(entry[0]) ||
                          schemaItem.required?.includes(entry[0]))
                      }
                      route={props.route}
                      key={idx}
                      parentKeys={[
                        ...props.parentKeys,
                        props.schemaEntry[0].split('_').join('-'),
                      ]}
                    />
                  </>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ChatbotFriendlyRow.displayName = 'ChatbotFriendlyRow';

export default ChatbotFriendlyRow;
