import Users from 'plaid-threads/Icons/Users';
import Webhooks from 'plaid-threads/Icons/Webhooks';
import BookOpen from 'plaid-threads/Icons/BookOpen';
import Changelog from 'plaid-threads/Icons/Changelog';
import Learn from 'plaid-threads/Icons/Learn';
// import Document from 'plaid-threads/Icons/Document'; // to be used when we add back Additional resources
import Help from 'plaid-threads/Icons/Help';

import { NavItem, NavGroup } from '../../docs/constants/routeMap';

export const WELCOME_21: NavGroup = [
  {
    children: [
      { title: 'Timeline', path: '/', icon: Changelog, highlight: true },
      {
        title: 'Program overview',
        path: '/program',
        icon: BookOpen,
        highlight: true,
        subNav: false, // test
      },
      {
        title: 'Meet your class',
        path: '/class',
        icon: Users,
        highlight: true,
        subNav: false,
      },
    ],
  },
];

export const PROGRAM_21: NavGroup = [
  {
    children: [
      {
        title: 'Bootcamp workshops',
        path: '/workshops',
        icon: Learn,
        highlight: true,
        subNav: false,
      },
      {
        title: 'Network support',
        path: '/network-support',
        icon: Webhooks,
        highlight: true,
        subNav: false,
      },
      {
        title: 'Ongoing support',
        path: '/ongoing-support',
        icon: Help,
        highlight: true,
        subNav: false,
      },
      // {
      //   title: 'Additional resources',
      //   path: '/resources',
      //   icon: Document,
      //   highlight: true,
      //   subNav: false,
      // },
    ],
  },
];

// everything
export const ROUTE_MAP: NavGroup = [...WELCOME_21, ...PROGRAM_21];

const flattenNavGroup = (group: NavGroup): Array<NavItem> => {
  return group.reduce((acc, item) => {
    const res: Array<NavItem> = [];
    // if this item has properties,
    // push item to results
    if (item.path != null && item.title != null) {
      res.push(item);
    }
    // process children if they exist
    if (item.children != null) {
      res.push(...flattenNavGroup(item.children));
    }
    return acc.concat(res);
  }, []);
};

export const ROUTE_MAP_FLAT = flattenNavGroup(ROUTE_MAP);
