import { ErrorReference } from '../../types';

export const OAUTH_STATE_ID_ALREADY_PROCESSED: ErrorReference = {
  server: {
    error_type: 'OAUTH_ERROR',
    error_code: 'OAUTH_STATE_ID_ALREADY_PROCESSED',
    error_message: null,
    display_message: null,
    http_code: 208,
  },
  link: null,
};

export const INCORRECT_OAUTH_NONCE: ErrorReference = {
  server: {
    error_type: 'OAUTH_ERROR',
    error_code: 'INCORRECT_OAUTH_NONCE',
    error_message: 'nonce does not match',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const MISSING_OAUTH_STATE_ID: ErrorReference = {
  server: {
    error_type: 'OAUTH_ERROR',
    error_code: 'MISSING_OAUTH_STATE_ID',
    error_message: 'oauth_state_id missing',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const OAUTH_FLOW_ALREADY_COMPLETED: ErrorReference = {
  server: {
    error_type: 'OAUTH_ERROR',
    error_code: 'OAUTH_FLOW_ALREADY_COMPLETED',
    error_message: 'oauth flow has already been completed for oauth_state_id',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const OAUTH_STATE_ID_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'OAUTH_ERROR',
    error_code: 'OAUTH_STATE_ID_NOT_FOUND',
    error_message: "the provided oauth_state_id wasn't found",
    display_message: null,
    http_code: 404,
  },
  link: null,
};

export const INCORRECT_LINK_TOKEN: ErrorReference = {
  server: {
    error_type: 'OAUTH_ERROR',
    error_code: 'INCORRECT_LINK_TOKEN',
    error_message: 'link token does not match original link token',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
