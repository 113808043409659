import React from 'react';

import styles from './Container.module.scss';

interface Props {
  children?: React.ReactNode | Array<React.ReactNode>;
}

const Container: React.FC<Props> = (props) => (
  <table className={styles.table}>
    <tbody>{props.children}</tbody>
  </table>
);

Container.displayName = 'Container';

export default Container;
