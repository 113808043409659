import React from 'react';

interface Props {
  children: React.ReactNode | Array<React.ReactNode>;
}

const Content: React.FC<Props> = (props) => {
  return <div>{props.children}</div>;
};

Content.displayName = 'Content';

export default Content;
