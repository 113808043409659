import Button from 'plaid-threads/Button';
import Image from 'src/components/docs/Image';
import IconButton from 'plaid-threads/IconButton';
import MenuS1 from 'plaid-threads/Icons/MenuS1';
import React, { useContext, useState } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PrimaryLink from 'plaid-threads/PrimaryLink';

import Context from '../../../contexts/docs';
import Search from '../Search';
import Sidebar from '../Sidebar';
import { DOCS_BASE_URL } from '../constants';

import styles from './index.module.scss';
import APIVersionSwitcher from './APIVersionSwitcher';
import classNames from 'classnames';
import LoggedInWrapper from '../LoggedInWrapper';
import Switch from 'plaid-threads/Icons/Switch';
import { BasicInfoTeam } from '../types';
import DropModal from '../DropModal';
import Key from 'plaid-threads/Icons/Key';
import Briefcase from 'plaid-threads/Icons/Briefcase';
import Home from 'plaid-threads/Icons/Home';
import { useTrack } from 'src/contexts';
import { TrackFunction } from 'src/contexts/AnalyticsContext/AnalyticsContextTypes';
import { LOGGED_IN_DOCS } from 'src/lib/trackingEvents';

interface Props {
  setShowSidebar: (show: boolean) => void;
  track: TrackFunction;
}

const loggedOutRightColumn = (props: Props) => {
  return (
    <div className={classNames([styles.rightColumn, styles.extraPadding])}>
      <div className={styles.plaidDotCom}>
        <PrimaryLink className={styles.link} href='/'>
          Plaid.com
        </PrimaryLink>
      </div>
      <div className={styles.buttonWrapperLogIn}>
        <Button
          href='https://dashboard.plaid.com/signin?redirect=docs'
          inline
          size={48}
          centered
          className={cx('signup-attach-utm', styles.loginButton)}
          secondary
          icon={null}
          onClick={() =>
            props.track({
              type: LOGGED_IN_DOCS.USER_CLICKED_LOGGED_IN_DOCS_SIGN_IN,
            })
          }
        >
          Log in
        </Button>
      </div>

      <div className={styles.buttonWrapperAPIKeys}>
        <Button
          href='https://dashboard.plaid.com/signup'
          inline
          centered
          icon={null}
          className={cx('signup-attach-utm', styles.APIButton)}
        >
          Get API Keys
        </Button>
      </div>

      <IconButton
        className={styles.sidebarToggle}
        accessibilityLabel='Open nav'
        icon={<MenuS1 />}
        onClick={() => {
          props.setShowSidebar(true);
        }}
      />
    </div>
  );
};

interface LoggedInProps {
  setShowSidebar: (show: boolean) => void;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  team: BasicInfoTeam;
  email: string;
  track: TrackFunction;
}

const loggedInRightColumn = (props: LoggedInProps) => {
  return (
    <div className={classNames([styles.rightColumn, styles.extraPadding])}>
      <div className={styles.plaidDotCom}>
        <PrimaryLink className={styles.link} href='/'>
          Plaid.com
        </PrimaryLink>
      </div>

      <div
        data-testid='team-switcher'
        className={styles.navItemWrapper}
        onClick={() => props.setIsOpen(!props.isOpen)}
      >
        <div className={styles.team}>
          <div className={classNames(styles.wrapper)}>
            <div className={classNames(styles.badge)}>
              {props.team && props.team.company.slice(0, 1).toUpperCase()}
            </div>
            <div className={styles.name}>
              {props.team && props.team.company}
            </div>
          </div>
        </div>
        <div className={styles.navArrows}>
          <Switch />
        </div>
      </div>
      <div className={styles.dropModalWrapper}>
        <DropModal
          side={'center'}
          isOpen={props.isOpen}
          onClickOutside={() => props.setIsOpen(false)}
          className={styles.dropModal}
        >
          <span className={styles.header}>{props.email}</span>
          <a
            href='https://dashboard.plaid.com/developers/keys'
            target='_blank'
            onClick={() =>
              props.track({
                type: LOGGED_IN_DOCS.USER_CLICKED_ON_MENU,
                payload: { link: 'API_KEYS' },
              })
            }
          >
            <div className={styles.row}>
              <Key className={styles.icon} />
              <span className={styles.text}>API Keys</span>
            </div>
          </a>
          <div className={styles.divider}></div>
          <a
            href='https://dashboard.plaid.com/personal/teams'
            target='_blank'
            onClick={() =>
              props.track({
                type: LOGGED_IN_DOCS.USER_CLICKED_ON_MENU,
                payload: { link: 'TEAMS' },
              })
            }
          >
            <div className={styles.row}>
              <Briefcase className={styles.icon} />
              <span className={styles.text}>Teams</span>
            </div>
          </a>
          <div className={styles.divider}></div>
          <a
            href='https://dashboard.plaid.com'
            target='_blank'
            onClick={() =>
              props.track({
                type: LOGGED_IN_DOCS.USER_CLICKED_ON_MENU,
                payload: { link: 'DASHBOARD_HOME' },
              })
            }
          >
            <div className={styles.row}>
              <Home className={styles.icon} />
              <span className={styles.text}>Dashboard Home</span>
            </div>
          </a>
          <div className={styles.divider}></div>
        </DropModal>
      </div>
    </div>
  );
};

const Navbar: React.FC = () => {
  // use Docs context
  const { metadata, user } = useContext(Context);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const track = useTrack();
  if (router.events) {
    // router.events can be undefined on initial load
    router.events.on('routeChangeStart', () => setShowSidebar(false));
  }

  // if nav is turned off on this page dont render
  if (!metadata || (metadata && metadata.nav === false)) {
    return null;
  }

  return (
    <>
      <nav className={cx(styles.navbar, styles.mainNav)} aria-label='Secondary'>
        <div className={styles.leftColumn}>
          <div className={styles.homeLink}>
            <Link href={DOCS_BASE_URL} passHref>
              <a
                href={DOCS_BASE_URL}
                rel='noopener noreferrer'
                className={styles.logo}
              >
                <Image
                  className={styles.logoImage}
                  src={'/assets/img/navbar/logo.svg'}
                  alt='Plaid logo'
                  width='80'
                  height='30.47'
                  noMarginBottom
                />
                <span className={styles.logoText}>Docs</span>
              </a>
            </Link>
          </div>
          <div className={styles.search}>
            <Search />
          </div>
          <div className={styles.apiVersion}>
            <APIVersionSwitcher />
          </div>
        </div>

        <LoggedInWrapper
          loggedInComponent={loggedInRightColumn({
            setShowSidebar,
            isOpen,
            setIsOpen,
            team: user?.team,
            email: user?.email,
            track,
          })}
          loggedOutComponent={loggedOutRightColumn({ setShowSidebar, track })}
        />
      </nav>
      <Sidebar
        mobile
        closed={!showSidebar}
        onClose={() => setShowSidebar(false)}
        className={styles.sidebar}
      />
      {showSidebar && (
        <div
          className={styles.sidebarBackdrop}
          role='button'
          tabIndex={-1}
          onClick={() => setShowSidebar(false)}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              // escape
              setShowSidebar(false);
            }
          }}
        ></div>
      )}
    </>
  );
};

export default Navbar;
