import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import globalStyles from '../DocsBeta/index.module.scss';
import CloseS1 from 'plaid-threads/Icons/CloseS1';
import IconButton from 'src/components/Inputs/IconButton/IconButton';
import Image from 'src/components/Image';

import Context from '../../../contexts/docs';

import styles from './SearchParent.module.scss';
import SearchDialog from './SearchDialog';
import ChatDialog from './ChatDialog';
import Note from 'plaid-threads/Note';
import { CHATBOT_EVENTS } from 'src/lib/trackingEvents';
import { useTrack } from 'src/contexts/AnalyticsContext';

enum SearchType {
  SEARCH = 'search',
  CHAT = 'chat',
}

const SearchParent = ({ showFinn = false }) => {
  const { showSearch, dispatch } = useContext(Context);
  const [searchType, setSearchType] = useState(SearchType.SEARCH);
  const track = useTrack();

  // If the url contains showChat=true, then pop up Finn by default

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('showChat') === 'true') {
      dispatch({
        type: 'SET_SEARCH_VISIBILITY',
        payload: {
          visibility: true,
          openSearchClicked: true,
        },
      });

      // Update the state value
      setSearchType(SearchType.CHAT);
    }
  }, []);

  const trackTabSelected = () => {
    const payload = { tab: searchType };
    track({ type: CHATBOT_EVENTS.CHAT_SEARCH_TAB_SELECTED, payload: payload });
  };

  useEffect(() => {
    if (showSearch) {
      if (searchType === SearchType.SEARCH) {
        const el = document.querySelector('#search-input');
        if (el instanceof HTMLInputElement) {
          el.focus();
        }
      } else if (searchType === SearchType.CHAT) {
        const el = document.querySelector('#finn-input-div input');
        if (el instanceof HTMLInputElement) {
          el.focus();
        }
      }
      trackTabSelected();
    }
  }, [searchType, showSearch]);

  const handleClick = () =>
    dispatch({
      type: 'SET_SEARCH_VISIBILITY',
      payload: { visibility: false, openSearchClicked: false },
    });

  return (
    <>
      <div className={!showSearch ? styles.hidden : ''}>
        <div
          className={cx(styles.backdrop, showSearch ? styles.show : '')}
          onClick={handleClick}
          onKeyUp={handleClick}
          role='button'
          tabIndex={0}
        />

        <div className={cx(globalStyles.docs, styles.parentModalContainer)}>
          <div className={styles.offsetClose}>
            <IconButton
              accessibilityLabel='Close search modal'
              icon={<CloseS1 className={styles.inputIcon} />}
              onClick={() =>
                dispatch({
                  type: 'SET_SEARCH_VISIBILITY',
                  payload: {
                    visibility: false,
                    openSearchClicked: false,
                  },
                })
              }
            />
          </div>
          {showFinn && (
            <div className={styles.topBar}>
              <span
                className={cx(
                  styles.barLabel,
                  searchType === SearchType.SEARCH && styles.selected,
                )}
              >
                <button
                  className={styles.barButton}
                  onClick={(e) => setSearchType(SearchType.SEARCH)}
                >
                  Search the docs
                </button>
              </span>
              <span
                className={cx(
                  styles.barLabel,
                  searchType === SearchType.CHAT && styles.selected,
                )}
              >
                <button
                  className={styles.barButton}
                  onClick={(e) => setSearchType(SearchType.CHAT)}
                >
                  Ask Bill!
                </button>
                <Image
                  src='/assets/img/docs/bill-happy.png'
                  alt='Ask Bill!'
                  isLazy={false}
                  className={styles.billIcon}
                  width={25}
                  height={25}
                />
              </span>
            </div>
          )}
          <SearchDialog
            isVisible={showSearch && searchType === SearchType.SEARCH}
          />
          {showFinn && (
            <ChatDialog
              isVisible={showSearch && searchType === SearchType.CHAT}
            />
          )}
        </div>
      </div>
    </>
  );
};

SearchParent.displayName = 'SearchParent';
export default SearchParent;
