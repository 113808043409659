import React from 'react';
import ActionCard from 'plaid-threads/ActionCard';

import { isExternalUrl, withTrailingSlash } from '../utilities';
import LinkWrapper from '../LinkWrapper';
import styles from './Card.module.scss';

interface Props {
  title: string;
  subtitle?: string;
  link: string;
  linkText: string;
  icon: React.ReactNode;
}

const Card: React.FC<Props> = (props: Props) => {
  return isExternalUrl(props.link) ? (
    <ActionCard
      className={styles.card}
      title={props.title}
      subtitle={props.subtitle}
      href={withTrailingSlash(props.link)}
      ctaText={props.linkText}
      icon={props.icon}
    ></ActionCard>
  ) : (
    <ActionCard
      className={styles.card}
      title={props.title}
      subtitle={props.subtitle}
      href={withTrailingSlash(props.link)}
      ctaText={props.linkText}
      icon={props.icon}
      component={LinkWrapper}
      componentProps={{ href: props.link }}
    ></ActionCard>
  );
};

Card.displayName = 'Card';

export default Card;
