import React from 'react';

import { APIEndpointType, APIProductName } from '../../types';
import { withBasePath } from '../../utilities';
import Table from '..';

interface Props {
  name: string; // an endpoint
  type: APIEndpointType; // used to create full path
  url?: string; // use explicit url if name is not an endpoint
  product?: APIProductName; // If our type is product, this lists the actual product.
}

const EndPoint: React.FC<Props> = (props) => {
  const path =
    props.url != null ? props.url : `#${props.name.split('/').join('')}`;
  const page =
    props.type === 'products' && props.product != null
      ? `products/${props.product}`
      : props.type;
  const fullPath = withBasePath(`/api/${page}/${path}`);

  return <Table.ContentItem name={props.name} href={fullPath} />;
};

EndPoint.displayName = 'EndPoint';

export default EndPoint;
