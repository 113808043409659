import { ErrorReference } from '../../types';

export const INSTITUTION_DOWN: ErrorReference = {
  server: {
    error_type: 'INSTITUTION_ERROR',
    error_code: 'INSTITUTION_DOWN',
    error_message:
      'this institution is not currently responding to this request. please try again soon',
    display_message:
      'This financial institution is not currently responding to requests. We apologize for the inconvenience.',
    http_code: 400,
  },
  link: {
    link_error_button: 'Restart',
    link_error_heading: 'Please try connecting a different account',
    link_error_icon: 'close',
    link_error_message:
      'There was a problem processing your request. Your account could not be connected at this time',
    error_code: 'INSTITUTION_DOWN',
  },
};

export const INSTITUTION_NOT_RESPONDING: ErrorReference = {
  server: {
    error_type: 'INSTITUTION_ERROR',
    error_code: 'INSTITUTION_NOT_RESPONDING',
    error_message:
      'this institution is not currently responding to this request. please try again soon',
    display_message:
      'This financial institution is not currently responding to requests. We apologize for the inconvenience.',
    http_code: 400,
  },
  link: {
    link_error_button: 'Restart',
    link_error_heading: "Couldn't connect to your institution",
    link_error_icon: 'close',
    link_error_message:
      'If you need to use this app immediately, we recommend trying another institution. Errors like this are usually resolved quickly, so you may want to try the same account again later.',
    error_code: 'INSTITUTION_NOT_RESPONDING',
  },
};

export const INSTITUTION_NOT_AVAILABLE: ErrorReference = {
  server: {
    error_type: 'INSTITUTION_ERROR',
    error_code: 'INSTITUTION_NOT_AVAILABLE',
    error_message:
      'this institution is not currently responding to this request. please try again soon',
    display_message:
      'This financial institution is not currently responding to requests. We apologize for the inconvenience.',
    http_code: 400,
  },
  link: null,
};
export const INSTITUTION_NO_LONGER_SUPPORTED: ErrorReference = {
  server: {
    error_type: 'INSTITUTION_ERROR',
    error_code: 'INSTITUTION_NO_LONGER_SUPPORTED',
    error_message: 'this institution is no longer supported',
    display_message:
      'This financial institution is no longer supported. We apologize for the inconvenience.',
    http_code: 400,
  },
  link: {
    link_error_button: 'Restart',
    link_error_heading: 'Please try connecting a different account',
    link_error_icon: 'close',
    link_error_message:
      'There was a problem processing your request. Your account could not be connected at this time.',
    error_code: 'INSTITUTION_NO_LONGER_SUPPORTED',
  },
};

export const INSTITUTION_NOT_ENABLED_IN_ENVIRONMENT: ErrorReference = {
  server: {
    error_type: 'INSTITUTION_ERROR',
    error_code: 'INSTITUTION_NOT_ENABLED_IN_ENVIRONMENT',
    error_message: 'Institution not enabled in this environment',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INSTITUTION_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'INSTITUTION_ERROR',
    error_code: 'INSTITUTION_NOT_FOUND',
    error_message:
      'this institution was not found. Please check the ID supplied',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const UNAUTHORIZED_INSTITUTION: ErrorReference = {
  server: {
    error_type: 'INSTITUTION_ERROR',
    error_code: 'UNAUTHORIZED_INSTITUTION',
    error_message: 'not authorized to create items for this institution',
    display_message:
      'You are not authorized to create items for this institution at this time.',
    http_code: 400,
  },
  link: null,
};

export const INSTITUTION_REGISTRATION_REQUIRED: ErrorReference = {
  server: {
    error_type: 'INSTITUTION_ERROR',
    error_code: 'INSTITUTION_REGISTRATION_REQUIRED',
    error_message: 'not yet registered to create items for this institution',
    display_message:
      'You must register your application with this institution before you can create items for it.',
    http_code: 400,
  },
  link: null,
};

export const UNSUPPORTED_RESPONSE: ErrorReference = {
  server: {
    error_type: 'INSTITUTION_ERROR',
    error_code: 'UNSUPPORTED_RESPONSE',
    error_message:
      'the data returned from the financial institution is not valid',
    display_message:
      'The data returned from the financial institution is not valid.',
    http_code: 400,
  },
  link: null,
};
