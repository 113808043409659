import React from 'react';
import { OpenAPIObject } from 'openapi3-ts';

import link from './link.yml';

const apiLinkSchema = (link as unknown) as OpenAPIObject;

const OpenAPILinkContext = React.createContext<OpenAPIObject>(apiLinkSchema);

export const { Consumer, Provider } = OpenAPILinkContext;

export const LinkOpenAPIProvider = (props) => {
  return <Provider value={apiLinkSchema}>{props.children}</Provider>;
};

export default OpenAPILinkContext;
