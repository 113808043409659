
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';
import ContentSwitcher from '../../ContentSwitcher';

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ContentSwitcher.Container id='libraries' names={['Current libraries', 'Legacy libraries']}>
      <ContentSwitcher.Content>
        <MultiCodeBlock type='server' mdxType="MultiCodeBlock">
          <pre><code parentName="pre" {...{
              "className": "language-node",
              "metastring": "#server.js",
              "#server.js": true
            }}>{`app.post('/api/exchange_public_token', async function (
  request,
  response,
  next,
) {
  const publicToken = request.body.public_token;
  try {
    const response = await client.itemPublicTokenExchange({
      public_token: publicToken,
    });

    // These values should be saved to a persistent database and
    // associated with the currently signed-in user
    const accessToken = response.data.access_token;
    const itemID = response.data.item_id;

    res.json({ public_token_exchange: 'complete' });
  } catch (error) {
    // handle error
  }
});
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`curl -X POST https://sandbox.plaid.com/item/public_token/exchange \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "public_token": "public-sandbox-12345678-abcd-1234-abcd-1234567890ab"
}'
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-ruby",
              "metastring": "#server.rb",
              "#server.rb": true
            }}>{`access_token = nil

post '/api/exchange_public_token' do
  request = Plaid::ItemPublicTokenExchangeRequest.new(
    {
      public_token: params["public_token"]
    }
  )
  response = client.item_public_token_exchange(request)

  # These values should be saved to a persistent database and
  # associated with the currently signed-in user
  access_token = response.access_token
  item_id = response.item_id

  content_type :json
  {public_token_exchange: "complete"}.to_json
end
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-java",
              "metastring": "#Server.java",
              "#Server.java": true
            }}>{`import com.plaid.client.model.ItemPublicTokenExchangeRequest;
import com.plaid.client.model.ItemPublicTokenExchangeResponse;

public class PlaidExample {

  ...
  static class GetAccessToken implements HttpHandler {
    private static PlaidClient plaidClient;

    private String publicToken;
    private String accessToken;
    private String itemId;

    public void handle(HttpExchange t) throws IOException {
      // Simplified psuedo-code for obtaining public_token
      InputStream is = t.getRequestBody();
      publicToken = is.publicToken();

      // Create your Plaid client
      HashMap<String, String> apiKeys = new HashMap<String, String>();
      apiKeys.put("clientId", CLIENT_ID);
      apiKeys.put("secret", SECRET);
      apiKeys.put("plaidVersion", "2020-09-14");
      apiClient = new ApiClient(apiKeys);
      apiClient.setPlaidAdapter(ApiClient.Sandbox);

      plaidClient = apiClient.createService(PlaidApi.class);

      // Exchange public_token for an access_token
      ItemPublicTokenExchangeRequest request = new ItemPublicTokenExchangeRequest()
        .publicToken(publicToken);

      Response<ItemPublicTokenExchangeResponse> response = plaidClient
        .itemPublicTokenExchange(request)
        .execute();

      // These values should be saved to a persistent database and
      // associated with the currently signed-in user
      accessToken = response.body().getAccessToken();
      itemId      = response.body().getItemId();

      String message = "{\\"public_token_exchange\\": \\"complete\\"}";
      return Response
        .status(Response.Status.OK)
        .entity(message)
        .type(MediaType.APPLICATION_JSON)
      }
  }
}
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-python",
              "metastring": "#server.py",
              "#server.py": true
            }}>{`access_token = None
item_id = None

@app.route('/exchange_public_token', methods=['POST'])
def exchange_public_token():
    global access_token
    public_token = request.form['public_token']
    request = ItemPublicTokenExchangeRequest(
      public_token=public_token
    )
    response = client.item_public_token_exchange(request)

    # These values should be saved to a persistent database and
    # associated with the currently signed-in user
    access_token = response['access_token']
    item_id = response['item_id']

    return jsonify({'public_token_exchange': 'complete'})
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-go",
              "metastring": "#server.go",
              "#server.go": true
            }}>{`func getAccessToken(c *gin.Context) {
  ctx := context.Background()
  publicToken := c.PostForm("public_token")

  // exchange the public_token for an access_token
  exchangePublicTokenReq := plaid.NewItemPublicTokenExchangeRequest(sandboxPublicTokenResp.GetPublicToken())
    exchangePublicTokenResp, _, err := client.PlaidApi.ItemPublicTokenExchange(ctx).ItemPublicTokenExchangeRequest(
        *exchangePublicTokenReq,
    ).Execute()

  // These values should be saved to a persistent database and
  // associated with the currently signed-in user
  accessToken := exchangePublicTokenResp.GetAccessToken()
  itemID := exchangePublicTokenResp.GetItemId()

  c.JSON(http.StatusOK, gin.H{"public_token_exchange": "complete"})
}


`}</code></pre>
        </MultiCodeBlock>
  </ContentSwitcher.Content>
      <ContentSwitcher.Content>
        <MultiCodeBlock type='server' mdxType="MultiCodeBlock">
          <pre><code parentName="pre" {...{
              "className": "language-node",
              "metastring": "#server.js",
              "#server.js": true
            }}>{`app.post('/exchange_public_token', async (request, response) => {
  try {
    const publicToken = request.body.public_token;
    // Exchange the client-side public_token for a server access_token
    const tokenResponse = await client.exchangePublicToken(publicToken);

    // These values should be saved to a persistent database and
    // associated with the currently signed-in user
    const accessToken = response.data.access_token;
    const itemID = response.data.item_id;

    res.json({ public_token_exchange: 'complete' });
  } catch (e) {
    // Display error on client
    return response.send({ error: e.message });
  }
});
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`## no curl
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-ruby",
              "metastring": "server.rb",
              "server.rb": true
            }}>{`access_token = nil

post '/exchange_public_token' do
  exchange_token_response =
client.item.public_token.exchange(params['public_token'])

  # These values should be saved to a persistent database and
  # associated with the currently signed-in user
  access_token = exchange_token_response['access_token']
  item_id = exchange_token_response['item_id']

  content_type :json
  {public_token_exchange: "complete"}.to_json
end
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-java",
              "metastring": "#Server.java",
              "#Server.java": true
            }}>{`import com.plaid.client.request.ItemPublicTokenExchangeRequest;
import com.plaid.client.response.ItemPublicTokenExchangeResponse;


public class PlaidExample {

  ...

  static class GetAccessToken implements HttpHandler {
    private static PlaidClient plaidClient;

    private String publicToken;
    private String accessToken;
    private String itemId;

    public void handle(HttpExchange t) throws IOException {
      // Simplified psuedo-code for obtaining public_token
      InputStream is = t.getRequestBody();
      publicToken = is.publicToken();

      // Build your Plaid client
      PlaidClient plaidClient = PlaidClient.newBuilder()
        .clientIdAndSecret(CLIENT_ID, SECRET)
        .sandboxBaseUrl() // sandbox Plaid environment
        .logLevel(HttpLoggingInterceptor.Level.BODY)
        .build();

      // Exchange public_token for an access_token
      Response plaidResponse =
        plaidClient()
          .service()
          .itemPublicTokenExchange(
            new ItemPublicTokenExchangeRequest(publicToken))
          .execute();

      // These values should be saved to a persistent database
      // and associated with the currently signed-in user
      accessToken = plaidResponse.body().getAccessToken();
      itemId      = plaidResponse.body().getItemId();

      String message = "{\\"public_token_exchange\\": \\"complete\\"}";
      return Response
        .status(Response.Status.OK)
        .entity(message)
        .type(MediaType.APPLICATION_JSON)
      }

    }
  }
}
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-python",
              "metastring": "#server.py",
              "#server.py": true
            }}>{`access_token = None
public_token = None

@app.route("/exchange_public_token", methods=['POST'])
def exchange_public_token():
    global access_token
    public_token = request.form['public_token']
    exchange_response = \\
        client.Item.public_token.exchange(public_token)

    # These values should be saved to a persistent database and
    # associated with the currently signed-in user
    access_token = exchange_response['access_token']
    item_id = exchange_response['item_id']

    return jsonify({'public_token_exchange': 'complete'})

`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-go",
              "metastring": "#server.go",
              "#server.go": true
            }}>{`func getAccessToken(c *gin.Context) {
  publicToken := c.PostForm("public_token")
  response, err := client.ExchangePublicToken(publicToken)
  if err != nil {
    renderError(c, err)
    return
  }

  // These values should be saved to a persistent database and
  //  associated with the currently signed-in user
  accessToken = response.AccessToken
  itemID = response.ItemID

  c.JSON(http.StatusOK, gin.H{"public_token_exchange": "complete"})
}

`}</code></pre>
        </MultiCodeBlock>
      </ContentSwitcher.Content>
    </ContentSwitcher.Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
