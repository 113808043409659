/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from 'next/link';

import { isExternalUrl } from '../utilities';

interface Props {
  className?: string;
  children: React.ReactNode;
  href: string;
}

const LinkWrapper: React.FC<Props> = ({ className, children, href }) =>
  isExternalUrl(href) ? (
    <a className={className} href={href} target='_blank' rel='noopener'>
      {children}
    </a>
  ) : (
    <Link href={href} passHref>
      <a className={className} href={href}>
        {children}
      </a>
    </Link>
  );

LinkWrapper.displayName = 'LinkWrapper';

export default LinkWrapper;
