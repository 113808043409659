import React from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';

import styles from 'src/components/docs/Layout/Page.module.scss';
import globalStyles from '../../../components/docs/DocsBeta/index.module.scss';

import Sidebar from '../Sidebar';
import { CoreExchangeOpenAPIProvider } from 'src/contexts/docs/openapi/core-exchange';
import Navbar from '../../exchange-docs/Navbar';
import CurrentActiveItem from 'src/components/docs/DocsBeta/CurrentActiveItem';
import { CORE_EXCHANGE_DOCS_BASE_URL } from '../constants';

const Page = (props) => {
  const scrollContainer = React.useRef(null);
  const { asPath } = useRouter();

  React.useEffect(() => {
    // if page is a deeplink, do nothing
    if (asPath.includes('#')) {
      return;
    }
    // if not, reset the scroll container so that
    // we dont end up somewhere down the page when the route changes
    if (scrollContainer?.current?.scrollTop != null) {
      scrollContainer.current.scrollTop = 0;
    }
  }, [asPath]);

  return (
    <CoreExchangeOpenAPIProvider>
      <div className={cx(globalStyles.docs, styles.layout)}>
        <Sidebar
          baseUrl={CORE_EXCHANGE_DOCS_BASE_URL}
          className={styles.sidebar}
          onClose={() => null}
          headerText='Core Exchange'
        />
        <div className={styles.navbarContainer}>
          <Navbar baseUrl={CORE_EXCHANGE_DOCS_BASE_URL} />
          <div
            className={cx(
              'docs-scroll-container',
              styles.contentScrollContainer,
            )}
            ref={scrollContainer}
          >
            <CurrentActiveItem scrollContainer={scrollContainer.current} />
            <main className={cx(styles.content)}>{props.children}</main>
          </div>
        </div>
      </div>
    </CoreExchangeOpenAPIProvider>
  );
};

Page.displayName = 'Page';
Page._type = 'Core-Exchange-Doc';

export default Page;
