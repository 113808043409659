import Grid from 'plaid-threads/Grid';
import Column from 'plaid-threads/Column';
import React from 'react';

import styles from './MediaCardGrid.module.scss';

interface Props {
  children: React.ReactNode;
}
const MediaCardGrid: React.FC<Props> = (props: Props) => (
  <Grid noPadding={true}>
    {React.Children.map(props.children, (child) => {
      return (
        <Column large={6} medium={6} small={12}>
          <div className={styles.paddingForShadowDiv}>{child}</div>
        </Column>
      );
    })}
  </Grid>
);

MediaCardGrid.displayName = 'MediaCard';

export default MediaCardGrid;
