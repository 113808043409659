
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';
import ContentSwitcher from '../../ContentSwitcher';

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ContentSwitcher.Container id='libraries' names={['Current libraries', 'Legacy libraries']}>
      <ContentSwitcher.Content>
        <MultiCodeBlock type='server' mdxType="MultiCodeBlock">
          <pre><code parentName="pre" {...{
              "className": "language-node",
              "metastring": "#server.js",
              "#server.js": true
            }}>{`// Using Express
app.post('/api/create_link_token', async function (request, response) {
  const configs = {
    user: {
      // This should correspond to a unique id for the current user.
      client_user_id: 'user-id',
    },
    client_name: 'Plaid Test App',
    products: [Products.PaymentInitiation],
    language: 'en',
    webhook: 'https://webhook.sample.com',
    country_codes: [CountryCode.Gb],
    payment_initiation: {
      payment_id: paymentID,
    },
  };
  try {
    const createTokenResponse = await client.linkTokenCreate(configs);
    response.json(createTokenResponse.data);
  } catch (error) {
    // handle error
  }
});
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`curl -X POST https://sandbox.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "Plaid Test App",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "products": ["payment_initiation"],
  "country_codes": ["GB"],
  "language": "en",
  "webhook": "https://webhook.sample.com",
  "payment_initiation": { "payment_id": "\${PAYMENT_ID}" },
}'
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-ruby"
            }}>{`require 'sinatra'
require 'plaid'

post '/api/create_link_token' do
  # Create a link_token for the given user
  link_token_create_request = Plaid::LinkTokenCreateRequest.new(
    {
      user: { client_user_id: 'user-id' },
      client_name: 'Plaid Test App',
      products: ['payment_initiation'],
      country_codes: ['GB'],
      language: "en",
      redirect_uri: nil_if_empty_envvar('PLAID_REDIRECT_URI'),
      webhook: 'https://webhook.sample.com',
      payment_initiation: { payment_id: payment_id}
    }
  )
  response = client.link_token_create(link_token_create_request)
  content_type :json
  response.to_json
end
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-java",
              "metastring": "#Server.java",
              "#Server.java": true
            }}>{`
import com.plaid.client.model.LinkTokenCreateRequest;
import com.plaid.client.model.LinkTokenCreateRequestUser;
import com.plaid.client.model.LinkTokenCreateResponse;


static class GetLinkToken implements HttpHandler {
  private static PlaidClient plaidClient;

  public void handle(HttpExchange t) throws IOException {
    // Create your Plaid client
    HashMap<String, String> apiKeys = new HashMap<String, String>();
    apiKeys.put("clientId", CLIENT_ID);
    apiKeys.put("secret", SECRET);
    apiKeys.put("plaidVersion", "2020-09-14");
    apiClient = new ApiClient(apiKeys);
    apiClient.setPlaidAdapter(ApiClient.Sandbox);

    plaidClient = apiClient.createService(PlaidApi.class);

    // Simplified psuedo-code for obtaining a user_id
    User userFromDB = db.find(...);
    String clientUserId = userFromDB.id;
    LinkTokenCreateRequestUser user = new LinkTokenCreateRequestUser()
      .clientUserId(clientUserId);

    // Create a link_token for the given user
    LinkTokenCreateRequestPaymentInitiation paymentInitiation = new LinkTokenCreateRequestPaymentInitiation()
    .paymentId(paymentId);

    LinkTokenCreateRequest request = new LinkTokenCreateRequest()
      .user(user)
      .clientName("Plaid Test App")
      .products(['payment_initiation']))
      .countryCodes(['GB']))
      .language("en")
      .redirectUri(redirectUri)
      .webhook("https://sample.webhook.com")
      .paymentInitiation(paymentInitiation);

    Response<LinkTokenCreateResponse> response = plaidClient
      .linkTokenCreate(request)
      .execute();

    // Send the data to the client
    return response.body();
  }
}

`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-python"
            }}>{`import plaid

# Using Flask
@app.route("/create_link_token", methods=['POST'])
def create_link_token(): # Get the client_user_id by searching for the current user
user = User.find(...)
client_user_id = user.id

    # Create a link_token for the given user
    request = LinkTokenCreateRequest(
            products=[Products('payment_initiation')],
            client_name="Plaid Test App",
            country_codes=[CountryCode('GB')],
            language='en',
            webhook='https://webhook.sample.com',
            payment_initiation=LinkTokenCreateRequestPaymentInitiation(
                payment_id=payment_id
            ),
            user=LinkTokenCreateRequestUser(
                client_user_id=client_user_id
            )
        )
    response = client.link_token_create(request)
    link_token = response['link_token']

    # Send the data to the client
    return jsonify(response.to_dict())

`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-go"
            }}>{`import (
    "context"
    "net/http"
    "os"

    "github.com/gin-gonic/gin"
    "github.com/plaid/plaid-go/plaid"
)


func createLinkToken(c *gin.Context) {
  ctx := context.Background()

  // Get the client_user_id by searching for the current user
  user, _ := usermodels.Find(...)
  clientUserId := user.ID.String()

  // Create a link_token for the given user
  paymentInitiationReq := plaid.NewLinkTokenCreateRequestPaymentInitiation()
  request := plaid.NewLinkTokenCreateRequest("Plaid Test App", "en", []plaid.CountryCode{plaid.COUNTRYCODE_GB}, *plaid.NewLinkTokenCreateRequestUser(clientUserId))
  request.SetWebhook("https://webhook.sample.com")
  request.SetProducts([]plaid.Products{plaid.PRODUCTS_PAYMENT_INITIATION})
  request.SetPaymentInitiation(*paymentInitiationReq)

    resp, _, err := testClient.PlaidApi.LinkTokenCreate(ctx).LinkTokenCreateRequest(*request).Execute()

  // Send the data to the client
  c.JSON(http.StatusOK, gin.H{
    "link_token": resp.GetLinkToken(),
  })
}

`}</code></pre>
        </MultiCodeBlock>
      </ContentSwitcher.Content>
      <ContentSwitcher.Content>
        <MultiCodeBlock type='server' mdxType="MultiCodeBlock">
          <pre><code parentName="pre" {...{
              "className": "language-node",
              "metastring": "#server.js",
              "#server.js": true
            }}>{`// Using Express
app.post('/create_link_token', async (request, response) => {
  try {
    // Get the client_user_id by searching for the current user
    const user = await User.find(...);
    const clientUserId = user.id;

    // Create the link_token with all of your configurations
    const tokenResponse = await client.createLinkToken({
      user: {
        client_user_id: clientUserId,
      },
      client_name: 'Plaid Test App',
      products: ['payment_initiation'],
      country_codes: ['GB'],
      language: 'en',
      webhook: 'https://webhook.sample.com',
      payment_initiation: {
        payment_id: paymentId,
      },
    });

    response.on({ link_token: tokenResponse.link_token });
  } catch (e) {
    // Display error on client
    return response.send({ error: e.message });
  }
});
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`curl -X POST https://sandbox.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "Plaid Test App",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "products": ["payment_initiation"],
  "country_codes": ["GB"],
  "language": "en",
  "webhook": "https://webhook.sample.com",
  "payment_initiation": { "payment_id": "\${PAYMENT_ID}" },
}'
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-ruby"
            }}>{`require 'sinatra'
require 'plaid'

post '/create_link_token' do
  // Get the client_user_id by searching for the current user
  current_user = User.find(...)
  client_user_id = current_user.id

  // Create a link_token for the given user
  token_response = client.link_token.create(
    user: {
      client_user_id: client_user_id
    },
    client_name: 'Plaid Test App',
    products: ['payment_initiation'],
    country_codes: ['GB'],
    language: 'en',
    webhook: 'https://webhook.sample.com',
    payment_initiation: {
      payment_id: payment_id
    }
  )

  // Send the data to the client
  content_type :json
  response.to_json
end
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-java",
              "metastring": "#Server.java",
              "#Server.java": true
            }}>{`import com.plaid.client.request.LinkTokenCreateRequest;
import com.plaid.client.request.LinkTokenCreateResponse;

static class GetLinkToken implements HttpHandler {
  private static PlaidClient plaidClient;

  public void handle(HttpExchange t) throws IOException {
    // Build your Plaid client
    PlaidClient plaidClient = PlaidClient.newBuilder()
      .clientIdAndSecret(CLIENT_ID, SECRET)
      .sandboxBaseUrl() // sandbox Plaid environment
      .logLevel(HttpLoggingInterceptor.Level.BODY)
      .build();

    // Simplified psuedo-code for obtaining a user_id
    User userFromDB = db.find(...);
    String clientUserId = userFromDB.id;
    LinkTokenCreateRequest.User user = new LinkTokenCreateRequest.User(clientUserId);

    // Create a link_token for the given user
    Response<LinkTokenCreateResponse> response =
      plaidClient.service()
        .linkTokenCreate(
          new LinkTokenCreateRequest(
            user,
            "Plaid Test App",
            Collections.singletonList("payment_initiation"),
            Collections.singletonList("GB"),
            "en"
          )
          .withWebhook("https://sample.webhook.com")
          .withPaymentInitiation(
            new LinkTokenCreateRequest.PaymentInitiation(PAYMENT_ID)
        ).execute();

    // Send the data to the client
    return response.body();
  }
}
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-python"
            }}>{`
# Using Flask
@app.route("/create_link_token", methods=['POST'])
def create_link_token():
    # Get the client_user_id by searching for the current user
    user = User.find(...)
    client_user_id = user.id

    # Create a link_token for the given user
    response = client.LinkToken.create({
      'user': {
        'client_user_id': client_user_id,
      },
      'products': ['payment_initiation'],
      'client_name': 'Plaid Test App',
      'country_codes': ['GB'],
      'language': 'en',
      'webhook': 'https://webhook.sample.com',
      'payment_initiation': {
        'payment_id': payment_id,
      }
    })
    link_token = response['link_token']

    # Send the data to the client
    return jsonify(response)

`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-go"
            }}>{`import (
    "net/http"
    "os"

    "github.com/gin-gonic/gin"
    "github.com/plaid/plaid-go/plaid"
)

func createLinkToken(c *gin.Context) {
  //  Get the client_user_id by searching for the current user
  user, _ := usermodels.Find(...)
  clientUserId := user.ID.String()

  // Create a link_token for the given user
  tokenResponse, _ := client.CreateLinkToken(plaid.LinkTokenConfigs{
    User: &plaid.LinkTokenUser{
      ClientUserID: clientUserId,
    },
    ClientName: "Plaid Test App",
    Products: []string{"payment_initiation"},
    CountryCodes: []string{"GB"},
    Language: []string{"en"},
    Webhook: 'https://webhook.sample.com',
    PaymentInitiation: &plaid.PaymentInitiation{
      PaymentID: paymentId,
    }
  })

  // Send the data to the client
  c.JSON(http.StatusOK, gin.H{
    "link_token": tokenResponse.LinkToken,
  })
}

`}</code></pre>
        </MultiCodeBlock>
      </ContentSwitcher.Content>
    </ContentSwitcher.Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
