import React, { useContext } from 'react';

import { APIVersion as APIVersionT } from '../types';
import { API_VERSIONS } from '../constants';
import Context from '../../../contexts/docs';

interface Props {
  version: string;
  children: React.ReactNode;
}

const allOperators = ['<', '>', '<=', '>='];

const operatorPattern = new RegExp('(<=|>=|<|>)(.*)');

const APIVersion: React.FC<Props> = ({ version, children }) => {
  const { apiVersion } = useContext(Context);

  const content = <>{children}</>;

  const versionParts = version.match(operatorPattern);

  // no match, exact version, e.g. version='2019-05-29'
  if (versionParts == null) {
    if (!API_VERSIONS.includes(version as APIVersionT)) {
      throw new Error(`APIVersion: ${version} is not a valid API version`);
    }
    return apiVersion === version ? content : null;
  }

  // match, operator is present e.g. version='>2018-05-22'
  // operator, e.g. >
  const operator = versionParts[1];
  // version, e.g. 2018-05-22
  const versionString = versionParts[2];

  if (!API_VERSIONS.includes(versionString as APIVersionT)) {
    throw new Error(`APIVersion: ${versionString} is not a valid API version`);
  }

  if (!allOperators.includes(operator)) {
    throw new Error(
      `APIVersion: ${operator} is not a valid operator, must be one of >, <, >=, <=`,
    );
  }

  switch (operator) {
    case '>':
      return apiVersion > versionString ? content : null;
    case '<':
      return apiVersion < versionString ? content : null;
    case '>=':
      return apiVersion >= versionString ? content : null;
    case '<=':
      return apiVersion <= versionString ? content : null;
    default:
      return null;
  }
};

export default APIVersion;
