import React from 'react';
import Image from 'src/components/docs/Image';
import cx from 'classnames';
import IconButton from 'plaid-threads/IconButton';

import styles from './Community.module.scss';

const Community = () => {
  return (
    <div className={cx(styles.communityContainer)}>
      <div className={cx(styles.text)}>Developer community</div>
      <div className={cx(styles.icons)}>
        <div className={styles.iconContainer}>
          <IconButton
            accessibilityLabel='GitHub'
            href='https://github.com/plaid'
            icon={
              <Image
                src='/assets/img/icons/icon-social-github.svg'
                alt='GitHub'
              />
            }
          />
        </div>
        <div className={cx(styles.iconContainer)}>
          <IconButton
            accessibilityLabel='Stack Overflow'
            href='https://stackoverflow.com/questions/tagged/plaid?tab=Newest'
            icon={
              <Image
                src='/assets/img/icons/icon-social-stack-overflow.svg'
                alt='Stack Overflow'
              />
            }
          />
        </div>
        <div className={cx(styles.iconContainer)}>
          <IconButton
            accessibilityLabel='YouTube'
            href='https://www.youtube.com/c/PlaidInc'
            icon={
              <Image
                src='/assets/img/icons/icon-social-youtube-black.svg'
                alt='YouTube'
              />
            }
          />
        </div>
        <div className={cx(styles.iconContainer)}>
          <IconButton
            accessibilityLabel='Discord'
            href='https://discord.gg/sf57M8DW3y'
            icon={
              <Image src='/assets/img/icons/discord-footer.svg' alt='Discord' />
            }
          />
        </div>
      </div>
    </div>
  );
};

Community.displayName = 'Community';
export default Community;
