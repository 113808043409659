import React, { ChangeEvent } from 'react';

import PasswordInput from 'plaid-threads/PasswordInput';
import Key from 'plaid-threads/Icons/Key';
import styles from './index.module.scss';

interface Props {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  title: string;
}

// PseudoPassword provides a very simple localStorage check that prevents
// people from accessing the page without the correct access code / password.
// It is not meant to be high security and does not perform any actual backend
// authentication. It is mostly to keep different finrise cohorts in their
// respective parts of the website.
const PseudoPassword: React.FC<Props> = ({ value, onChange, id, title }) => (
  <>
    <div className={styles.fullscreen}>
      <div className={styles.card}>
        <div className={styles.iconContainer}>
          <Key />
        </div>
        <p className={styles.paragraph}>
          <b>{title}</b>
          <br />
          Enter password to continue.
        </p>
        <PasswordInput
          value={value}
          onChange={onChange}
          id={id}
          label='Password'
        />
      </div>
    </div>
  </>
);

PseudoPassword.displayName = 'PseudoPassword';
export default PseudoPassword;
