import React from 'react';

interface Props {
  units: number;
}

const Spacer: React.FC<Props> = (props: Props) => (
  <div style={{ marginBottom: `${props.units * 0.8}rem` }}></div>
);

Spacer.displayName = 'Spacer';

export default Spacer;
