
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';
import ContentSwitcher from '../../ContentSwitcher';

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ContentSwitcher.Container id='libraries' names={['Current libraries', 'Legacy libraries']}>
      <ContentSwitcher.Content>
        <MultiCodeBlock type='server' mdxType="MultiCodeBlock">
          <pre><code parentName="pre" {...{
              "className": "language-node",
              "metastring": "#server.js",
              "#server.js": true
            }}>{`// Using Express
app.post('/api/create_link_token', async function (request, response) {
  // Get the client_user_id by searching for the current user
  const user = await User.find(...);
  const clientUserId = user.id;
  const request = {
    user: {
      client_user_id: clientUserId,
    },
    client_name: 'Plaid Test App',
    language: 'en',
    webhook: 'https://webhook.sample.com',
    country_codes: [CountryCode.Us],
    access_token: 'ENTER_YOUR_ACCESS_TOKEN',
  };
  try {
    const createTokenResponse = await client.linkTokenCreate(request);
    response.json(createTokenResponse.data);
  } catch (error) {
    // handle error
  }
});
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`curl -X POST https://sandbox.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "Plaid Test App",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "access_token": "\${ACCESS_TOKEN}",
  "country_codes": ["US"],
  "language": "en",
  "webhook": "https://webhook.sample.com"
}'
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-ruby",
              "metastring": "#server.rb",
              "#server.rb": true
            }}>{`require 'sinatra'
require 'plaid'

post '/api/create_link_token' do
  // Get the client_user_id by searching for the current user
  current_user = User.find(...)
  client_user_id = current_user.id

  # Create a link_token for the given user
  request = Plaid::LinkTokenCreateRequest.new(
    {
      user => { client_user_id: client_user_id },
      client_name: 'Plaid Test App',
      access_token:  'ENTER_YOUR_ACCESS_TOKEN',
      country_codes: ['US'],
      language: "en",
      webhook: 'https://webhook.sample.com'
    }
  )
  response = client.link_token_create(request)
  content_type :json
  response.to_json
end
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-java",
              "metastring": "#Server.java",
              "#Server.java": true
            }}>{`import com.plaid.client.model.LinkTokenCreateRequest;
import com.plaid.client.model.LinkTokenCreateRequestUser;
import com.plaid.client.model.LinkTokenCreateResponse;

static class GetLinkToken implements HttpHandler {
  private static PlaidClient plaidClient;

  public void handle(HttpExchange t) throws IOException {
    // Create your Plaid client
    HashMap<String, String> apiKeys = new HashMap<String, String>();
    apiKeys.put("clientId", CLIENT_ID);
    apiKeys.put("secret", SECRET);
    apiKeys.put("plaidVersion", "2020-09-14");
    apiClient = new ApiClient(apiKeys);
    apiClient.setPlaidAdapter(ApiClient.Sandbox);

    plaidClient = apiClient.createService(PlaidApi.class);

    // Simplified psuedo-code for obtaining a user_id
    User userFromDB = db.find(...);
    String clientUserId = userFromDB.id;
    LinkTokenCreateRequestUser user = new LinkTokenCreateRequestUser()
      .clientUserId(clientUserId);

    // Create a link_token for the given user
    LinkTokenCreateRequest request = new LinkTokenCreateRequest()
      .user(user)
      .clientName("Plaid Test App")
      .accessToken("ENTER_YOUR_ACCESS_TOKEN")
      .countryCodes(['US', 'CA']))
      .language("en")
      .redirectUri(redirectUri)
      .webhook("https://sample.webhook.com");

    Response<LinkTokenCreateResponse> response = plaidClient
      .linkTokenCreate(request)
      .execute();

    // Send the data to the client
    return response.body();
  }
}
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-python",
              "metastring": "#server.py",
              "#server.py": true
            }}>{`# Using Flask
@app.route("/create_link_token", methods=['POST'])
def create_link_token():
    # Get the client_user_id by searching for the current user
    user = User.find(...)
    client_user_id = user.id

    # Create a link_token for the given user
    request = LinkTokenCreateRequest(
            access_token ="ENTER_YOUR_ACCESS_TOKEN",
            client_name="Plaid Test App",
            country_codes=[CountryCode('US')],
            language='en',
            webhook='https://webhook.sample.com',
            user=LinkTokenCreateRequestUser(
                client_user_id=client_user_id
            )
        )
    response = client.link_token_create(request)
    link_token = response['link_token']

    # Send the data to the client
    return jsonify(response.to_dict())

`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-go",
              "metastring": "#server.go",
              "#server.go": true
            }}>{`import (
    "context"
    "net/http"
    "os"

    "github.com/gin-gonic/gin"
    "github.com/plaid/plaid-go/plaid"
)

func createLinkToken(c *gin.Context) {
  ctx := context.Background()

  // Get the client_user_id by searching for the current user
  user, _ := usermodels.Find(...)
  clientUserId := user.ID.String()

  // Create a link_token for the given user
  request := plaid.NewLinkTokenCreateRequest("Plaid Test App", "en", []plaid.CountryCode{plaid.COUNTRYCODE_US}, *plaid.NewLinkTokenCreateRequestUser(clientUserId))
  request.SetWebhook("https://webhook.sample.com")
  request.SetAccessToken("ENTER_YOUR_ACCESS_TOKEN")

    resp, _, err := testClient.PlaidApi.LinkTokenCreate(ctx).LinkTokenCreateRequest(*request).Execute()

  // Send the data to the client
  c.JSON(http.StatusOK, gin.H{
    "link_token": resp.GetLinkToken(),
  })
}

`}</code></pre>
        </MultiCodeBlock>
      </ContentSwitcher.Content>
      <ContentSwitcher.Content>
        <MultiCodeBlock type='server' title='Link token create request for update mode' mdxType="MultiCodeBlock">
          <pre><code parentName="pre" {...{
              "className": "language-node",
              "metastring": "#server.js",
              "#server.js": true
            }}>{`// Using Express
app.post('/create_link_token', async (request, response) => {
  try {
    // Get the client_user_id by searching for the current user
    const user = await User.find(...);
    const clientUserId = user.id;

    // Create the link_token with all of your configurations
    const tokenResponse = await client.createLinkToken({
      user: {
        client_user_id: clientUserId,
      },
      client_name: 'Plaid Test App',
      country_codes: ['US'],
      language: 'en',
      webhook: 'https://webhook.sample.com',
      access_token: 'ENTER_YOUR_ACCESS_TOKEN',
    });

    response.on({ link_token: tokenResponse.link_token });
  } catch (e) {
    // Display error on client
    return response.send({ error: e.message });
  }
});
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`curl -X POST https://sandbox.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "Plaid Test App",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "country_codes": ["US"],
  "language": "en",
  "webhook": "https://webhook.sample.com"
  "access_token": "\${ACCESS_TOKEN}",
}'
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-ruby"
            }}>{`require 'sinatra'
require 'plaid'

post '/create_link_token' do
  // Get the client_user_id by searching for the current user
  current_user = User.find(...)
  client_user_id = current_user.id

  // Create a link_token for the given user
  token_response = client.link_token.create(
    user: {
      client_user_id: client_user_id
    },
    client_name: 'Plaid Test App',
    country_codes: ['US'],
    language: 'en',
    webhook: 'https://webhook.sample.com'
    access_token: 'ENTER_YOUR_ACCESS_TOKEN',
  )

  // Send the data to the client
  content_type :json
  response.to_json
end
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-java",
              "metastring": "#Server.java",
              "#Server.java": true
            }}>{`import com.plaid.client.request.LinkTokenCreateRequest;
import com.plaid.client.request.LinkTokenCreateResponse;

static class GetLinkToken implements HttpHandler {
  private static PlaidClient plaidClient;

  public void handle(HttpExchange t) throws IOException {
    // Build your Plaid client
    PlaidClient plaidClient = PlaidClient.newBuilder()
      .clientIdAndSecret(CLIENT_ID, SECRET)
      .sandboxBaseUrl() // sandbox Plaid environment
      .logLevel(HttpLoggingInterceptor.Level.BODY)
      .build();

    // Simplified psuedo-code for obtaining a user_id
    User userFromDB = db.find(...);
    String clientUserId = userFromDB.id;
    LinkTokenCreateRequest.User user = new LinkTokenCreateRequest.User(clientUserId);

    // Create a link_token for the given user
    Response<LinkTokenCreateResponse> response =
      plaidClient.service()
        .linkTokenCreate(
          new LinkTokenCreateRequest(
            user,
            "Plaid Test App",
            Collections.singletonList(),
            Collections.singletonList("US"),
            "en"
          )
          .withWebhook("https://sample.webhook.com")
          .withAccessToken("ENTER_YOUR_ACCESS_TOKEN")
        ).execute();

    // Send the data to the client
    return response.body();
  }
}
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-python"
            }}>{`# Using Flask
@app.route("/create_link_token", methods=['POST'])
def create_link_token():
    # Get the client_user_id by searching for the current user
    user = User.find(...)
    client_user_id = user.id

    # Create a link_token for the given user
    response = client.LinkToken.create({
      'user': {
        'client_user_id': client_user_id,
      },
      'client_name': 'Plaid Test App',
      'country_codes': ['US'],
      'language': 'en',
      'webhook': 'https://webhook.sample.com',
      'access_token': 'ENTER_YOUR_ACCESS_TOKEN',
    })
    link_token = response['link_token']

    # Send the data to the client
    return jsonify(response)
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-go"
            }}>{`import (
    "net/http"
    "os"

    "github.com/gin-gonic/gin"
    "github.com/plaid/plaid-go/plaid"
)

func createLinkToken(c *gin.Context) {
  //  Get the client_user_id by searching for the current user
  user, _ := usermodels.Find(...)
  clientUserId := user.ID.String()

  // Create a link_token for the given user
  tokenResponse, _ := client.CreateLinkToken(plaid.LinkTokenConfigs{
    User: &plaid.LinkTokenUser{
      ClientUserID: clientUserId,
    },
    ClientName: "Plaid Test App",
    CountryCodes: []string{"US"},
    Language: []string{"en"},
    AccessToken: "ENTER_YOUR_ACCESS_TOKEN",
  })

  // Send the data to the client
  c.JSON(http.StatusOK, gin.H{
    "link_token": tokenResponse.LinkToken,
  })
}

`}</code></pre>
        </MultiCodeBlock>
      </ContentSwitcher.Content>
    </ContentSwitcher.Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
