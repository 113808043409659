import React from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';

import Sidebar from '../Sidebar';
import PseudoPassword from '../PseudoPassword';
import {
  ACCELERATE_BASE_URL_2021,
  ACCELERATE_BASE_URL_2022,
} from '../constants';

import styles from 'src/components/docs/Layout/Page.module.scss';
import globalStyles from '../../../components/docs/DocsBeta/index.module.scss';

const Page = (props) => {
  const scrollContainer = React.useRef(null);
  const { asPath } = useRouter();
  let base_url = '';

  // Pseduo A setup
  const [showA, setShowA] = React.useState(false);
  const [pseudoA, setPseudoA] = React.useState(() => {
    if (typeof window !== 'undefined') {
      const savedA = localStorage.getItem('pseudoA'); // Get stored value
      const valueA = JSON.parse(savedA);
      return valueA || null;
    }

    return null;
  });

  // Pseduo B setup
  const [showB, setShowB] = React.useState(false);
  const [pseudoB, setPseudoB] = React.useState(() => {
    if (typeof window !== 'undefined') {
      const savedB = localStorage.getItem('pseudoB'); // Get stored value
      const valueB = JSON.parse(savedB);
      return valueB || null;
    }

    return null;
  });

  // Check if passwords are correct
  React.useEffect(() => {
    if (pseudoA === 'Welcome2021') {
      setShowA(true);
    }

    if (pseudoB === 'Welcome2022') {
      setShowB(true);
    }

    localStorage.setItem('pseudoA', JSON.stringify(pseudoA));
    localStorage.setItem('pseudoB', JSON.stringify(pseudoB));
  }, [pseudoA, pseudoB]);

  React.useEffect(() => {
    // if page is a deeplink, do nothing
    if (asPath.includes('#')) {
      return;
    }
    // if not, reset the scroll container so that
    // we dont end up somewhere down the page when the route changes
    if (scrollContainer?.current?.scrollTop != null) {
      scrollContainer.current.scrollTop = 0;
    }
  }, [asPath]);

  // Check for 2021 password
  if (asPath.includes(ACCELERATE_BASE_URL_2021)) {
    base_url = ACCELERATE_BASE_URL_2021;

    if (!showA) {
      return (
        <PseudoPassword
          value={pseudoA}
          onChange={(e) => setPseudoA(e.target.value)}
          id='pass2021'
          title='Accelerate 2021 Guide'
        />
      );
    }
  }

  // Check for 2022 password
  if (asPath.includes(ACCELERATE_BASE_URL_2022)) {
    base_url = ACCELERATE_BASE_URL_2022;

    if (!showB) {
      return (
        <PseudoPassword
          value={pseudoB}
          onChange={(e) => setPseudoB(e.target.value)}
          id='pass2022'
          title='Accelerate 2022 Guide'
        />
      );
    }
  }

  // Return page once we've passed all the password checks above
  return (
    <>
      <div className={cx(globalStyles.docs, styles.layout)}>
        <Sidebar baseUrl={base_url} />
        <div className={styles.navbarContainer}>
          <div
            ref={scrollContainer}
            className={cx(
              'docs-scroll-container',
              styles.contentScrollContainer,
            )}
          >
            <main className={cx(styles.content, styles.noNav)}>
              {props.children}
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

Page.displayName = 'Page';
Page._type = 'FinRise-Doc';

export default Page;
