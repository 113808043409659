import React from 'react';
import cx from 'classnames';

import styles from './index.module.scss';

interface Props {
  header: string;
  children: React.ReactNode;
  className?: string;
  withPadding?: boolean;
}

const ContainerWithHeader: React.FC<Props> = (props: Props) => (
  <div className={cx(styles.container, props.className)}>
    <div className={styles.header}>{props.header}</div>
    {props.withPadding ? (
      <div className={styles.content}>{props.children}</div>
    ) : (
      props.children
    )}
  </div>
);

ContainerWithHeader.displayName = 'ContainerWithHeader';

export default ContainerWithHeader;
