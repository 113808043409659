import React from 'react';

import { PartnerProps } from '../../types';
import Partner from './Partner';

type Props = Pick<PartnerProps, 'isPaymentProcessor' | 'partner'>;

const AccountGuidance: React.FC<PartnerProps> = (props: Props) => {
  if (props.isPaymentProcessor) {
    return (
      <>
        If you want the user to specify only a single account to link so you
        know which account to use with <Partner p={props.partner} />, set{' '}
        <a href='https://dashboard.plaid.com/link/account-select'>
          Account Select
        </a>{' '}
        to "enabled for one account" in the Plaid Dashboard. When this setting
        is selected, the <code>accounts</code> array will always contain exactly
        one account.
      </>
    );
  }
  return (
    <>
      The `accounts` array will contain information about bank accounts
      associated with the credentials entered by the user, and may contain
      multiple accounts if the user has more than one bank account at the
      institution. In most cases, you will want your users to connect all of
      their accounts so that they can be tracked in{' '}
      <Partner p={props.partner} />.
    </>
  );
};

export default AccountGuidance;
