import { ErrorReference } from '../../types';

export const TRANSACTION_INSUFFICIENT_FUNDS: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'TRANSACTION_INSUFFICIENT_FUNDS',
    error_message: 'There are insufficient funds to complete the transaction',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSACTION_EXECUTION_FAILED: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'TRANSACTION_EXECUTION_FAILED',
    error_message:
      'There was a problem executing the transaction. Please retry.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSACTION_ON_SAME_ACCOUNT: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'TRANSACTION_ON_SAME_ACCOUNT',
    error_message: 'Payment to the same account is not allowed',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSACTION_CURRENCY_MISMATCH: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'TRANSACTION_CURRENCY_MISMATCH',
    error_message:
      'The currency between the wallet and recipient account is different',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSACTION_IBAN_INVALID: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'TRANSACTION_IBAN_INVALID',
    error_message: 'The provided IBAN is invalid',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSACTION_BACS_INVALID: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'TRANSACTION_BACS_INVALID',
    error_message:
      'The provided BACS account number and/or sort code is invalid',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSACTION_FAST_PAY_DISABLED: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'TRANSACTION_FAST_PAY_DISABLED',
    error_message: 'The recipient sort code is not enabled for faster payments',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSACTION_AMOUNT_EXCEEDED: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'TRANSACTION_AMOUNT_EXCEEDED',
    error_message:
      'Transaction amount exceeds the allowed threshold for this client',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const NONIDENTICAL_REQUEST: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'NONIDENTICAL_REQUEST',
    error_message:
      'Request body does not match previous request with this idempotency key',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const REQUEST_CONFLICT: ErrorReference = {
  server: {
    error_type: 'TRANSACTION_ERROR',
    error_code: 'REQUEST_CONFLICT',
    error_message: 'Original request is still processing',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
