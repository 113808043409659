import React, { useContext, useRef } from 'react';
import { useRouter } from 'next/router';
import Touchable from 'plaid-threads/Touchable';
import SearchIcon from 'plaid-threads/Icons/Search';
import cx from 'classnames';

import { usePrevious } from 'src/hooks';

import Context from '../../../contexts/docs';

import styles from './index.module.scss';
import SearchParent from './SearchParent';
import FlippyIcon from './FlippyIcon';

type FinnVariant = 'enabled' | 'disabled' | 'unknown';
type SearchPlaceholderProps = {
  finnVariant: FinnVariant;
};

const SearchPlaceholder: React.FC<SearchPlaceholderProps> = ({
  finnVariant,
}) => {
  const { dispatch, showSearch } = useContext(Context);
  const searchRef = useRef(null);
  const previousShowSearch = usePrevious(showSearch);
  const { pathname } = useRouter();
  const previousPathname = usePrevious(pathname);

  // usePrevious hook is used to determine whether the modal has just now closed, at which point
  // focus should be placed on the search bar.  Also, keep the focus on the search bar only if
  // you remain on the same page when the modal closes (when clicking X or clicking outside the modal).
  // Otherwise focus should be directed to the id of the search hit chosen on the new page.

  React.useEffect(() => {
    if (previousShowSearch && !showSearch && searchRef.current != null) {
      searchRef.current.focus();
    }
  }, [showSearch, previousShowSearch, searchRef]);

  React.useEffect(() => {
    if (previousPathname !== pathname && searchRef.current != null) {
      searchRef.current.blur();
    }
  }, [searchRef, pathname, previousPathname]);

  return (
    <span className={cx(styles.inputWrapper, showSearch && styles.hidden)}>
      <Touchable
        className={styles.button}
        ref={searchRef}
        onClick={() =>
          dispatch({
            type: 'SET_SEARCH_VISIBILITY',
            payload: {
              visibility: true,
              openSearchClicked: true,
            },
          })
        }
      >
        {finnVariant === 'enabled' ? (
          <>
            Search or Ask
            <span className={styles.onlyLargeFields}>&nbsp;a Question</span>
            <span className={cx(styles.onlyLargeFields, styles.icon)}>
              <FlippyIcon />
            </span>
          </>
        ) : finnVariant === 'disabled' ? (
          <>
            Search <SearchIcon className={styles.icon} />
          </>
        ) : (
          <>
            {' '}
            <SearchIcon className={styles.icon} />
          </>
        )}
      </Touchable>
    </span>
  );
};

const Search: React.FC = () => {
  return (
    <>
      <SearchPlaceholder finnVariant='enabled' />
      <SearchParent showFinn={true} />
    </>
  );
};

Search.displayName = 'Search';
export default Search;
