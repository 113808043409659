import React, { useContext } from 'react';
import cx from 'classnames';

import DocsContext from '../../../contexts/docs';
import Feedback from '../Feedback';
import Community from './Community';
import Layout from '../Layout';

import styles from './index.module.scss';

const Footer = () => {
  const { metadata } = useContext(DocsContext);

  return (
    <footer className={styles.footerContainer} data-algolia-remove='true'>
      <Layout.FullWidth>
        <Layout.Divider />
        <div className={cx(styles.footerItems)}>
          {metadata.source !== 'error' &&
            metadata.source !== 'endpoint' &&
            metadata.source !== 'webhook' && (
              <Feedback name='footer' source='footer' />
            )}
          <Community />
        </div>
      </Layout.FullWidth>
    </footer>
  );
};

Footer.displayName = 'Footer';
export default Footer;
