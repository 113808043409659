
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';
import OptionalText from './OptionalText';
import Partner from './Partner';
import ContentSwitcher from '../../ContentSwitcher';
import SampleCode from '../SampleCode';
import LinkTokenCreate from '../../LinkTokenCreate';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h5 {...{
      "id": "set-up-your-accounts"
    }}>{`Set up your accounts`}</h5>
    <p>{`You'll need accounts at both Plaid and `}<Partner p={props.partner} mdxType="Partner" />{` in order to use the
Plaid + `}<Partner p={props.partner} mdxType="Partner" />{` integration. You'll also need to enable your
Plaid account for the `}<Partner p={props.partner} mdxType="Partner" />{` integration.`}</p>
    <p>{`First, you will need to work with the `}<Partner p={props.partner} mdxType="Partner" />{` team
to `}<a href={props.registrationUrl} children={`sign up for a ${props.partner} account`} />,
if you do not already have one. <OptionalText text={props.extraSetupInstructions} mdxType="OptionalText" /></p>
    <p>{`Next, verify that your Plaid account is enabled for the integration. If you do not have a Plaid account,
`}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/signup"
      }}>{`create one`}</a>{`.`}</p>
    <p>{`To enable your Plaid account for the integration, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/developers/integrations"
      }}>{`Integrations`}</a>{`
section of the account dashboard. If the integration is off, simply click the 'Enable' button
for `}<Partner p={props.partner} mdxType="Partner" />{` to enable the integration.`}</p>
    <p>{`You'll need to complete your Plaid `}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/settings/company/app-branding"
      }}>{`Application Profile`}</a>{` in the Dashboard, which involves filling out basic information about your app, such as your company name and website. This step helps your end-users learn more how your product uses their bank information and is also required for connecting to some banks.`}</p>
    <p>{`Finally, you'll need to go to the `}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/link/data-transparency-v5"
      }}>{`Link customization UI`}</a>{` and pick the use cases that you will be using `}<Partner p={props.partner} mdxType="Partner" />{` to power, so that Plaid can request the appropriate authorization and consent from your end users. If you have any questions, contact  `}<Partner p={props.partner} mdxType="Partner" />{`.`}</p>
    <h5 {...{
      "id": "create-a-link_token"
    }}>{`Create a link_token`}</h5>
    <p>{`In order to integrate with Plaid Link, you will first need to create a `}<inlineCode parentName="p">{`link_token`}</inlineCode>{`. A `}<inlineCode parentName="p">{`link_token`}</inlineCode>{`
is a short-lived, one-time use token that is used to authenticate your app with Link. To create one,
make a `}<inlineCode parentName="p">{`/link/token/create`}</inlineCode>{` request with your `}<inlineCode parentName="p">{`client_id`}</inlineCode>{`, `}<inlineCode parentName="p">{`secret`}</inlineCode>{`, and a few other
required parameters from your app server. For a full list of `}<inlineCode parentName="p">{`link_token`}</inlineCode>{` configurations, see `}<inlineCode parentName="p">{`/link/token/create`}</inlineCode>{`.`}</p>
    <p>{`To see your `}<inlineCode parentName="p">{`client_id`}</inlineCode>{` and `}<inlineCode parentName="p">{`secret`}</inlineCode>{`, visit the `}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/developers/keys"
      }}>{`Plaid Dashboard`}</a>{`.`}</p>
    <LinkTokenCreate useCases={[{
      useCase: 'Account Verification for ACH',
      config: {
        user: {
          client_user_id: 'user-id',
          phone_number: '+1 415 555 0123'
        },
        client_name: 'Money Transfer App',
        products: ['auth'],
        country_codes: ['US'],
        language: 'en',
        webhook: 'https://sample-web-hook.com',
        redirect_uri: 'https://domainname.com/oauth-page.html'
      }
    }]} mdxType="LinkTokenCreate" />
    <h5 {...{
      "id": "integrate-with-plaid-link"
    }}>{`Integrate with Plaid Link`}</h5>
    <p>{`Once you have a `}<inlineCode parentName="p">{`link_token`}</inlineCode>{`, all it takes is a few lines of client-side JavaScript to launch
Link. Then, in the `}<inlineCode parentName="p">{`onSuccess`}</inlineCode>{` callback, you can call a simple server-side handler to exchange
the Link `}<inlineCode parentName="p">{`public_token`}</inlineCode>{` for a Plaid `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` and a `}<Partner p={props.partner} mdxType="Partner" />{` `}<inlineCode parentName="p">{`processor_token`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "switcher=false #Integrate Link",
        "switcher": "false",
        "#Integrate": true,
        "Link": true
      }}>{`<button id="linkButton">Open Link - Institution Select</button>
<script src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"></script>
<script>
  (async function(){
    var linkHandler = Plaid.create({
      // Make a request to your server to fetch a new link_token.
      token: (await $.post('/create_link_token')).link_token,
      onSuccess: function(public_token, metadata) {
        // The onSuccess function is called when the user has successfully
        // authenticated and selected an account to use.
        //
        // When called, you will send the public_token and the selected accounts,
        // metadata.accounts, to your backend app server.
        sendDataToBackendServer({
           public_token: public_token,
           accounts: metadata.accounts
        });
      },
      onExit: function(err, metadata) {
        // The user exited the Link flow.
        if (err != null) {
            // The user encountered a Plaid API error prior to exiting.
        }
        // metadata contains information about the institution
        // that the user selected and the most recent API request IDs.
        // Storing this information can be helpful for support.
      },
    });
  })();

  // Trigger the authentication view
  document.getElementById('linkButton').onclick = function() {
    // Link will automatically detect the institution ID
    // associated with the public token and present the
    // credential view to your user.
    linkHandler.open();
  };
</script>
`}</code></pre>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/docs/link/web#create"
      }}>{`Link parameter reference`}</a>{` for complete documentation on possible configurations.`}</p>
    <p><inlineCode parentName="p">{`Plaid.create`}</inlineCode>{` accepts one argument, a configuration `}<inlineCode parentName="p">{`Object`}</inlineCode>{`, and returns an `}<inlineCode parentName="p">{`Object`}</inlineCode>{` with three functions, `}<a parentName="p" {...{
        "href": "/docs/link/web/#open"
      }}><inlineCode parentName="a">{`open`}</inlineCode></a>{`, `}<a parentName="p" {...{
        "href": "/docs/link/web/#exit"
      }}><inlineCode parentName="a">{`exit`}</inlineCode></a>{`, and `}<a parentName="p" {...{
        "href": "/docs/link/web/#destroy"
      }}><inlineCode parentName="a">{`destroy`}</inlineCode></a>{`. Calling `}<inlineCode parentName="p">{`open`}</inlineCode>{` will display the "Institution Select" view, calling `}<inlineCode parentName="p">{`exit`}</inlineCode>{` will close Link, and calling `}<inlineCode parentName="p">{`destroy`}</inlineCode>{` will clean up the iframe.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
