import React from 'react';
import cx from 'classnames';

import styles from './FullWidth.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  withSeparator?: boolean;
}

const FullWidth: React.FC<Props> = (props: Props) => (
  <div className={cx(styles.fullWidthBustOut, props.className)}>
    <div className={styles.fullWidth}>{props.children}</div>
    {props.withSeparator && <div className={styles.separator}></div>}
  </div>
);

FullWidth.displayName = 'FullWidth';

export default FullWidth;
