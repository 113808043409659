import React, { useState, useEffect, useContext } from 'react';
import Image from 'src/components/docs/Image';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cx from 'classnames';
import CloseS1 from 'plaid-threads/Icons/CloseS1';
import IconButton from 'plaid-threads/IconButton';
import Button from 'plaid-threads/Button';

import SidebarSubnav from './SidebarSubnav';
import SidebarNavGroup from './SidebarNavGroup';
import {
  GENERAL,
  NavItem,
  NavGroup,
  PAYMENT_PRODUCTS,
  PFM_PRODUCTS,
  FRAUD_PRODUCTS,
  CREDIT_PRODUCTS,
  ONBOARDING_PRODUCTS,
} from '../constants/routeMap';
import { DOCS_BASE_URL } from '../constants';
import { EXCHANGE_DOCS_BASE_URL } from '../../exchange-docs/constants';
import { CORE_EXCHANGE_DOCS_BASE_URL } from '../../core-exchange-docs/constants';
import Context from '../../../contexts/docs';
import { getSection } from '../utilities';
import Search from '../Search';

import styles from './index.module.scss';
import APIVersionSwitcher from '../Navbar/APIVersionSwitcher';
import useUser from 'src/contexts/docs/useUser';

export interface Props {
  className?: string;
  mobile?: boolean;
  closed?: boolean;
  onClose?: () => void;
  navigation?: NavGroup; // optional override for reuse in finrise
  dark?: boolean; // override for finrise
  baseUrl?: string;
  logo?: string;
  headerText?: string | React.ReactNode;
}

const TOP_NAV: NavGroup = [
  ...GENERAL,
  ...PAYMENT_PRODUCTS,
  ...PFM_PRODUCTS,
  ...FRAUD_PRODUCTS,
  ...ONBOARDING_PRODUCTS,
  ...CREDIT_PRODUCTS,
];

const Sidebar: React.FC<Props> = (props: Props) => {
  const BASE_URL = props.baseUrl != null ? props.baseUrl : DOCS_BASE_URL;
  const router = useRouter();
  const [route, setRoute] = useState<NavItem>(
    getSection(router.pathname, BASE_URL, props.navigation),
  );

  // use Docs context
  const { tableOfContents, metadata } = useContext(Context);
  const { getFlagValue } = useUser();

  // on page-load & route-change - set the route section in states
  useEffect(() => {
    setRoute(getSection(router.pathname, BASE_URL, props.navigation));
  }, [BASE_URL, props.navigation, router.pathname]);

  if (props.mobile && props.closed) {
    return <></>;
  } else {
    return (
      <aside
        className={cx(
          props.className,
          styles.sidebar,
          styles.sidebarDefault,
          props.dark && styles.dark,
        )}
      >
        <nav aria-label='Main' className={styles.nav}>
          {props.mobile &&
            props.baseUrl !== EXCHANGE_DOCS_BASE_URL &&
            props.baseUrl !== CORE_EXCHANGE_DOCS_BASE_URL && (
              <div className={styles.mobileHeader}>
                <div className={styles.header}>
                  <div className={styles.homeLink}>
                    <Link href={DOCS_BASE_URL}>
                      <a rel='noopener noreferrer' className={styles.logo}>
                        <Image
                          className={styles.logoImage}
                          src={'/assets/img/navbar/logo.svg'}
                          alt='Plaid logo'
                          width='80'
                          height='30.47'
                          noMarginBottom
                        />
                        <span className={styles.logoText}>Docs</span>
                      </a>
                    </Link>
                  </div>
                  <div className={styles.close}>
                    <IconButton
                      accessibilityLabel='Close nav'
                      icon={<CloseS1 />}
                      onClick={() => props.onClose()}
                    />
                  </div>
                </div>
                <div className={styles.searchAndVersion}>
                  <div className={styles.search}>
                    <Search />
                  </div>
                  <div className={styles.apiVersion}>
                    <APIVersionSwitcher />
                  </div>
                  <div className={styles.plaidDotCom}>
                    <Link href='/'>
                      <a className={styles.link}>Plaid.com</a>
                    </Link>
                  </div>
                </div>
              </div>
            )}

          {props.mobile &&
            (props.baseUrl === EXCHANGE_DOCS_BASE_URL ||
              props.baseUrl === CORE_EXCHANGE_DOCS_BASE_URL) && (
              <div className={styles.exchangeClose}>
                <IconButton
                  accessibilityLabel='Close nav'
                  icon={<CloseS1 />}
                  onClick={() => props.onClose()}
                />
              </div>
            )}
          {!props.mobile && (
            <div className={styles.header}>
              <Link href={BASE_URL}>
                <a rel='noopener noreferrer' className={styles.logo}>
                  <Image
                    className={styles.logoImage}
                    src={
                      props.logo != null
                        ? props.logo
                        : '/assets/img/navbar/logo.svg'
                    }
                    alt='Plaid logo'
                    width='80'
                    height='30.47'
                    noMarginBottom
                  />
                  <span className={styles.logoText}>
                    {props.headerText != null ? props.headerText : 'Docs'}
                  </span>
                </a>
              </Link>
            </div>
          )}
          <div className={styles.shrinkContainer}>
            {/* if route has children (subnav) or there is a table of contents in route, show the subnav or table of contents;
          otherwise, show nav */}
            {tableOfContents == null ||
            (metadata.flag && !getFlagValue(metadata.flag)) ? (
              <></>
            ) : (route?.children != null && route?.subNav !== false) ||
              (tableOfContents.length > 0 && route?.subNav !== false) ? (
              <SidebarSubnav
                mobile={props.mobile}
                onClose={props.onClose}
                currentRoute={route}
                baseUrl={BASE_URL}
              />
            ) : (
              <>
                <SidebarNavGroup
                  baseUrl={BASE_URL}
                  dark={props.dark}
                  navGroup={
                    props.navigation != null ? props.navigation : TOP_NAV
                  }
                  currentRoute={route}
                  isCollapsible={props.navigation != null}
                />
              </>
            )}
            {props.mobile &&
              props.baseUrl !== EXCHANGE_DOCS_BASE_URL &&
              props.baseUrl !== CORE_EXCHANGE_DOCS_BASE_URL && (
                <>
                  <div className={styles.buttonWrapperAPIKeys}>
                    <Button
                      href='https://dashboard.plaid.com/signup'
                      target='_blank'
                      className={cx('signup-attach-utm', styles.getAPIKeys)}
                      secondary
                    >
                      Get API keys
                    </Button>
                  </div>
                </>
              )}
          </div>
        </nav>
      </aside>
    );
  }
};

Sidebar.displayName = 'Sidebar';
export default Sidebar;
