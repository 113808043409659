import Link from 'next/link';
import React, {
  CSSProperties,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { SchemaObject, ParameterObject } from 'openapi3-ts';
import Touchable from 'plaid-threads/Touchable';
import Button from 'plaid-threads/Button';
import snarkdown from 'snarkdown';
import cx from 'classnames';
import mergeAllOf from '@stoplight/json-schema-merge-allof';

import Context from '../../../contexts/docs';
import ChevronS2Down from 'plaid-threads/Icons/ChevronS2Down';
import ChevronS2Up from 'plaid-threads/Icons/ChevronS2Up';
import styles from './SchemaRow.module.scss';
import AdditionalProperties from './AdditionalProperties';
import { addWordBreaks, createId } from '../utilities';
import { getIndentLevel } from './schemaUtils';

type SchemaObjectEntry = [
  string,
  (
    | SchemaObject['properties']
    | { 'x-hidden-from-docs'?: boolean }
    | { 'x-hidden-behind-flag'?: string }
  ),
];

interface Props {
  schemaName: string;
  schemaEntry: SchemaObjectEntry;
  pathEntry?: ParameterObject;
  required?: boolean;
  deprecated?: boolean;
  depth: number;
  maxDepth?: number;
  parentHeight?: number;
  expandAll: boolean;
  expandText?: string;
  hideText?: string;
  type?: string;
  topMargin?: number;
  parentKeys: [] | string[];
  unhideFlag?: string;
  responseExamples?: SchemaObject['examples'];
  stringCode?: string;
  route?: string;
  exchangeSpec?: string;
  coreExchangeSpec?: string;
}

const isExpandable = (schemaItem: SchemaObject): boolean => {
  // this is an object, thereforce allow expanding
  if (schemaItem.properties != null) {
    return true;
  }

  // this is an array of objects, not an array of strings, so allow
  // don't allow items with oneOf discriminator, we don't support it yet
  const schemaItemSubItems: SchemaObject = schemaItem.items;
  if (
    schemaItemSubItems != null &&
    schemaItemSubItems.type === 'object' &&
    !schemaItemSubItems.oneOf
  ) {
    return true;
  }

  return false;
};

const shouldRenderSchemaItemArrayMembers = (
  schemaItem: SchemaObject,
): boolean => {
  return schemaItem.type === 'array' && schemaItem.items != null;
};

const SchemaRow: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(props.expandAll);
  const [itemExistsInExample, setItemExistsInExample] = useState(true);
  const [lineStart, setLineStart] = useState<number | null>(null);
  const [lineEnd, setLineEnd] = useState<number | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const { dispatch, selectedCode } = useContext(Context);
  const [myStickyHeight, setMyStickyHeight] = useState(0);
  const [isStuck, setIsStuck] = useState(false);

  let key: string;
  let id;
  let schemaItem: SchemaObject;
  let schemaItemSubItems: SchemaObject;
  let pathEntryIn: string = '';
  if (props.pathEntry) {
    key = props.pathEntry.name;
    id = createId(props.schemaName, key, props.parentKeys);
    schemaItem = props.pathEntry.schema;
    schemaItem.description = props.pathEntry.description;
    pathEntryIn = props.pathEntry.in;
  } else {
    key = props.schemaEntry[0];
    id = createId(props.schemaName, key, props.parentKeys);
    schemaItem = props.schemaEntry[1];
    schemaItemSubItems = schemaItem.items;

    // handle polymorphic schema items
    // https://swagger.io/docs/specification/data-models/inheritance-and-polymorphism/
    if (schemaItem.allOf != null || schemaItemSubItems?.allOf != null) {
      schemaItem = mergeAllOf(schemaItem, {
        resolvers: {
          defaultResolver: mergeAllOf.options.resolvers.title,
        },
      });
      schemaItemSubItems = schemaItem.items;
    }
  }
  const itemPlusParents = `${props.route}/${props.parentKeys.join('/')}/${key}`;
  React.useEffect(() => setIsOpen(props.expandAll), [props.expandAll]);

  const showLine = () => {
    if (selectedCode.item === itemPlusParents) {
      dispatch({
        type: 'SET_SELECTED_CODE',
        payload: {
          route: null,
          item: null,
          startLine: null,
          endLine: null,
        },
      });

      return;
    }
    dispatch({
      type: 'SET_SELECTED_CODE',
      payload: {
        route: props.route,
        item: itemPlusParents,
        startLine: lineStart - 1,
        endLine: lineEnd - 1,
      },
    });
  };
  // set state of json
  const setExampleLineNumbers = async () => {
    try {
      let selectedObject = Object.values(props.responseExamples)[0].value; // complete json response examples object
      const parentKeys = props.parentKeys.map((key) => {
        return key.split('-').join('_');
      });
      // find selected schema object or field in response examples object (because certain fields exist more than once in the object)
      parentKeys.forEach((key) => {
        if (selectedObject != null) {
          selectedObject = Array.isArray(selectedObject)
            ? selectedObject[0][key]
            : selectedObject[key];
        }
      });

      selectedObject = Array.isArray(selectedObject)
        ? selectedObject[0]
        : selectedObject;
      // selected item may not exist in response examples object
      if (
        selectedObject == null ||
        !Object.keys(selectedObject).includes(key)
      ) {
        setItemExistsInExample(false);
        setLineEnd(null);
        setLineStart(null);
        return;
      }
      if (props.responseExamples != null && props.type === 'response') {
        setItemExistsInExample(true);
      }
      const finalObject = selectedObject[key];
      // find line numbers of selected item
      const stringified = JSON.stringify({ [key]: finalObject }, null, 2);
      let finalSelectedString = stringified.slice(1, stringified.length - 1);
      finalSelectedString = finalSelectedString.trim();
      const numberOfFinalSelectedStringLines = finalSelectedString.split(
        /\r\n|\r|\n/,
      ).length;

      if (props.stringCode != null) {
        const stringCode = props.stringCode.replace(/ /g, '');
        finalSelectedString = finalSelectedString.replace(/ /g, '');

        let marker = 0;
        let slicedKeyLocation;
        // find specific location of selected item
        parentKeys.forEach((key) => {
          slicedKeyLocation =
            stringCode.slice(marker).indexOf(`"${key}":{`) !== -1
              ? stringCode.slice(marker).indexOf(`"${key}":{`)
              : stringCode.slice(marker).indexOf(`"${key}":[`);
          marker += slicedKeyLocation;
        });
        marker += stringCode.slice(marker).indexOf(finalSelectedString);

        const stringUpToMarker = stringCode.slice(0, marker);
        const lineStart = stringUpToMarker.split(/\r\n|\r|\n/).length;

        setLineStart(lineStart);
        setLineEnd(lineStart + numberOfFinalSelectedStringLines - 1);
      }
    } catch (err) {
      setItemExistsInExample(false);
      setLineStart(null);
      setLineEnd(null);
    }
  };

  const stickyHeaderRef = useRef(null);

  // This is a check to determine if our header is "stuck" based on its y
  // position. We do this so we can add the little bit of shadow
  // underneath the header, but there might be other fun things we could do
  // in the future.
  const checkStickinessBasedOnPosition = () => {
    const HEADER_MIN_HEIGHT = 36;
    const topMarginVal = props.topMargin ?? 96;
    const targetOffset = topMarginVal - HEADER_MIN_HEIGHT;
    const y = stickyHeaderRef.current.getBoundingClientRect().top;
    if (y <= myStickyHeight + targetOffset && y > 0 && !isStuck) {
      setIsStuck(true);
    } else if (isStuck && (y < 0 || y > myStickyHeight + topMarginVal)) {
      setIsStuck(false);
    }
  };

  useEffect(() => {
    if (
      isExpandable(schemaItem) &&
      stickyHeaderRef != null &&
      'IntersectionObserver' in window
    ) {
      checkStickinessBasedOnPosition();
      const container = document.querySelector('.docs-scroll-container');
      // With our IntersectionObserver, we can have items listen to onScroll
      // events only when they're on screen.
      const observer = new IntersectionObserver(
        ([e]) => {
          if (e.isIntersecting) {
            container.addEventListener(
              'scroll',
              checkStickinessBasedOnPosition,
            );
          } else {
            container.removeEventListener(
              'scroll',
              checkStickinessBasedOnPosition,
            );
          }
        },
        { threshold: [1] },
      );
      observer.observe(stickyHeaderRef.current);
      return () => {
        container.removeEventListener('scroll', checkStickinessBasedOnPosition);
        observer.disconnect();
      };
    }
  }, [schemaItem, stickyHeaderRef, myStickyHeight, isStuck]);

  useEffect(() => {
    setExampleLineNumbers();
  }, [setExampleLineNumbers]);

  // We have to determine exactly what our y position should be based on the
  // height of any parent headers that might also be sticky -- this is passed
  // down through the parentHeight prop.
  useEffect(() => {
    setMyStickyHeight(
      stickyHeaderRef.current.clientHeight + (props.parentHeight ?? 0),
    );
  }, [schemaItem, props.parentHeight]);

  const myIndentLevel = getIndentLevel(props.depth, props.maxDepth);
  // This is typically where our link anchors like to scroll to by default.
  // I still don't quite know where this value comes from.
  const DEFAULT_TOP_ANCHOR_MARGIN = 98;

  return (
    <div
      className={cx(styles.schemaRow, props.depth >= 2 && styles.noSeparator)}
    >
      <div
        className={cx(styles.contentRow)}
        data-algolia='schemaRowTd'
        style={{ '--indent-amount': `${myIndentLevel}rem` } as CSSProperties}
      >
        <header
          className={cx(
            styles.header,
            styles.stickyBit,
            isStuck && styles.stuck,
          )}
          ref={stickyHeaderRef}
          style={
            {
              '--top-position': `${props.parentHeight ?? 0}px`,
              '--sticky-z-index': 20 - props.depth * 2,
            } as CSSProperties
          }
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Link href={`#${id}`}>
            <a>
              <code
                className={styles.attributeKey}
                data-algolia='schemaRowAttributeKey'
              >
                <>{addWordBreaks(key)}</>
              </code>
              <span
                id={id}
                className={cx(styles.offsetAnchor, styles.scrollyBit)}
                style={
                  {
                    '--scroll-margin-amount': `${
                      (props.parentHeight ?? DEFAULT_TOP_ANCHOR_MARGIN) -
                      DEFAULT_TOP_ANCHOR_MARGIN
                    }px`,
                  } as CSSProperties
                }
                data-algolia='schemaRowAnchor'
              />
            </a>
          </Link>
          <div className={styles.attributesContainer}>
            {itemExistsInExample &&
              isHovered &&
              (selectedCode?.item === itemPlusParents ? (
                <Button
                  secondary
                  inline
                  onClick={showLine}
                  className={styles.viewCode}
                  centered
                  small
                  aria-label='view JSON'
                >
                  View all
                </Button>
              ) : isHovered ? (
                <Button
                  secondary
                  inline
                  onClick={showLine}
                  className={styles.viewCode}
                  centered
                  small
                  aria-label='view JSON'
                >
                  JSON
                </Button>
              ) : (
                <></>
              ))}
            <small className={styles.attributeType}>
              {props.required &&
                (props.type === 'request' ||
                  props.exchangeSpec != null ||
                  props.coreExchangeSpec != null) && (
                  <span className={cx(styles.label, styles.labelRequired)}>
                    required
                  </span>
                )}
              {schemaItem.deprecated && (
                <span className={cx(styles.label, styles.labelDeprecated)}>
                  deprecated
                </span>
              )}
              {schemaItem.nullable &&
                (props.type === 'response' || props.type === 'link') && (
                  <span className={styles.label}>nullable</span>
                )}
              <span className={styles.label}>
                {schemaItemSubItems != null && schemaItemSubItems.type != null
                  ? `[${schemaItemSubItems.type}]`
                  : schemaItem.type}
              </span>
            </small>
          </div>
        </header>
        <div className={cx(styles.attributes)}>
          {schemaItem.description && (
            <div
              className={styles.description}
              data-algolia='schemaRowDescription'
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: snarkdown(schemaItem.description),
                }}
              />

              <AdditionalProperties schemaItem={schemaItem} />
              {props.pathEntry && (
                <div>
                  In:
                  <code> {pathEntryIn}</code>
                </div>
              )}
            </div>
          )}

          {isExpandable(schemaItem) && key !== 'core_attributes' && (
            <Touchable
              onClick={() => setIsOpen(!isOpen)}
              className={styles.attributesLink}
            >
              {isOpen
                ? props.hideText || 'Hide object'
                : props.expandText
                ? `${props.expandText}…`
                : 'View object…'}

              {isOpen ? <ChevronS2Up /> : <ChevronS2Down />}
            </Touchable>
          )}
        </div>
        {isOpen && isExpandable(schemaItem) && key !== 'core_attributes' && (
          <div className={styles.nestedTable}>
            <div>
              {Object.entries(
                shouldRenderSchemaItemArrayMembers(schemaItem)
                  ? schemaItemSubItems.properties
                  : schemaItem.properties,
              )
                .filter((entry: SchemaObjectEntry) => {
                  return !(
                    (entry[1].hasOwnProperty('x-hidden-from-docs') &&
                      entry[1]['x-hidden-from-docs'] === true) ||
                    (entry[1].hasOwnProperty('x-hidden-behind-flag') &&
                      entry[1]['x-hidden-behind-flag'] !== props.unhideFlag)
                  );
                })
                .map((entry: SchemaObjectEntry, idx: number) => (
                  <SchemaRow
                    coreExchangeSpec={props.coreExchangeSpec}
                    type={props.type}
                    schemaName={props.schemaName}
                    expandAll={props.expandAll}
                    schemaEntry={entry}
                    responseExamples={props.responseExamples}
                    required={
                      schemaItem != null &&
                      (schemaItemSubItems?.required != null ||
                        schemaItem.required != null) &&
                      (props.type === 'request' || props.coreExchangeSpec) &&
                      (schemaItemSubItems?.required?.includes(entry[0]) ||
                        schemaItem.required?.includes(entry[0]))
                    }
                    stringCode={props.stringCode}
                    route={props.route}
                    key={idx}
                    maxDepth={props.maxDepth}
                    parentKeys={[
                      ...props.parentKeys,
                      props.schemaEntry[0].split('_').join('-'),
                    ]}
                    depth={props.depth + 1}
                    parentHeight={myStickyHeight}
                    topMargin={props.topMargin}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SchemaRow.displayName = 'SchemaRow';

export default SchemaRow;
