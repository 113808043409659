import { Partnerships as PartnershipsT } from '../../types';

import Header from './Header';
import Instructions from './Instructions.mdx';
import AuthInstructions from './AuthInstructions.mdx';
import IdentityInstructions from './IdentityInstructions.mdx';
import IdentityServer from './IdentityServer.mdx';
import AuthIdentityInstructions from './AuthIdentityInstructions.mdx';
import AuthSignalInstructions from './AuthSignalInstructions.mdx';
import TransactionsInstructions from './TransactionsInstructions.mdx';
import TransactionsServer from './TransactionsServer.mdx';
import TransactionsConclusion from './TransactionsConclusion.mdx';
import SardineInstructions from './SardineInstructions.mdx';
import AdyenInstructions from './AdyenInstructions.mdx';
import FinixInstructions from './FinixInstructions.mdx';

import Server from './Server.mdx';
import Conclusion from './Conclusion.mdx';
import GettingStarted from './GettingStarted';

export default {
  Header,
  Instructions,
  AuthInstructions,
  AuthIdentityInstructions,
  AuthSignalInstructions,
  IdentityInstructions,
  IdentityServer,
  TransactionsInstructions,
  TransactionsServer,
  TransactionsConclusion,
  SardineInstructions,
  AdyenInstructions,
  FinixInstructions,
  Server,
  Conclusion,
  GettingStarted,
};
