import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import TextArea from 'plaid-threads/TextArea';
import Button from 'plaid-threads/Button';
import InlineLink from 'plaid-threads/InlineLink';
import Checkmark from 'plaid-threads/Icons/Checkmark';

import Context from '../../../contexts/docs';

import styles from './Feedback.module.scss';

interface Props {
  source: string;
  name: string;
}

const Feedback: React.FC<Props> = (props: Props) => {
  const { dispatch, metadata, feedbackHelpful } = useContext(Context);
  const [text, setText] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [noResponse, setNoResponse] = useState(false);
  const [yesResponse, setYesResponse] = useState(false);
  const [placeholder, setPlaceholder] = useState('');
  const yesPlaceholder =
    "We're glad it was helpful. Please provide any additional comments you may have. " +
    "Note that we can't directly respond to this feedback; if you need help, submit a Support ticket.";
  const noPlaceholder =
    'How can we improve our documentation? ' +
    "Note that we can't directly respond to this feedback; if you need help, submit a Support ticket.";
  useEffect(() => {
    // Reset values back to null on page changes since this component persists and does not
    // re-render otherwise
    if (feedbackHelpful == null) {
      setText('');
      setSubmitted(false);
      setNoResponse(false);
      setShowTextArea(false);
      setYesResponse(false);
    }
  }, [feedbackHelpful]);

  // Feedback potentially fires two CSAT analytics events:
  // 1. On selection of 'Yes' or 'No' in response to 'Was this page helpful?'
  // 2. On submit of freeform feedback in response to 'How can we improve this page?'
  const handleSelect = (value) => {
    const payload = {
      helpful: value,
    };
    setShowTextArea(true);
    if (!value) {
      setNoResponse(true);
      setYesResponse(false);
      setPlaceholder(noPlaceholder);
    } else {
      setYesResponse(true);
      setNoResponse(false);
      setPlaceholder(yesPlaceholder);
    }

    dispatch({ type: 'DOCS_CSAT_FEEDBACK', payload });
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const payload = { text, source: props.name };
    dispatch({ type: 'DOCS_CSAT_FEEDBACK_ADDITIONAL', payload });
  };

  if (metadata == null || metadata.feedback == null) {
    return null;
  }

  return (
    <>
      <div className={styles.content} data-testid='docs-feedback'>
        {!submitted && (
          <div className={styles.feedback}>
            <h5 className={styles.title}>Was this helpful?</h5>
            <div className={styles.selectButtons}>
              <Button
                type='button'
                className={styles.selectButton}
                small
                inline
                secondary={!yesResponse}
                centered
                onClick={() => {
                  handleSelect(true);
                }}
              >
                {yesResponse && <Checkmark className={styles.checkmark} />}
                <span
                  className={cx(
                    styles.buttonText,
                    yesResponse && styles.buttonTextWithIcon,
                  )}
                >
                  {' '}
                  Yes
                </span>
              </Button>
              <Button
                type='button'
                className={cx(styles.selectButton, styles.selectButtonNo)}
                small
                inline
                secondary={!noResponse}
                centered
                onClick={() => {
                  handleSelect(false);
                }}
              >
                {noResponse && <Checkmark className={styles.checkmark} />}
                <span
                  className={cx(
                    styles.buttonText,
                    noResponse && styles.buttonTextWithIcon,
                  )}
                >
                  {' '}
                  No
                </span>
              </Button>
            </div>
          </div>
        )}

        <div className={styles.formContainer}>
          {!submitted && showTextArea && (
            <>
              <div className={styles.form}>
                <TextArea
                  className={styles.textArea}
                  id='feedback-input'
                  value={text}
                  label='Feedback (optional)'
                  placeholder={placeholder}
                  onChange={(e) => setText(e.currentTarget.value)}
                  minRows={6}
                />
                <div className={styles.submitButtons}>
                  <Button
                    className={styles.closeButton}
                    tertiary
                    onClick={() => {
                      setShowTextArea(false);
                      setText('');
                      setNoResponse(false);
                      setYesResponse(false);
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    small
                    disabled={!text}
                    centered
                    onClick={handleSubmit}
                  >
                    Submit feedback
                  </Button>
                </div>
              </div>
            </>
          )}
          {submitted && (
            <h5
              className={styles.title}
              id='thank-you-message'
              aria-live='polite'
            >
              Thank you! Your feedback will help us improve our documentation.
            </h5>
          )}
        </div>
        {noResponse && !submitted && props.source === 'footer' && (
          <p className={styles.consumerDeflection} id='enduserNote'>
            This documentation is for building apps with Plaid. If you're having
            trouble connecting your accounts to an app, we recommend contacting
            the app creator for help. For more information, visit our{' '}
            <a href='https://my.plaid.com/help' target='_blank'>
              consumer help center
            </a>
            .
          </p>
        )}
      </div>
    </>
  );
};

Feedback.displayName = 'Feedback';
export default Feedback;
