import { ErrorReference } from '../../types';

export const AUTH_PRODUCT_NOT_READY: ErrorReference = {
  server: {
    error_type: 'AUTH_ERROR',
    error_code: 'PRODUCT_NOT_READY',
    error_message:
      'the requested product is not yet ready. please provide a webhook or try the request again later',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

// TODO figure out if this is a webhook error or AUTH_ERROR
export const VERIFICATION_EXPIRED = {
  server: {},
  link: null,
};
