import { ErrorReference } from '../../types';

// Invalid Request errors are hard-coded at:
// https://github.plaid.com/plaid/go/blob/master/lib/apischemas/apierrors/invalid_request.go

export const MISSING_FIELDS: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'MISSING_FIELDS',
    error_message: 'the following required fields are missing: {fields}',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const UNKNOWN_FIELDS: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'UNKNOWN_FIELDS',
    error_message:
      'the following fields are not recognized by this endpoint: {fields}',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_CONFIGURATION: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'INVALID_CONFIGURATION',
    error_message: 'please ensure that the request body is formatted correctly',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_FIELD: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'INVALID_FIELD',
    error_message:
      '{{ error message is specific to the given / missing request field }}',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_BODY: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'INVALID_BODY',
    error_message: 'body could not be parsed as JSON',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_HEADERS: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'INVALID_HEADERS',
    error_message:
      '{{ error message is specific to the given / missing header }}',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'NOT_FOUND',
    error_message: 'not found',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_ACCOUNT_NUMBER: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'INVALID_ACCOUNT_NUMBER',
    error_message: 'The provided account number was invalid.',
    display_message: null,
    http_code: 400,
  },
  link: {
    link_error_heading: 'Account or routing number incorrect',
    link_error_icon: 'close',
    link_error_message:
      'Check with your bank and make sure that your account and routing numbers are entered correctly',
    link_error_button: 'Try again',
    error_code: 'INVALID_ACCOUNT_NUMBER',
  },
};

export const SANDBOX_ONLY: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'SANDBOX_ONLY',
    error_message:
      'access to {api/route} is only available in the sandbox environment at https://sandbox.plaid.com/',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const NO_LONGER_AVAILABLE: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'NO_LONGER_AVAILABLE',
    error_message:
      'This endpoint has been discontinued as of version {version-date} of the API.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INCOMPATIBLE_API_VERSION: ErrorReference = {
  server: {
    error_type: 'INVALID_REQUEST',
    error_code: 'INCOMPATIBLE_API_VERSION',
    error_message:
      'The public_key cannot be used for this endpoint as of version {version-date} of the API. Please use the client_id and secret instead.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
