import { ErrorReference } from '../../types';

export const TRANSACTIONS_SYNC_MUTATION_DURING_PAGINATION: ErrorReference = {
  server: {
    error_type: 'TRANSACTIONS_ERROR',
    error_code: 'TRANSACTIONS_SYNC_MUTATION_DURING_PAGINATION',
    error_message:
      'Underlying transaction data changed since last page was fetched. Please restart pagination from last update.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
