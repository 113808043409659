import { toCamelCase, capitalizeFirstLetter, formatComment } from './utils';

const javaEnumTypes = {
  products: 'Products',
  country_codes: 'CountryCode',
  additional_consented_products: 'Products',
  required_if_supported_products: 'Products',
  optional_products: 'Products',
  'account_filters.depository.account_subtypes': 'DepositoryAccountSubtype',
  'account_filters.credit.account_subtypes': 'CreditAccountSubtype',
  'income_verification.income_source_types': 'IncomeVerificationSourceType',
  'income_verification.payroll_income.flow_types':
    'IncomeVerificationPayrollFlowType',
};

const javaSingleValueEnums = {
  consumer_report_permissible_purpose: 'ConsumerReportPermissiblePurpose',
};

const javaEnumStripCommonPrefix = {
  // See https://github.com/swagger-api/swagger-codegen-generators/issues/246#issuecomment-442629121
  // for why we need this
  'income_verification.payroll_income.flow_types': 'PAYROLL_',
};

const javaTypes = {
  user: 'LinkTokenCreateRequestUser',
  account_filters: 'LinkTokenAccountFilters',
  'account_filters.depository': 'DepositoryFilter',
  'account_filters.credit': 'CreditFilter',

  auth: 'LinkTokenCreateRequestAuth',
  transactions: 'LinkTokenTransactions',
  payment_initiation: 'LinkTokenCreateRequestPaymentInitiation',
  statements: 'LinkTokenCreateRequestStatements',
  identity_verification: 'LinkTokenCreateRequestIdentityVerification',
  income_verification: 'LinkTokenCreateRequestIncomeVerification',
  'income_verification.bank_income':
    'LinkTokenCreateRequestIncomeVerificationBankIncome',
  'income_verification.payroll_income':
    'LinkTokenCreateRequestIncomeVerificationPayrollIncome',
  cra_options: 'LinkTokenCreateRequestCraOptions',
  'cra_options.base_report': 'LinkTokenCreateRequestCraOptionsBaseReport',
  'cra_options.partner_insights':
    'LinkTokenCreateRequestCraOptionsPartnerInsights',
};

const stringsThatShouldBeDates = [
  'statements.start_date',
  'statements.end_date',
];

// TODO: I'll want to put in the same indent fix as I did for the Java version,
// if or when I run across a use case that need it
const formatArray = (
  path,
  elements,
  maxElementsPerLine = 1,
  maxCharsPerLine = 50,
) => {
  const enumType = javaEnumTypes[path];
  const arrayElements = elements
    .map(
      (v) =>
        `${(javaEnumStripCommonPrefix[path]
          ? v.toUpperCase().replace(javaEnumStripCommonPrefix[path], '')
          : v.toUpperCase()
        ).replace(/ /g, '_')}`,
    )
    .map((v) => `${enumType ? enumType + '.' : ''}${v.toUpperCase()}`);
  if (
    elements.length <= maxElementsPerLine &&
    JSON.stringify(elements).length <= maxCharsPerLine
  ) {
    const valuesStr = arrayElements.join(', ');
    return `Arrays.asList(${valuesStr})`;
  } else {
    const valuesStr = arrayElements.join(',\n     ');
    return `Arrays.asList(\n     ${valuesStr}\n  )`;
  }
};

export const createJavaSampleCode = (config: object, comment: string) => {
  const indent = '  '; // Add one level of indentation to properties of LinkTokenCreateRequest

  const createdIntermediaries: any = {};

  const formatValue = (
    value: any,
    path: string,
    requireIntermediary: boolean = false,
  ) => {
    const typeName = javaTypes[path] || toCamelCase(path.split('.').pop());
    if (Array.isArray(value)) {
      return formatArray(path, value);
    } else if (typeof value === 'object' && javaTypes[path]) {
      const varName = createdIntermediaries[path];
      if (varName === undefined && requireIntermediary) {
        throw new Error(`No intermediary created for ${path}`);
      }
      return varName || '';
    } else if (typeof value === 'object') {
      // Capitalize the typeName
      const propsStr = Object.entries(value)
        .map(
          ([k, v]) => `.${toCamelCase(k)}(${formatValue(v, `${path}.${k}`)})`,
        )
        .join('\n  ');
      return `new ${capitalizeFirstLetter(typeName)}()\n  ${propsStr}`;
    } else if (javaSingleValueEnums[path]) {
      return `${javaSingleValueEnums[path]}.${value}`;
    } else if (typeof value === 'boolean') {
      return `${value}`;
    } else if (typeof value === 'number') {
      return `${value}`;
    } else if (stringsThatShouldBeDates.includes(path)) {
      return `LocalDate.parse("${value}")`;
    } else {
      return `"${value}"`;
    }
  };

  // Java (and Go) are complicated because in our sample code, we generally
  // construct intermediary types before we pass them in to the final config
  // object. And it gets further complicated because those intermediary types
  // can have their own intermediary types. Sigh.
  const createAndFormatIntermediaries = (obj: any, path: string = '') => {
    let declarations = [];
    let initializations = [];

    for (let [key, value] of Object.entries(obj)) {
      const fullPath = `${path}${key}`;

      // If the value is an object, we need to process its properties first.
      if (typeof value === 'object') {
        const [
          nestedDeclarations,
          nestedInitializations,
        ] = createAndFormatIntermediaries(value, `${fullPath}.`);
        declarations = [...declarations, ...nestedDeclarations];
        initializations = [...initializations, ...nestedInitializations];
      }

      // If the path exists in javaTypes, we need to create an intermediary.
      if (javaTypes[fullPath]) {
        const typeName = javaTypes[fullPath];
        const varName = toCamelCase(key);
        createdIntermediaries[fullPath] = varName;

        // We add the declaration to the declarations array.
        declarations.push(`${typeName} ${varName};`);

        // We need to format the intermediary initialization.
        const propsStr = Object.entries(value)
          .map(
            ([k, v]) =>
              `.${toCamelCase(k)}(${formatValue(v, `${fullPath}.${k}`)})`,
          )
          .join(`\n${indent}`);

        // We add the initialization to the initializations array.
        initializations.push(
          `${varName} = new ${capitalizeFirstLetter(
            typeName,
          )}()\n  ${propsStr};`,
        );
      }
    }

    return [declarations, initializations];
  };

  const combineDeclarationsAndInitializations = (
    declarations: string[],
    initializations: string[],
  ) => {
    return declarations
      .map((declaration, index) => {
        return `${declaration.split(';')[0]} = ${
          initializations[index].split('=')[1]
        }`;
      })
      .join('\n\n');
  };

  const [
    intermediaryDeclarations,
    intermediaryInitializations,
  ] = createAndFormatIntermediaries(config);

  const fullIntermediaryStr = combineDeclarationsAndInitializations(
    intermediaryDeclarations,
    intermediaryInitializations,
  );

  // Format the configuration object into Java code
  const configStr = Object.entries(config)
    .map(([key, value]) => {
      if (javaTypes[key]) {
        return `.${toCamelCase(key)}(${formatValue(value, key, true)})`;
      } else {
        return `.${toCamelCase(key)}(${formatValue(value, key, false)})`;
      }
    })
    .join(`\n${indent}`);

  // Combine everything into a single multiline string
  return `${formatComment(comment, '// ')}
${fullIntermediaryStr}

LinkTokenCreateRequest request = new LinkTokenCreateRequest()
${indent}${configStr};

Response<LinkTokenCreateResponse> response = client
${indent}.linkTokenCreate(request)
${indent}.execute();

String linkToken = response.body().getLinkToken();
`.trim();
};
