import React from 'react';

import StepWithDiagram from '../StepWithDiagram';

interface Props {}

const PaymentInitiationSetupFlow: React.FC<Props> = (props: Props) => (
  <>
    <StepWithDiagram
      step={1}
      asset='/assets/img/docs/payment_initiation/setup_flow/payment-setup-row-1.png'
    >
      Your app collects information of the sender and the recipient, as well as
      the payment amount.
    </StepWithDiagram>
    <StepWithDiagram
      step={2}
      asset='/assets/img/docs/payment_initiation/setup_flow/payment-setup-row-2.png'
    >
      With this information,{' '}
      <a href='/docs/api/products/payment-initiation/#payment_initiationrecipientcreate'>
        <b>create a recipient</b>
      </a>{' '}
      and obtain a <code>recipient_id</code>. You can reuse this{' '}
      <code>recipient_id</code> for future payments.
    </StepWithDiagram>
    <StepWithDiagram
      step={3}
      asset='/assets/img/docs/payment_initiation/setup_flow/payment-setup-row-3.png'
    >
      Provide the <code>recipient_id</code> to Plaid when you{' '}
      <a href='/docs/api/products/payment-initiation/#payment_initiationpaymentcreate'>
        <b>create a payment</b>
      </a>
      . Store the resulting <code>payment_id</code> along with your payment
      metadata.
    </StepWithDiagram>
    <StepWithDiagram
      step={4}
      asset='/assets/img/docs/payment_initiation/setup_flow/payment-setup-row-4.png'
    >
      From your backend, use the <code>payment_id</code> to{' '}
      <a href='/docs/api/link/#linktokencreate'>
        <b>create a link_token</b>
      </a>
      .
    </StepWithDiagram>
    <StepWithDiagram
      step={5}
      asset='/assets/img/docs/payment_initiation/setup_flow/payment-setup-row-5.png'
    >
      Finally, pass the <code>link_token</code> to your client app to{' '}
      <a href='/docs/link/#initializing-link'>
        <b>initialize Link</b>
      </a>
      .
    </StepWithDiagram>
  </>
);

PaymentInitiationSetupFlow.displayName = 'PaymentInitiationSetupFlow';

export default PaymentInitiationSetupFlow;
