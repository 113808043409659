import React, { useRef, useState, useEffect, LegacyRef } from 'react';
import ChevronS2Up from 'plaid-threads/Icons/ChevronS2Up';
import ChevronS2Down from 'plaid-threads/Icons/ChevronS2Down';
import Touchable from 'plaid-threads/Touchable';
import cx from 'classnames';
import { SchemaObject, ParameterObject } from 'openapi3-ts';

import Layout from '../Layout';

import Feedback from '../Feedback';
import { SchemaObjectEntry } from './index';
import Table from './Table';

import styles from './index.module.scss';

interface Props {
  schemaName: string;
  route: string;
  entries: Array<SchemaObjectEntry>;
  pathEntries?: Array<ParameterObject>;
  children?: React.ReactNode;
  tableHeader?: React.ReactNode;
  description?: React.ReactNode;
  required?: Array<string>;
  expandAll: boolean;
  tableOnly?: boolean;
  simplify?: boolean;
  expandText?: string;
  hideText?: string;
  unhideFlag?: string;
  type?: string;
  isRequest?: boolean;
  responseExamples?: SchemaObject['examples'];
  stringCode?: string;
  exchangeSpec?: string;
  coreExchangeSpec?: string;
}

interface HeaderProps extends Props {
  expandAll: boolean;
  setExpandAll: (boolean) => void;
  showToggle: boolean;
}

const Header = React.forwardRef<HTMLDivElement, HeaderProps>((props, ref) => (
  <div
    ref={ref}
    className={cx(
      styles.tableHeaderWrapper,
      props.tableOnly && styles.tableHeaderWrapperNoPadding,
      styles.stickyHeader,
    )}
  >
    <div
      className={cx(
        styles.tableHeaderButtonWrapper,
        props.tableHeader == null && styles.noHeader,
      )}
    >
      {props.tableHeader}
      {props.showToggle && (
        <Touchable
          className={styles.expandAllButton}
          onClick={() => props.setExpandAll(!props.expandAll)}
        >
          {props.expandAll ? 'Collapse all' : 'Expand all'}

          {props.expandAll ? <ChevronS2Up /> : <ChevronS2Down />}
        </Touchable>
      )}
    </div>
  </div>
));

const SchemaLayout: React.FC<Props> = (props: Props) => {
  const [expandAll, setExpandAll] = React.useState(Boolean(props.expandAll));
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, []);

  let showToggle = false;

  for (const entry of props.entries) {
    const items: SchemaObject = entry[1].items;
    if (entry[1].properties != null) {
      showToggle = true;
    } else if (items != null) {
      if (items.properties != null) {
        showToggle = true;
      }
    }
  }
  const name = `endpoint: ${props.schemaName}`;
  return (
    <div
      className={cx(styles.schema, props.isRequest && styles.request)}
      data-algolia='schema'
    >
      {props.description}
      <span
        className={styles.schemaRouteHidden}
        data-algolia='schemaRouteHidden'
      >
        {props.route}
      </span>
      {props.tableOnly || props.simplify ? (
        <div className={styles.layoutWrapper}>
          <div>
            <Header
              {...props}
              expandAll={expandAll}
              setExpandAll={setExpandAll}
              showToggle={showToggle}
            />
            <Table
              exchangeSpec={props.exchangeSpec}
              coreExchangeSpec={props.coreExchangeSpec}
              schemaName={props.schemaName}
              entries={props.entries}
              pathEntries={props.pathEntries}
              required={props.required}
              expandAll={expandAll}
              expandText={props.expandText}
              hideText={props.hideText}
              unhideFlag={props.unhideFlag}
              simplify={props.simplify}
              type={props.type}
              responseExamples={props.responseExamples}
              stringCode={props.stringCode}
              route={props.route}
            />
          </div>
        </div>
      ) : (
        <Layout.SideBySide className={styles.layoutWrapper}>
          <div>
            <Header
              ref={headerRef}
              {...props}
              expandAll={expandAll}
              setExpandAll={setExpandAll}
              showToggle={showToggle}
            />
            <Table
              schemaName={props.schemaName}
              entries={props.entries}
              pathEntries={props.pathEntries}
              required={props.required}
              expandAll={expandAll}
              exchangeSpec={props.exchangeSpec}
              coreExchangeSpec={props.coreExchangeSpec}
              expandText={props.expandText}
              hideText={props.hideText}
              unhideFlag={props.unhideFlag}
              type={props.type}
              responseExamples={props.responseExamples}
              stringCode={props.stringCode}
              route={props.route}
              headerHeight={headerHeight}
            />
          </div>

          <div className={styles.codeBlock}>{props.children}</div>
        </Layout.SideBySide>
      )}

      {props.type === 'response' && <Feedback name={name} source='endpooint' />}
      {props.type === 'model' && <Feedback name={name} source='webhook' />}
    </div>
  );
};

SchemaLayout.displayName = 'SchemaLayout';

export default SchemaLayout;
