
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <MultiCodeBlock type='server' mdxType="MultiCodeBlock">
      <pre><code parentName="pre" {...{
          "className": "language-node",
          "metastring": "#server.js",
          "#server.js": true
        }}>{`// Using Express
const express = require('express');
const app = express();
app.use(express.json());

const { Configuration, PlaidApi, PlaidEnvironments } = require('plaid');

const configuration = new Configuration({
  basePath: PlaidEnvironments.sandbox,
  baseOptions: {
    headers: {
      'PLAID-CLIENT-ID': process.env.PLAID_CLIENT_ID,
      'PLAID-SECRET': process.env.PLAID_SECRET,
    },
  },
});

const client = new PlaidApi(configuration);
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`## Not applicable with curl calls
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-ruby",
          "metastring": "#server.rb",
          "#server.rb": true
        }}>{`require 'sinatra'
require 'plaid'

set :port, ENV['APP_PORT'] || 8000

configuration = Plaid::Configuration.new
configuration.server_index = Plaid::Configuration::Environment[ENV['PLAID_ENV'] || 'sandbox']
configuration.api_key['PLAID-CLIENT-ID'] =  ENV['PLAID_CLIENT_ID']
configuration.api_key['PLAID-SECRET'] = ENV['PLAID_SECRET']

api_client = Plaid::ApiClient.new(
  configuration
)

client = Plaid::PlaidApi.new(api_client)
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-java",
          "metastring": "#Server.java",
          "#Server.java": true
        }}>{`import java.net.*;
import java.io.*;
import retrofit2.Response;
import java.util.Arrays;
import com.sun.net.httpserver.*;

import com.plaid.client.ApiClient;
import com.plaid.client.request.PlaidApi;

public class PlaidExample {
  private static final String CLIENT_ID = System.getenv("PLAID_CLIENT_ID");
  private static final String SECRET = System.getenv("PLAID_SECRET");

  public static void main(String[] args) {
    HttpServer server = HttpServer.create(
      new InetSocketAddress("localhost", 8000), 0);
    server.createContext("/create_link_token", new GetLinkToken());
    server.setExecutor(null);
    server.start();
  }

  // Additional server code goes here

}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-python",
          "metastring": "#server.py",
          "#server.py": true
        }}>{`import plaid
from plaid.api import plaid_api

from flask import Flask
from flask import render_template
from flask import request
from flask import jsonify

app = Flask(name)

configuration = plaid.Configuration(
  host=plaid.Environment.Sandbox,
  api_key={
    'clientId': PLAID_CLIENT_ID,
    'secret': PLAID_SECRET,
  }
)

api_client = plaid.ApiClient(configuration)
client = plaid_api.PlaidApi(api_client)

# Additional server code goes here

if __name__ == "__main__":
    app.run(port=8000)
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-go",
          "metastring": "#server.go",
          "#server.go": true
        }}>{`import (
    "context"
    "net/http"
    "os"

    "github.com/gin-gonic/gin"
    "github.com/plaid/plaid-go/v3/plaid"
)


configuration := plaid.NewConfiguration()
configuration.AddDefaultHeader("PLAID-CLIENT-ID", os.Getenv("CLIENT_ID"))
configuration.AddDefaultHeader("PLAID-SECRET", os.Getenv("SECRET"))
configuration.UseEnvironment(plaid.Sandbox)
client := plaid.NewAPIClient(configuration)


func main() {
  r := gin.Default()
  // Server endpoints would be declared here
  // e.g.  r.POST("/create_link_token", createLinkToken)
 r.POST("/create_link_token", createLinkToken)

  err := r.Run(":8000")
  if err != nil {
    panic("unable to start server")
  }
}
`}</code></pre>
    </MultiCodeBlock>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
