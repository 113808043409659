import React from 'react';
import cx from 'classnames';

import FullWidth from './FullWidth';

import styles from './SideBySide.module.scss';

interface Props {
  children: Array<React.ReactNode>;
  className?: string;
}

const SideBySide: React.FC<Props> = (props: Props) => (
  <FullWidth>
    <div className={cx(styles.wrapper, props.className)}>
      <div className={styles.side} key={1}>
        {props.children[0]}
      </div>
      <div className={styles.side} key={2}>
        {props.children[1]}
      </div>
    </div>
  </FullWidth>
);

SideBySide.displayName = 'SideBySide';

export default SideBySide;
