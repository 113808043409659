import React from 'react';
import cx from 'classnames';

import FullWidth from './FullWidth';

import styles from './Header.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  withSeparator?: boolean;
}

const Header: React.FC<Props> = (props: Props) => (
  <FullWidth
    className={cx(styles.header, props.className)}
    withSeparator={props.withSeparator}
  >
    <div className={styles.hero}>{props.children}</div>
  </FullWidth>
);

Header.displayName = 'Header';

export default Header;
