import React, { FC, useEffect, useState } from 'react';
import styles from './YouTubeEmbed.module.scss';

interface Props {
  videoId: string;
  caption?: string;
  title?: string;
  alignment?: 'full' | 'left' | 'right';
}

/**
 * A simple way of embedding YouTube videos in our documentation. Note that if
 * you choose 'left' or 'right' alignment and want tsx text to flow around the
 * video, you'll need to place the text and the video inside the same <div> or
 * <Callout>
 */
const YouTubeEmbed: FC<Props> = ({
  videoId,
  caption = '',
  title = 'YouTube video',
  alignment = 'full',
}) => {
  const DEFAULT_FULL_WIDTH = 560;
  const DEFAULT_SMALL_WIDTH = 290;
  const ASPECT_RATIO = 0.5625;

  const [vidWidth, setVidWidth] = useState(
    alignment === 'full' ? DEFAULT_FULL_WIDTH : DEFAULT_SMALL_WIDTH,
  );

  const alignmentToStyle = {
    full: styles.fullVideo,
    left: styles.leftVideo,
    right: styles.rightVideo,
  };
  const vidHeight = vidWidth * ASPECT_RATIO;
  const divStyle = alignmentToStyle[alignment];

  const handleResize = () => {
    setVidWidth(
      Math.min(
        alignment === 'full' ? DEFAULT_FULL_WIDTH : DEFAULT_SMALL_WIDTH,
        document.querySelector(`#vidFrame`).clientWidth,
      ),
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div id='vidFrame' className={divStyle} style={{ width: 'min-content' }}>
        <iframe
          width={vidWidth.toFixed(0)}
          height={vidHeight.toFixed(0)}
          title={`${title}`}
          src={`https://youtube.com/embed/${videoId}`}
          allow='accelerometer; autoplay; clipboard-write;  encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          data-testid='youtube-embed'
        ></iframe>
        {caption && (
          <div
            className={styles.youTubeCaption}
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        )}
      </div>
    </>
  );
};

export default YouTubeEmbed;
