import { NavItem, NavGroup } from '../../docs/constants/routeMap';

import Guide from 'plaid-threads/Icons/Guide';
import Help from 'plaid-threads/Icons/Help';
import Home from 'plaid-threads/Icons/Home';
import Changelog from 'plaid-threads/Icons/Changelog';
import Terminal from 'plaid-threads/Icons/Terminal';
import Book from 'plaid-threads/Icons/Book';
import Libraries from 'plaid-threads/Icons/Libraries';
import Key from 'plaid-threads/Icons/Key';

export const API_OVERVIEW: NavGroup = [
  {
    children: [
      {
        title: 'API Overview',
        path: '/overview',
      },
    ],
  },
  {
    children: [
      {
        title: 'Authentication Flow',
        path: '/overview/authentication-and-authorization-flow',
      },
      {
        title: 'Aggregation Flow',
        path: '/overview/aggregation-flow',
      },
    ],
  },
];

export const API_REFERENCE: NavGroup = [
  {
    children: [
      {
        title: 'Data Definitions',
        path: '/reference/data-definitions',
      },
      {
        title: 'Aggregation',
        path: '/reference/aggregation',
      },
      {
        title: 'Errors and Conditions',
        path: '/reference/errors-and-conditions',
      },
    ],
  },
];

export const EXPERIENCE: NavGroup = [
  {
    children: [
      {
        title: 'User Experience',
        path: '/user-experience',
        icon: Key,
        highlight: true,
      },
    ],
  },
];

export const AUTH: NavGroup = [
  {
    children: [
      {
        title: 'Authentication',
        path: '/authentication',
        icon: Key,
        highlight: true,
      },
    ],
  },
];

const SUPPORT: NavGroup = [
  {
    children: [{ title: 'Overview', path: '/support' }],
  },
];

export const ROUTES: NavGroup = [
  {
    subNav: false,
    highlight: false,
    children: [
      { title: 'Home', path: '/', icon: Home, highlight: true, subNav: false },
      {
        title: 'Implementation guide',
        path: '/implementation-guide',
        icon: Guide,
        highlight: true,
      },
      {
        title: 'User Experience',
        path: '/user-experience',
        icon: Guide,
        children: EXPERIENCE,
      },
      {
        title: 'Authentication',
        path: '/authentication',
        icon: Key,
        children: AUTH,
      },
      {
        title: 'API reference',
        path: '/reference',
        icon: Terminal,
        children: API_REFERENCE,
      },
      {
        title: 'Changelog',
        path: '/changelog',
        icon: Changelog,
        highlight: true,
      },
    ],
  },
];

// everything
export const ROUTE_MAP: NavGroup = [...ROUTES];

const flattenNavGroup = (group: NavGroup): Array<NavItem> =>
  group.reduce((acc, item) => {
    const res: Array<NavItem> = [];
    // if this item has properties,
    // push item to results
    if (item.path != null && item.title != null) {
      res.push(item);
    }
    // process children if they exist
    if (item.children != null) {
      res.push(...flattenNavGroup(item.children));
    }
    return acc.concat(res);
  }, []);

export const ROUTE_MAP_FLAT = flattenNavGroup(ROUTE_MAP);
