import { ErrorReference } from '../../types';

// Income Verification errors are hard-coded at:
// https://github.plaid.com/plaid/go/blob/master/lib/apischemas/apierrors/income_verification_error.go

export const INCOME_VERIFICATION_DOCUMENT_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'INCOME_VERIFICATION_ERROR',
    error_code: 'INCOME_VERIFICATION_DOCUMENT_NOT_FOUND',
    error_message:
      'the requested data was not found. Please check the ID supplied.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INCOME_VERIFICATION_FAILED: ErrorReference = {
  server: {
    error_type: 'INCOME_VERIFICATION_ERROR',
    error_code: 'INCOME_VERIFICATION_FAILED',
    error_message:
      'the income verification you are trying to retrieve could not be completed. please try creating a new income verification',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INCOME_VERIFICATION_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'INCOME_VERIFICATION_ERROR',
    error_code: 'INCOME_VERIFICATION_NOT_FOUND',
    error_message:
      'the requested data was not found. Please check the ID supplied.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INCOME_VERIFICATION_UPLOAD_ERROR: ErrorReference = {
  server: {
    error_type: 'INCOME_VERIFICATION_ERROR',
    error_code: 'INCOME_VERIFICATION_UPLOAD_ERROR',
    error_message:
      'there was a problem uploading the document for verification. Please try again or recreate an income verification.',
    display_message: null,
    http_code: 500,
  },
  link: null,
};

export const INCOME_VERIFICATION_PRODUCT_NOT_ENABLED: ErrorReference = {
  server: {
    error_type: 'INCOME_VERIFICATION_ERROR',
    error_code: 'PRODUCT_NOT_ENABLED',
    error_message:
      "the 'income_verification' product is not enabled for the following client ID: <CLIENT_ID>. please ensure that the 'income_verification' is included in the 'product' array when initializing Link and try again.",
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INCOME_VERIFICATION_PRODUCT_NOT_READY: ErrorReference = {
  server: {
    error_type: 'INCOME_VERIFICATION_ERROR',
    error_code: 'PRODUCT_NOT_READY',
    error_message:
      'the requested product is not yet ready. please provide a webhook or try the request again later',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const VERIFICATION_STATUS_PENDING_APPROVAL: ErrorReference = {
  server: {
    error_type: 'INCOME_VERIFICATION_ERROR',
    error_code: 'VERIFICATION_STATUS_PENDING_APPROVAL',
    error_message: 'The user has not yet authorized the sharing of this data',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const EMPLOYMENT_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'INCOME_VERIFICATION_ERROR',
    error_code: 'EMPLOYMENT_NOT_FOUND',
    error_message:
      'the requested employment data was not found. Please check the ID supplied.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
