import React from 'react';
import Layout from '../Layout';
import styles from './index.module.scss';

interface Props {
  title: string;
  subtitle: string;
  subsubtitle?: string;
  compact?: boolean;
  bottomBorder?: boolean;
}
const Header: React.FC<Props> = (props: Props) => (
  <>
    <Layout.Header
      className={props.compact ? styles.compactWrapper : styles.wrapper}
      withSeparator={props.bottomBorder}
    >
      <h1 className={styles.title}>{props.title}</h1>
      {props.subtitle != null && <h4>{props.subtitle}</h4>}
      {props.subsubtitle != null && <p>{props.subsubtitle}</p>}
      {!props.compact && <Layout.Spacer units={4} />}
    </Layout.Header>
  </>
);

Header.displayName = 'Header';

export default Header;
