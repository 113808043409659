import React from 'react';

import HeaderRef from '../../Header';
import Image from '../../Image';
import { PartnerProps } from '../../types';

const Header: React.FC<PartnerProps> = ({
  partner,
  imagePartnerName,
  subtitle = `Use ${partner} with Plaid Auth to send and receive payments`,
}) => {
  const partnerImage = (imagePartnerName ?? partner)
    .toLowerCase()
    .replace('.', ' ')
    .split(' ')
    .join('-');
  return (
    <>
      <HeaderRef title={`Add ${partner} to your app`} subtitle={subtitle} />

      <Image
        src={`/assets/img/docs/plaid-${partnerImage}-partnership.png`}
        width='624'
        alt={`Partnership ${partner} logo`}
      />
      <br />
    </>
  );
};

export default Header;
