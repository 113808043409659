import { ErrorReference } from '../../types';

// Partner errors are hard-coded at:
// https://github.plaid.com/plaid/go/blob/master/lib/apischemas/apierrors/partner_error.go

export const CUSTOMER_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'CUSTOMER_NOT_FOUND',
    error_message: 'the customer was not found',
    display_message: null,
    http_code: 404,
  },
  link: null,
};

export const FLOWDOWN_NOT_COMPLETE: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'FLOWDOWN_NOT_COMPLETE',
    error_message:
      'you must complete a flowdown to create and enable customers',
    display_message: null,
    http_code: 412,
  },
  link: null,
};

export const QUESTIONNAIRE_NOT_COMPLETE: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'QUESTIONNAIRE_NOT_COMPLETE',
    error_message:
      'you must complete the security questionnaire to create and enable customers',
    display_message: null,
    http_code: 412,
  },
  link: null,
};

export const CUSTOMER_NOT_READY_FOR_ENABLEMENT: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'CUSTOMER_NOT_READY_FOR_ENABLEMENT',
    error_message: 'this customer is not ready for enablement',
    display_message: null,
    http_code: 412,
  },
  link: null,
};

export const CUSTOMER_ALREADY_ENABLED: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'CUSTOMER_ALREADY_ENABLED',
    error_message: 'this customer has already been enabled',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const CUSTOMER_ALREADY_CREATED: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'CUSTOMER_ALREADY_CREATED',
    error_message: 'this customer has already been created',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const LOGO_REQUIRED: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'LOGO_REQUIRED',
    error_message:
      'a logo is required because create_link_customization is set to true and the co-branded consent pane is in use',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INVALID_LOGO: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'INVALID_LOGO',
    error_message:
      'the logo must be a base64-encoded string of a PNG of size 1024x1024',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const CONTACT_REQUIRED: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'CONTACT_REQUIRED',
    error_message:
      'billing or technical contacts must be submitted either in the request or filled in for your team in the dashboard',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const ASSETS_UNDER_MANAGEMENT_REQUIRED: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'ASSETS_UNDER_MANAGEMENT_REQUIRED',
    error_message:
      'assets under management must be submitted because your team has a monthly service commitment',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const CUSTOMER_REMOVAL_NOT_ALLOWED: ErrorReference = {
  server: {
    error_type: 'PARTNER_ERROR',
    error_code: 'CUSTOMER_REMOVAL_NOT_ALLOWED',
    error_message:
      'removal of a production-enabled end customer is not allowed. talk to your account manager to remove this end customer.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
