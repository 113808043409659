import Card from './Card';
import DoubleCard from './DoubleCard';
import Wrapper from './Wrapper';
import ProductCard from './ProductCard';
import MediaCard from './MediaCard';
import MediaCardGrid from './MediaCardGrid';

export default {
  Card,
  DoubleCard,
  Wrapper,
  ProductCard,
  MediaCard,
  MediaCardGrid,
};
