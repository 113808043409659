import { ErrorReference } from '../../types';

export const TRANSFER_LIMIT_REACHED: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'TRANSFER_LIMIT_REACHED',
    error_message: 'transfer limit exceeded',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const PAYMENT_PROFILE_NOT_READY: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'PAYMENT_PROFILE_NOT_READY',
    error_message: 'this payment profile is not ready to be used',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSFER_UI_UNAUTHORIZED: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'TRANSFER_UI_UNAUTHORIZED',
    error_message: 'client is not authorized for transfer UI',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSFER_ACCOUNT_BLOCKED: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'TRANSFER_ACCOUNT_BLOCKED',
    error_message:
      'transfer was blocked due to a previous ACH return on this account',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSFER_NOT_CANCELLABLE: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'TRANSFER_NOT_CANCELLABLE',
    error_message: 'transfer is not cancellable',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSFER_UNSUPPORTED_ACCOUNT_TYPE: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'TRANSFER_UNSUPPORTED_ACCOUNT_TYPE',
    error_message: 'transfer account type not supported',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSFER_FORBIDDEN_ACH_CLASS: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'TRANSFER_FORBIDDEN_ACH_CLASS',
    error_message: 'specified ach_class is forbidden',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const TRANSFER_ORIGINATOR_NOT_FOUND: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'TRANSFER_ORIGINATOR_NOT_FOUND',
    error_message:
      "the association between the sender and the originator client doesn't exist",
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INCOMPLETE_CUSTOMER_ONBOARDING: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'INCOMPLETE_CUSTOMER_ONBOARDING',
    error_message:
      'end customer has not completed onboarding. their diligence status must be `approved` before moving funds',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const UNAUTHORIZED_ACCESS: ErrorReference = {
  server: {
    error_type: 'TRANSFER_ERROR',
    error_code: 'UNAUTHORIZED_ACCESS',
    error_message:
      'you are not authorized to access this endpoint for your use case.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
