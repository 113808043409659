import React from 'react';
import { SchemaObject, ParameterObject } from 'openapi3-ts';
import { SchemaObjectEntry } from './index';
import SchemaRow from './SchemaRow';
import EmptySchemaRow from './EmptySchemaRow';

import styles from './Table.module.scss';
import { getSchemaDepth } from './schemaUtils';
import ChatbotFriendlyRow from './ChatbotFriendlyRow';

interface Props {
  schemaName: string;
  entries: Array<SchemaObjectEntry>;
  pathEntries: Array<ParameterObject>;
  required?: Array<string>;
  expandAll: boolean;
  expandText?: string;
  hideText?: string;
  simplify?: boolean;
  unhideFlag?: string;
  type?: string;
  responseExamples?: SchemaObject['examples'];
  stringCode?: string;
  route?: string;
  exchangeSpec?: string;
  coreExchangeSpec?: string;
  headerHeight?: number;
}

const Table: React.FC<Props> = (props: Props) => {
  const [topMarginVal, setTopMarginVal] = React.useState(96);

  const visibleEntries = props.entries.filter((entry: SchemaObjectEntry) => {
    return !(
      (entry[1].hasOwnProperty('x-hidden-from-docs') &&
        entry[1]['x-hidden-from-docs'] === true) ||
      (entry[1].hasOwnProperty('x-hidden-behind-flag') &&
        entry[1]['x-hidden-behind-flag'] !== props.unhideFlag)
    );
  });
  const maxTableDepth = Math.max(
    -1,
    ...visibleEntries.map((entry: SchemaObjectEntry) =>
      getSchemaDepth(entry[1]),
    ),
  );
  let pathEntries = props.pathEntries || [];

  React.useEffect(() => {
    // Figure out the "top" of our scroll container. This should be 96,
    // but calculating it like this seemed better than hard-coding it.
    const container = document.querySelector('.docs-scroll-container');
    if (container == null) {
      return;
    }
    const topMargin = parseInt(getComputedStyle(container).marginTop);
    if (Number.isNaN(topMargin)) {
      return;
    }
    setTopMarginVal(topMargin);
  }, []);

  // Will need to refactor below if we ever have endpoints that take path params AND body params
  return (
    <div className={styles.wrapper}>
      <div className={styles.schemaTable}>
        <div className={styles.body}>
          {visibleEntries.length === 0 && pathEntries.length === 0 ? (
            <EmptySchemaRow />
          ) : pathEntries.length !== 0 ? (
            props.pathEntries.map((pathEntry) => {
              return (
                <SchemaRow
                  schemaName={props.schemaName}
                  depth={0}
                  schemaEntry={['', {}]}
                  pathEntry={pathEntry}
                  maxDepth={maxTableDepth}
                  expandAll={props.expandAll}
                  expandText={props.expandText}
                  hideText={props.hideText}
                  unhideFlag={props.unhideFlag}
                  type={props.type}
                  parentKeys={[]}
                  responseExamples={props.responseExamples}
                  stringCode={props.stringCode}
                  route={props.route}
                  topMargin={topMarginVal}
                  parentHeight={props.headerHeight}
                />
              );
            })
          ) : (
            visibleEntries.map((schemaEntry: SchemaObjectEntry) => {
              const key = schemaEntry[0];
              return props.simplify ? (
                <ChatbotFriendlyRow
                  schemaName={props.schemaName}
                  schemaEntry={schemaEntry}
                  key={key}
                  required={props.required && props.required.includes(key)}
                  type={props.type}
                  parentKeys={[]}
                  route={props.route}
                  exchangeSpec={props.exchangeSpec}
                  coreExchangeSpec={props.coreExchangeSpec}
                />
              ) : (
                <SchemaRow
                  schemaName={props.schemaName}
                  depth={0}
                  schemaEntry={schemaEntry}
                  maxDepth={maxTableDepth}
                  key={key}
                  required={props.required && props.required.includes(key)}
                  expandAll={props.expandAll}
                  expandText={props.expandText}
                  hideText={props.hideText}
                  unhideFlag={props.unhideFlag}
                  type={props.type}
                  parentKeys={[]}
                  responseExamples={props.responseExamples}
                  stringCode={props.stringCode}
                  route={props.route}
                  exchangeSpec={props.exchangeSpec}
                  coreExchangeSpec={props.coreExchangeSpec}
                  topMargin={topMarginVal}
                  parentHeight={props.headerHeight}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

Table.displayName = 'Table';

export default Table;
