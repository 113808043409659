import React from 'react';

interface OptionalTextProps {
  text?: string;
}

const OptionalText: React.FC<OptionalTextProps> = (
  props: OptionalTextProps,
) => <>{props.text}</>;

export default OptionalText;
