import React from 'react';
import { OpenAPIObject } from 'openapi3-ts';

import directV1 from './directV1.yml';

export interface ExchangeOpenApiSchemas {
  aggregator: OpenAPIObject;
}

const schemas: ExchangeOpenApiSchemas = {
  aggregator: (directV1 as unknown) as OpenAPIObject, //In the future we will want the option to swap this OAS with FDX
};

const OpenAPIExchangeContext = React.createContext<ExchangeOpenApiSchemas>(
  schemas,
);

export const { Consumer, Provider } = OpenAPIExchangeContext;

export const ExchangeOpenAPIProvider: React.FC = (props) => (
  <Provider value={schemas}>{props.children}</Provider>
);

export default OpenAPIExchangeContext;
