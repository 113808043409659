import React, { useContext, useEffect } from 'react';
import Link from 'next/link';
import mergeAllOf from '@stoplight/json-schema-merge-allof';
import snarkdown from 'snarkdown';
import {
  OpenAPIObject,
  PathItemObject,
  SchemaObject,
  isSchemaObject,
  ParameterObject,
  isReferenceObject,
  ReferenceObject,
} from 'openapi3-ts';

import { ClientPlatform } from '../types';
import { replaceApiRouteLinks } from '../utilities';
import OpenAPIContext from '../../../contexts/docs/openapi';
import OpenAPILinkContext from '../../../contexts/docs/openapi/link';
import Context from '../../../contexts/docs';
import CodeBlock from '../CodeBlock';
import SchemaLayout from './SchemaLayout';

import styles from './index.module.scss';
import OpenAPIExchangeContext from 'src/contexts/docs/openapi/exchange';
import OpenAPICoreExchangeContext from 'src/contexts/docs/openapi/core-exchange';

interface BaseProps {
  children?: React.ReactNode;
  collapse?: boolean;
  expandText?: string;
  hideText?: string;
  unhideFlag?: string;
  exampleIndex?: number;
  tableOnly?: boolean;
  exampleTitle?: string;
  exchangeSpec?: 'aggregator';
  coreExchangeSpec?: '6.1';
}

interface ModelProps extends BaseProps {
  type: 'model';
  model: string;
}

interface RouteProps extends BaseProps {
  type: 'request' | 'response';
  route: string;
  method?: 'GET' | 'POST';
}

interface LinkProps extends BaseProps {
  type: 'link';
  sdk: ClientPlatform;
  method?: string;
}

export type Props = RouteProps | ModelProps | LinkProps;

export type SchemaObjectEntry = [string, SchemaObject];

const getFormattedExample = (
  examples: SchemaObject['examples'],
  exampleIndex: number = 0,
): string => {
  const exampleValues = Object.values(examples);
  if (exampleValues.length === 0) {
    return '';
  }
  if (exampleIndex >= exampleValues.length) {
    // eslint-disable-next-line no-console
    console.warn(`Schema: index ${exampleIndex} is out of range.`);
    exampleIndex = 0;
  }

  const targetExample = exampleValues[exampleIndex];
  if (
    !!targetExample &&
    Object.keys(targetExample).length === 1 &&
    !!targetExample?.value
  ) {
    return JSON.stringify(targetExample.value, null, 2); // format JSON with 2 space indentation
  }

  return JSON.stringify(targetExample, null, 2); // format JSON with 2 space indentation
};

const Schema: React.FC<Props> = (props: Props) => {
  const { selectedCode } = useContext(Context);
  const [simplifyForBill, setSimplifyForBill] = React.useState(false);
  let definition: OpenAPIObject;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setSimplifyForBill(params.get('forBill') === 'true');
  }, []);

  const pxSchemas = useContext(OpenAPIExchangeContext);
  const cxSchemas = useContext(OpenAPICoreExchangeContext);
  const defaultSchema = useContext(OpenAPIContext);
  if (props.exchangeSpec != null) {
    definition = pxSchemas[props.exchangeSpec];
  } else if (props.coreExchangeSpec != null) {
    definition = cxSchemas[props.coreExchangeSpec];
  } else {
    definition = defaultSchema;
  }

  const linkDefinition: OpenAPIObject = useContext(OpenAPILinkContext);

  // OpenAPI schema is not loaded into context yet
  if (definition?.paths == null) {
    return null;
  }

  // handle type=model case first as its a little different than request or response
  if (props.type === 'model') {
    if (props.model == null) {
      throw new Error('Schema: props.model must be set with type=model');
    }

    let model: SchemaObject = definition.components.schemas[props.model];

    if (model == null) {
      // eslint-disable-next-line no-console
      console.warn(`Schema: model ${props.model} not found in API definition.`);
      return null;
    }

    if (model.allOf != null) {
      model = mergeAllOf(model, {
        resolvers: {
          defaultResolver: mergeAllOf.options.resolvers.title,
        },
      });
    }

    if (!isSchemaObject(model)) {
      throw new Error('Schema: model was not an SchemaObject');
    }

    const entries: Array<SchemaObjectEntry> = Object.entries(model.properties);

    let maybeExamples: SchemaObject['examples'] =
      model['x-examples'] ??
      (model['example'] ? { 'example-1': { ...model['example'] } } : null);

    let exampleResponse =
      maybeExamples != null
        ? getFormattedExample(maybeExamples, props.exampleIndex)
        : '';

    // in exchange docs, we removed the x-examples from some of the base models (BaseAccount, BaseIdentity, BaseTransaction, etc) because they were over-riding
    // the x-examples in routes that were using these base models in allOf (e.g. LoanAccount).  Instead of using x-examples in the base routes
    // we will use in-line examples.

    if (maybeExamples == null) {
      let exampleObject = {};
      entries.forEach((property) => {
        exampleObject[`${property[0]}`] = property[1].example;
      });

      exampleResponse = JSON.stringify(exampleObject, null, 2);
    }

    return (
      <SchemaLayout
        route={''}
        schemaName={props.model}
        simplify={simplifyForBill}
        tableOnly={props.tableOnly}
        expandAll={!props.collapse}
        expandText={props.expandText}
        hideText={props.hideText}
        unhideFlag={props.unhideFlag}
        entries={entries}
        type={props.type}
        exchangeSpec={props.exchangeSpec}
        coreExchangeSpec={props.coreExchangeSpec}
        required={model.required}
        description={
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: snarkdown(
                replaceApiRouteLinks(model.description, definition),
              ),
            }}
          />
        }
      >
        {props.children != null ? (
          <div className={styles.codeBlock}>{props.children}</div>
        ) : (
          <CodeBlock
            className={styles.codeBlock}
            code={exampleResponse}
            lang='json'
            title={
              props.exampleTitle != null ? props.exampleTitle : 'API Object'
            }
          />
        )}
      </SchemaLayout>
    );
  }

  if (props.type === 'link') {
    const model: SchemaObject =
      linkDefinition.components.schemas[`${props.sdk}.${props.method}`];
    if (model == null) {
      throw new Error(
        'Schema: props.sdk & props.method must be available in link.yml',
      );
    }

    // Some link models don't have any parameters, but still contain
    if (model.properties == null) {
      return (
        <>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: snarkdown(
                replaceApiRouteLinks(model.description, definition),
              ),
            }}
          />
          {props.children}
        </>
      );
    }
    const methodKebabCase = props.method.replace('/', '-').toLowerCase();
    const entries: Array<SchemaObjectEntry> = Object.entries(
      model.properties || {},
    );

    return (
      <SchemaLayout
        route={props.method}
        schemaName={`link-${props.sdk}-${methodKebabCase}`}
        tableOnly={props.tableOnly}
        simplify={simplifyForBill}
        expandAll={!props.collapse}
        expandText={props.expandText}
        hideText={props.hideText}
        unhideFlag={props.unhideFlag}
        entries={entries}
        required={model.required}
        type={props.type}
        description={
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: snarkdown(
                replaceApiRouteLinks(model.description || '', definition),
              ),
            }}
          />
        }
      >
        {props.children}
      </SchemaLayout>
    );
  }

  let route = props.route;
  // Remove first character if it is a slash
  if (route[0] === '/') {
    route = route.substring(1);
  }
  const routeKebabCase = route.split('/').join('-').toLowerCase();

  // grab the route schema
  let routeFromDefinition: PathItemObject | undefined;
  switch (props.method) {
    case 'GET':
      routeFromDefinition = definition.paths[props.route]?.get;
      break;
    default:
      routeFromDefinition = definition.paths[props.route]?.post;
  }

  if (routeFromDefinition == null) {
    // eslint-disable-next-line no-console
    console.warn(`Schema: route ${props.route} not found in API definition.`);
    return null;
  }

  switch (props.type) {
    case 'request':
      let requestSchema =
        routeFromDefinition.requestBody?.content['application/json']?.schema ||
        routeFromDefinition.requestBody?.content[
          'application/x-www-form-urlencoded'
        ]?.schema;

      const pathParameters = routeFromDefinition?.parameters || [];
      let pathParamEntries = [];
      pathParameters.forEach((param) => {
        if (isReferenceObject(param)) {
          throw new Error('Schema: model was not a ParametersObject');
        } else {
          pathParamEntries.push(param);
        }
      });
      if (requestSchema?.allOf != null) {
        requestSchema = mergeAllOf(requestSchema);
      }
      const requestEntries: Array<SchemaObjectEntry> = Object.entries(
        requestSchema?.properties || {},
      );
      const required: Array<string> =
        routeFromDefinition.requestBody != null
          ? routeFromDefinition.requestBody.content['application/json']?.schema
              .required ||
            routeFromDefinition.requestBody?.content[
              'application/x-www-form-urlencoded'
            ]?.schema.required
          : [];
      const maybeRequestExamples: SchemaObject['examples'] =
        routeFromDefinition?.requestBody?.content['application/json']
          ?.examples ||
        routeFromDefinition?.requestBody?.content[
          'application/x-www-form-urlencoded'
        ]?.examples;
      const exampleRequestResponse =
        maybeRequestExamples != null
          ? getFormattedExample(maybeRequestExamples, props.exampleIndex)
          : '';
      const id = routeFromDefinition.summary.toLowerCase().split(' ').join('-');
      return (
        <SchemaLayout
          route={route}
          schemaName={`${routeKebabCase}-request`}
          tableOnly={props.tableOnly}
          expandAll={!props.collapse}
          expandText={props.expandText}
          hideText={props.hideText}
          unhideFlag={props.unhideFlag}
          entries={requestEntries}
          pathEntries={pathParamEntries}
          simplify={simplifyForBill}
          isRequest={true}
          type={props.type}
          description={
            <>
              <Link href={`#${id}`}>
                <a>
                  <h4 className={styles.schemaHeader}>
                    {routeFromDefinition.summary}
                    <span
                      id={id}
                      className={styles.offsetAnchor}
                      data-algolia='linkedHeadingAnchor'
                    />
                  </h4>
                </a>
              </Link>

              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: snarkdown(
                    replaceApiRouteLinks(
                      routeFromDefinition.description,
                      definition,
                    ),
                  ),
                }}
              />
            </>
          }
          tableHeader={<b>Request fields</b>}
          required={required}
        >
          {props.children != null ? (
            <div className={styles.codeBlock}>{props.children}</div>
          ) : exampleRequestResponse !== '' ? ( // If no example and no children, Exchange will use the Layout.SideBySide component in the MDX file to render hard coded bash example.
            <CodeBlock
              className={styles.codeBlock}
              code={exampleRequestResponse}
              lang='json'
              title={
                props.exampleTitle != null ? props.exampleTitle : 'API Object'
              }
            />
          ) : null}
        </SchemaLayout>
      );
    case 'response':
      let responseSchema =
        routeFromDefinition.responses['200'].content['application/json'].schema;
      if (responseSchema.allOf != null) {
        responseSchema = mergeAllOf(responseSchema);
      }

      const responseEntries: Array<SchemaObjectEntry> = Object.entries(
        responseSchema?.properties || {},
      );

      let requiredResponseProperties: string[];

      if (props.exchangeSpec != null || props.coreExchangeSpec != null) {
        const requiredResponseArray: Array<string[]> = Object.entries(
          responseSchema.required || [],
        );

        requiredResponseProperties = requiredResponseArray.map((property) => {
          return property[1];
        });
      }

      const maybeExamples: SchemaObject['examples'] =
        //       Accept examples object with nested example objects (oas v3.1.x)
        routeFromDefinition.responses['200'].content['application/json']
          .examples || {
          //       Accept single example object  (oas v3.0.x: https://www.openapis.org/blog/2021/02/16/migrating-from-openapi-3-0-to-3-1-0)
          'example-1': {
            ...routeFromDefinition.responses['200'].content['application/json']
              .example,
          },
        };

      const exampleResponse =
        maybeExamples != null
          ? getFormattedExample(maybeExamples, props.exampleIndex)
          : '';
      const showLineStart =
        selectedCode.route === route ? selectedCode.startLine : null;
      const showLineEnd =
        selectedCode.route === route ? selectedCode.endLine : null;

      return (
        <SchemaLayout
          exchangeSpec={props.exchangeSpec}
          coreExchangeSpec={props.coreExchangeSpec}
          route={route}
          schemaName={`${routeKebabCase}-response`}
          tableOnly={props.tableOnly}
          expandAll={!props.collapse}
          expandText={props.expandText}
          hideText={props.hideText}
          unhideFlag={props.unhideFlag}
          simplify={simplifyForBill}
          entries={responseEntries}
          responseExamples={maybeExamples}
          stringCode={exampleResponse}
          type={props.type}
          required={
            props.exchangeSpec != null || props.coreExchangeSpec != null
              ? requiredResponseProperties
              : null
          }
          tableHeader={
            <p className={styles.tableHeader}>
              <b>Response fields</b> and example
            </p>
          }
        >
          {props.children != null ? (
            <div className={styles.codeBlock}>{props.children}</div>
          ) : (
            <CodeBlock
              className={styles.codeBlock}
              code={exampleResponse}
              lang='json'
              title={
                props.exampleTitle != null ? props.exampleTitle : 'API Object'
              }
              showLineStart={showLineStart}
              showLineEnd={showLineEnd}
            />
          )}
        </SchemaLayout>
      );
    default:
      throw new Error(
        'Schema: no type defined. Must be one of: request | response | model',
      );
  }
};

Schema.displayName = 'Schema';

export default Schema;
