import Dropdown, { DropdownItem } from 'plaid-threads/Dropdown';
import React, { useEffect, useState } from 'react';
import ChevronS2Down from 'plaid-threads/Icons/ChevronS2Down';
import Touchable from 'plaid-threads/Touchable';

import { APIVersion, APIVersionPreview } from '../types';

import Context from 'src/contexts/docs';

import styles from './APIVersionSwitcher.module.scss';

interface APIVersionOption extends DropdownItem {
  label: string;
  value: APIVersion | APIVersionPreview;
}

const options: Array<APIVersionOption> = [
  { label: 'Published', value: '2020-09-14' },
  { label: 'Local Preview', value: 'local-preview' },
];

const APIVersionSwitcher: React.FC = () => {
  const { dispatch, apiVersion } = React.useContext(Context);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLocalHost, setIsLocalHost] = React.useState(false);

  useEffect(() => {
    setIsLocalHost(window.location.hostname === 'localhost');
  }, []);

  const selectedOption = options.find((o) => o.value === apiVersion);

  return (
    isLocalHost && (
      <>
        <Dropdown
          wrapperClassName={styles.wrapper}
          isOpen={isOpen}
          options={options}
          onChange={(v) => {
            console.log(`Setting version as ${v.value}`);
            setIsOpen(false);
            dispatch({
              type: 'SET_API_VERSION',
              payload: {
                apiVersion: v.value as APIVersion | APIVersionPreview,
              },
            });
          }}
          onEscape={() => {
            setIsOpen(false);
          }}
          value={selectedOption}
          target={
            <Touchable
              onClick={() => setIsOpen(!isOpen)}
              className={styles.apiVersionText}
            >
              <span className={styles.apiVersion}>API Version: </span>{' '}
              {selectedOption != null ? selectedOption.label : 'Published'}
              <ChevronS2Down />
            </Touchable>
          }
        />
      </>
    )
  );
};

APIVersionSwitcher.displayName = 'APIVersionSwitcher';

export default APIVersionSwitcher;
