import React, { useContext, useEffect, useState } from 'react';

import MultiCodeBlock from '../MultiCodeBlock';
import CodeBlock from '../CodeBlock';
import {
  createCurlSampleCode,
  createNodeSampleCode,
  createRubySampleCode,
} from './simpleLangConverter';
import { createPythonSampleCode } from './pythonConverter';
import { createJavaSampleCode } from './javaConverter';
import { createGoSampleCode } from './goConverter';
import Select from '../../Inputs/Select/Select';
import { useTrack } from 'src/contexts/AnalyticsContext';
import { LINK_TOKEN_CREATE_EVENTS } from 'src/lib/trackingEvents';

export interface ltcUseCase {
  useCase: string;
  config: object;
  comment?: string;
}

export interface Props {
  useCases: Array<ltcUseCase>;
  filenameAsTitle?: boolean;
}

const LinkTokenCreate: React.FC<Props> = (props: Props) => {
  const track = useTrack();

  const [selectedIndex, setSelectedIndex] = useState('0');
  const [sampleLTCObject, setSampleLTCObject] = useState(
    Object({
      loading_sample: true,
    }),
  );
  const [sampleComment, setSampleComment] = useState('');

  useEffect(() => {
    const numericIndex = parseInt(selectedIndex);
    if (numericIndex >= 0 && props.useCases != null) {
      setSampleLTCObject(props.useCases[numericIndex].config);
      setSampleComment(props.useCases[numericIndex].comment ?? '');
    }
  }, [selectedIndex, props.useCases]);

  useEffect(() => {
    track({
      type: LINK_TOKEN_CREATE_EVENTS.SELECTOR_LOADED,
    });
  }, []);

  // Do I need to call this directly, or could I make this a userEffect
  const trackSelectedUseCase = (index: string) => {
    track({
      type: LINK_TOKEN_CREATE_EVENTS.OPTION_PICKED,
      payload: {
        useCase: props.useCases[parseInt(index)].useCase,
      },
    });
  };

  return (
    <>
      {props.useCases.length > 1 && (
        <p>
          <Select
            id='configObject'
            label='Select a sample scenario / product'
            name='ltcSelect'
            onChange={(e) => {
              trackSelectedUseCase(e.value);
              setSelectedIndex(e.value);
            }}
            options={
              props.useCases != null &&
              props.useCases.map((obj, idx) => {
                return {
                  label: obj.useCase,
                  value: idx,
                };
              })
            }
          ></Select>
        </p>
      )}
      <MultiCodeBlock
        type={'server'}
        codeBlocks={{
          node: (
            <CodeBlock
              title={props.filenameAsTitle ? 'server.js' : 'Link Token Create'}
              lang='javascript'
              code={createNodeSampleCode(sampleLTCObject, sampleComment)}
            />
          ),
          python: (
            <CodeBlock
              title={props.filenameAsTitle ? 'server.py' : 'Link Token Create'}
              lang='python'
              code={createPythonSampleCode(sampleLTCObject, sampleComment)}
            />
          ),
          bash: (
            <CodeBlock
              title={props.filenameAsTitle ? '' : 'Link Token Create'}
              lang='bash'
              code={createCurlSampleCode(sampleLTCObject, sampleComment)}
            />
          ),
          ruby: (
            <CodeBlock
              title={props.filenameAsTitle ? 'server.rb' : 'Link Token Create'}
              lang='ruby'
              code={createRubySampleCode(sampleLTCObject, sampleComment)}
            />
          ),
          java: (
            <CodeBlock
              title={
                props.filenameAsTitle ? 'Server.java' : 'Link Token Create'
              }
              lang='java'
              code={createJavaSampleCode(sampleLTCObject, sampleComment)}
            />
          ),
          go: (
            <CodeBlock
              title={props.filenameAsTitle ? 'server.go' : 'Link Token Create'}
              lang='go'
              code={createGoSampleCode(sampleLTCObject, sampleComment)}
            />
          ),
        }}
      />
    </>
  );
};

LinkTokenCreate.displayName = 'LinkTokenCreate';
export default LinkTokenCreate;
