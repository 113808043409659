import React from 'react';
import MultiCodeBlock from '../../MultiCodeBlock';

import CodeBlock from '../../CodeBlock';

interface CodeBlockProps {
  directory: string;
  setup: string;
  command: string;
  product: string;
  oauth?: boolean;
}

interface Props {
  product: string;
  oauth?: boolean;
}

const QuickstartCodeBlock: React.FC<CodeBlockProps> = (props) => {
  const oauthEnvMessage = `# Copy the .env.example file to .env, then fill
# out PLAID_CLIENT_ID, PLAID_SECRET,
# PLAID_COUNTRY_CODES=GB, and
# PLAID_REDIRECT_URI=http://localhost:3000/`;

  const defaultEnvMessage = `# Copy the .env.example file to .env, then fill
# out PLAID_CLIENT_ID and PLAID_SECRET in .env`;

  const code =
    props.directory === 'curl'
      ? `# not applicable`
      : `# Note: If on Windows, run
# git clone -c core.symlinks=true https://github.com/plaid/quickstart
# instead to ensure correct symlink behavior

git clone https://github.com/plaid/quickstart.git
cd quickstart/${props.directory}

${props.oauth ? oauthEnvMessage : defaultEnvMessage}
cp .env.example .env

${props.setup}

# Start the backend app
${props.command}`;

  return (
    <CodeBlock
      title='1. Clone the Quickstart and run the backend'
      lang='bash'
      code={code}
    />
  );
};

const RunQuickstart: React.FC<Props> = (props) => (
  <MultiCodeBlock
    type='server'
    codeBlocks={{
      node: (
        <QuickstartCodeBlock
          directory='node'
          product={props.product}
          setup={`# Install dependencies
npm install`}
          command='./start.sh'
          oauth={props.oauth}
        />
      ),
      bash: (
        <QuickstartCodeBlock
          directory='curl'
          product={props.product}
          setup={`# Install dependencies
npm install`}
          command='./start.sh'
          oauth={props.oauth}
        />
      ),
      ruby: (
        <QuickstartCodeBlock
          directory='ruby'
          setup={`# Install dependencies
bundle`}
          product={props.product}
          command='./start.sh'
          oauth={props.oauth}
        />
      ),
      python: (
        <QuickstartCodeBlock
          directory='python'
          setup={`# Note: must use python 3

# For virtualenv users:
# virtualenv venv
# source venv/bin/activate
pip3 install -r requirements.txt`}
          product={props.product}
          command='./start.sh'
          oauth={props.oauth}
        />
      ),
      java: (
        <QuickstartCodeBlock
          directory='java'
          setup={'mvn clean package'}
          product={props.product}
          command='./start.sh'
          oauth={props.oauth}
        />
      ),
      go: (
        <QuickstartCodeBlock
          directory='go'
          setup={'go build'}
          product={props.product}
          command='./start.sh'
          oauth={props.oauth}
        />
      ),
    }}
  />
);

RunQuickstart.displayName = 'RunQuickstart';
export default RunQuickstart;
