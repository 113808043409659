import { ErrorReference } from '../../types';

export const SANDBOX_WEBHOOK_INVALID: ErrorReference = {
  server: {
    error_type: 'SANDBOX_ERROR',
    error_code: 'SANDBOX_WEBHOOK_INVALID',
    error_message:
      "Webhook for this item is either not set up, or invalid. Please update the item's webhook and try again.",
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const SANDBOX_PRODUCT_NOT_ENABLED: ErrorReference = {
  server: {
    error_type: 'SANDBOX_ERROR',
    error_code: 'SANDBOX_PRODUCT_NOT_ENABLED',
    error_message:
      'The [auth | transactions | ...] product is not enabled on this item',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const SANDBOX_BANK_TRANSFER_EVENT_TRANSITION_INVALID: ErrorReference = {
  server: {
    error_type: 'SANDBOX_ERROR',
    error_code: 'SANDBOX_BANK_TRANSFER_EVENT_TRANSITION_INVALID',
    error_message:
      'The provided simulated event type is incompatible with the current transfer status',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const SANDBOX_TRANSFER_EVENT_TRANSITION_INVALID: ErrorReference = {
  server: {
    error_type: 'SANDBOX_ERROR',
    error_code: 'SANDBOX_TRANSFER_EVENT_TRANSITION_INVALID',
    error_message:
      'The provided simulated event type is incompatible with the current transfer status',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const SANDBOX_ACCOUNT_SELECT_V2_NOT_ENABLED: ErrorReference = {
  server: {
    error_type: 'SANDBOX_ERROR',
    error_code: 'SANDBOX_ACCOUNT_SELECT_V2_NOT_ENABLED',
    error_message: 'The item was not created with Account Select v2',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
