/**
 * The code used to generate Node, Ruby, and cURL sample code was so similar
 * that it was fairly easy to combine their code into a few common functions.
 * The same can't really be said for Python, Java, and Go. It got too messy
 * trying to combine them.
 */

import { formatComment } from './utils';

type FormatOptions = {
  arrayOneLineThreshold?: number;
  keyValueSeparator?: string;
};

// Common function to format arrays based on some conditions
const formatArray = (
  arr: any[],
  maxElementsPerLine = 2,
  maxCharsPerLine = 50,
) => {
  if (
    arr.length <= maxElementsPerLine &&
    JSON.stringify(arr).length <= maxCharsPerLine
  ) {
    return `[${arr.map((v) => `'${v}'`).join(', ')}]`;
  } else {
    return `[\n    ${arr.map((v) => `'${v}'`).join(',\n    ')}\n  ]`;
  }
};

function formatObject(obj, indent = 2, options: FormatOptions = {}) {
  const defaultOptions = {
    arrayOneLineThreshold: 2,
    keyValueSeparator: ':',
  };

  const { arrayOneLineThreshold, keyValueSeparator } = {
    ...defaultOptions,
    ...options,
  };

  let str = '{\n';
  const entries = Object.entries(obj);
  entries.forEach(([key, value], i) => {
    const isLast = i === entries.length - 1;
    let valueStr;
    if (Array.isArray(value)) {
      valueStr = formatArray(value, arrayOneLineThreshold);
    } else if (typeof value === 'object') {
      valueStr = formatObject(value, indent + 2, options);
    } else if (typeof value === 'boolean') {
      valueStr = `${value}`;
    } else if (typeof value === 'number') {
      valueStr = `${value}`;
    } else {
      valueStr = `'${value}'`;
    }
    str += `${' '.repeat(indent)}${key}${keyValueSeparator} ${valueStr}${
      isLast ? '\n' : ',\n'
    }`;
  });
  str += `${' '.repeat(indent - 2)}}`;
  return str;
}

export const createNodeSampleCode = (obj: object, comment: string) => {
  return `
${formatComment(comment, '// ')}
const request: LinkTokenCreateRequest = ${formatObject(obj)};
try {
  const response = await plaidClient.linkTokenCreate(request);
  const linkToken = response.data.link_token;
} catch (error) {
  // handle error
}
`.trim();
};

export const createRubySampleCode = (config: object, comment: string) => {
  return `${formatComment(comment, '# ')}
link_token_create_request = Plaid::LinkTokenCreateRequest.new(
  ${formatObject(config, 4, { keyValueSeparator: ':' })}
)

response = client.link_token_create(
  link_token_create_request
)
link_token = response.link_token
`.trim();
};

export const createCurlSampleCode = (config: object, comment: string) => {
  config = {
    client_id: '<Client>${PLAID_CLIENT_ID}</Client>',
    secret: '<Secret>${PLAID_SECRET}</Secret>',
    ...config,
  };
  // A helper function passed in to JSON stringify that will add newlines if
  // our arrays get too big
  const replacer = (key: string, value: any) => {
    if (Array.isArray(value)) {
      return formatArray(value, 3, 50);
    }
    return value;
  };

  const configJson = JSON.stringify(config, replacer, 2)
    .replace(/"\[/g, '[')
    .replace(/\]"/g, ']')
    .replace(/\\\"/g, '"')
    .replace(/'/g, '"');

  return `${formatComment(
    comment,
    '# ',
  )}curl -X POST https://sandbox.plaid.com/link/token/create -H 'Content-Type: application/json' -d '${configJson}'`;
};
