
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';
import Partner from './Partner';

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Callout = makeShortcode("Callout");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h5 {...{
      "id": "test-with-sandbox-credentials"
    }}>{`Test with Sandbox credentials`}</h5>
    <p>{`To test the integration in Sandbox mode, simply use the Plaid `}<a parentName="p" {...{
        "href": "/docs/sandbox/test-credentials"
      }}>{`Sandbox credentials`}</a>{`
along when launching Link with a `}<inlineCode parentName="p">{`link_token`}</inlineCode>{` created in the Sandbox environment.`}</p>
    <Callout mdxType="Callout">
      <p>{`When testing in the Sandbox, you have the option to use the `}<inlineCode parentName="p">{`/sandbox/public_token/create`}</inlineCode>{` endpoint instead of the end-to-end Link flow to create a `}<inlineCode parentName="p">{`public_token`}</inlineCode>{`.  When using the `}<inlineCode parentName="p">{`/sandbox/public_token/create`}</inlineCode>{`-based flow, the Account Select flow will be bypassed and the `}<inlineCode parentName="p">{`accounts`}</inlineCode>{` array will not be populated. On Sandbox, instead of using the `}<inlineCode parentName="p">{`accounts`}</inlineCode>{` array, you can call `}<inlineCode parentName="p">{`/accounts/get`}</inlineCode>{` and test with any returned account ID.`}</p>
    </Callout>
    <h5 {...{
      "id": "get-ready-for-production"
    }}>{`Get ready for production`}</h5>
    <p>{`Your account is immediately enabled for our Sandbox environment (`}<a parentName="p" {...{
        "href": "https://sandbox.plaid.com"
      }}>{`https://sandbox.plaid.com`}</a>{`).
To move to Production, please request access from the `}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/developers/keys"
      }}>{`Dashboard`}</a>{`.
You will need `}<Partner p={props.partner} mdxType="Partner" />{` Production credentials prior to initiating live traffic
in the `}<Partner p={props.partner} mdxType="Partner" />{` API with Plaid.`}</p>
    <h5 {...{
      "id": "support-and-questions"
    }}>{`Support and questions`}</h5>
    <p>{`Find answers to many common integration questions and concerns—such as pricing, sandbox and test mode usage,
and more, in our `}<a parentName="p" {...{
        "href": "/docs"
      }}>{`docs`}</a>{`.`}</p>
    <p>{`If you're still stuck, open a `}<a parentName="p" {...{
        "href": "https://dashboard.plaid.com/support/new"
      }}>{`support ticket`}</a>{` with information
describing the issue that you're experiencing and we'll get back to you as soon as we can.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
