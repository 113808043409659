import React, { useContext, useEffect, useState } from 'react';
import IconButton from 'plaid-threads/IconButton';
import Cancel from 'plaid-threads/Icons/Cancel';

// #TODO: If I use the wrapped component instead, the ID gets all weird.
// How do I fix that?
import { TextInput } from 'src/components/Inputs';

import Context from '../../../contexts/docs';
import styles from './ChatInput.module.scss';

const ChatInput = ({
  currentQuestion,
  onInputChange,
  isStreaming,
  handleStopStream,
  isEmptyState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch, openSearchClicked } = useContext(Context);

  return (
    <div className={styles.chatInput} id='finn-input-div'>
      <TextInput
        autoComplete='off'
        className={styles.input}
        id='finn-input'
        name='finn-input'
        label={isEmptyState ? 'Ask a question...' : 'Ask another question...'}
        value={currentQuestion}
        onChange={onInputChange}
        iconButtons={[
          isStreaming && (
            <IconButton
              accessibilityLabel='Clear search input'
              hideTooltip={true}
              icon={<Cancel className={styles.inputIcon} />}
              onClick={handleStopStream}
            />
          ),
        ]}
      />
    </div>
  );
};

ChatInput.displayName = 'ChatInput';

export default ChatInput;
