import React from 'react';

import styles from './Wrapper.module.scss';

interface Props {
  children: Array<React.ReactNode>;
}

const Wrapper: React.FC<Props> = (props: Props) => (
  <div className={styles.wrapper} data-algolia-remove='true'>
    {props.children}
  </div>
);

Wrapper.displayName = 'Wrapper';

export default Wrapper;
