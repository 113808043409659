import React, { useContext } from 'react';
import SelectGroup, { SelectGroupOptionsType } from 'plaid-threads/SelectGroup';
import { useUniqueId } from 'plaid-threads/utils';

import Context from '../../../contexts/docs';

import styles from './Container.module.scss';

interface Props {
  children: Array<React.ReactNode> | React.ReactNode;
  names: string[]; // names prop must be an array of names in the order that the content children are placed inside this component.
  id?: string;
  hiddenIsSearchable?: boolean;
}

const Container = (props: Props) => {
  const { contentSelected, dispatch } = useContext(Context);

  const options = props.names.map((name) => ({
    // options required by threads SelectGroup
    label: name,
    value: name,
  }));

  const value =
    options.find((o) => o.value === contentSelected[props.id]) || options[0];

  const uniqueId = useUniqueId('content-switcher');

  // sets selection in context for the particular id so all instances of that id will change selection
  const handleChange = (v: SelectGroupOptionsType) => {
    dispatch({
      type: 'SET_CONTENT_SELECTED',
      payload: { [props.id]: v.value },
    });
  };

  const targetIndex = props.names.indexOf(value.value);

  return (
    <>
      <span id={uniqueId} className={styles.a11yTitle}>
        Select group for content switcher
      </span>
      <div>
        <SelectGroup
          className={styles.buttonsContainer}
          labeledById={uniqueId}
          options={options}
          onChange={handleChange}
          value={value}
          small
          inline
        />
      </div>
      {React.Children.map(props.children, (child, idx) => {
        if (!React.isValidElement(child)) {
          console.log(`Invalid child: ${child}`);
          return child;
        }
        return props.hiddenIsSearchable ? (
          <div className={idx === targetIndex ? '' : styles.visuallyHidden}>
            {child}
          </div>
        ) : idx === targetIndex ? (
          <div>{child}</div>
        ) : null;
      })}
    </>
  );
};

Container.displayName = 'Container';

export default Container;
