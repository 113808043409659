import Link from 'next/link';
import React, { useContext } from 'react';
import cx from 'classnames';
import { OpenAPIObject } from 'openapi3-ts';

import Context from '../../../contexts/docs';
import OpenAPIContext from '../../../contexts/docs/openapi';
import { isApiRoute } from '../utilities';

import styles from './index.module.scss';

interface Props {
  Component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  id: string;
  children: React.ReactElement;
}

const LinkedHeading: React.FC<Props> = ({ Component, id, children }) => {
  const { tableOfContents } = useContext(Context);
  const definition: OpenAPIObject | null = useContext(OpenAPIContext);

  // return SSR version of component so that headers are included in initial payload
  if (tableOfContents == null) {
    return (
      <Link href={`#${id}`} passHref>
        <a href={`#${id}`}>
          <Component className={styles.heading}>
            {children}
            <span
              id={id}
              className={styles.offsetAnchor}
              data-algolia='linkedHeadingAnchor'
            />
          </Component>
        </a>
      </Link>
    );
  }

  const childrenIsApiRoute = isApiRoute(children, definition);
  if (id == null) {
    return <Component>{children}</Component>;
  }
  return (
    <>
      <Link href={`#${id}`}>
        <a>
          <Component
            className={cx(styles.heading, childrenIsApiRoute && 'noMargin')}
          >
            {childrenIsApiRoute ? <code>{children}</code> : children}
            <span
              id={id}
              className={styles.offsetAnchor}
              data-algolia='linkedHeadingAnchor'
            />
          </Component>
        </a>
      </Link>
    </>
  );
};

LinkedHeading.displayName = 'LinkedHeading';

export default LinkedHeading;
