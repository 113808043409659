import Video from 'plaid-threads/Icons/Video';
import Terminal from 'plaid-threads/Icons/Terminal';
import React from 'react';
import styles from './MediaCard.module.scss';

interface Props {
  title?: string;
  subtitle?: string;
  href: string;
  imageSrc: string;
  category?: 'video' | 'code';
}

const MediaCard: React.FC<Props> = (props: Props) => (
  <div className={styles.shadowedBox}>
    {props.title != null && (
      <div className={styles.titleDiv}>
        {props.category === 'video' && <Video />}
        {props.category === 'code' && <Terminal />}
        <b>{props.title}</b>
      </div>
    )}
    <a href={props.href}>
      <img src={props.imageSrc} width='100%' />
    </a>
    <p className={styles.subtitle}>{props.subtitle}</p>
  </div>
);

MediaCard.displayName = 'MediaCard';

export default MediaCard;
