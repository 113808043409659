import { ErrorReference } from '../../types';

export const INVALID_PARENT: ErrorReference = {
  server: {
    error_type: 'ASSET_REPORT_ERROR',
    error_code: 'INVALID_PARENT',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const ASSET_REPORT_GENERATION_FAILED: ErrorReference = {
  server: {
    error_type: 'ASSET_REPORT_ERROR',
    error_code: 'ASSET_REPORT_GENERATION_FAILED',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const DATA_UNAVAILABLE: ErrorReference = {
  server: {
    http_code: 400,
    display_message: null,
    error_code: 'DATA_UNAVAILABLE',
    error_message:
      'unable to pull sufficient data for all items to generate this report. please see the "causes" field for more details',
    error_type: 'ASSET_REPORT_ERROR',
    suggested_action: null,
    causes: [
      {
        display_message: null,
        error_code: 'ITEM_LOGIN_REQUIRED',
        error_message:
          "the login details of this item have changed (credentials, MFA, or required user action) and a user login is required to update this information. use Link's update mode to restore the item to a good state",
        error_type: 'ITEM_ERROR',
        item_id: 'pZ942ZA0npFEa0BgLCV9DAQv3Zq8ErIZhc81F',
      },
    ],
  },
  link: null,
};

export const ASSET_PRODUCT_NOT_ENABLED: ErrorReference = {
  server: {
    error_type: 'ASSET_REPORT_ERROR',
    error_code: 'PRODUCT_NOT_ENABLED',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const ASSET_PRODUCT_NOT_READY: ErrorReference = {
  server: {
    error_type: 'ASSET_REPORT_ERROR',
    error_code: 'PRODUCT_NOT_READY',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INSIGHTS_NOT_ENABLED: ErrorReference = {
  server: {
    error_type: 'ASSET_REPORT_ERROR',
    error_code: 'INSIGHTS_NOT_ENABLED',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INSIGHTS_PREVIOUSLY_NOT_ENABLED: ErrorReference = {
  server: {
    error_type: 'ASSET_REPORT_ERROR',
    error_code: 'INSIGHTS_PREVIOUSLY_NOT_ENABLED',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
