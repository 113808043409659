import React, { Component } from 'react';

import ContainerWithHeader from '../ContainerWithHeader';
import { LinkError } from '../types';

import styles from './index.module.scss';

interface Props {
  customizations?: any;
  defaultCustomizations?: any;
  defaultPreset?: string;
  displayedPresets?: any;
  preset?: string;
  scenario?: string;
  error: null | LinkError;
}

interface State {
  isLoaded: boolean;
}

const MESSAGE_NAMESPACE = 'preview::';
const LINK_PREVIEW_ORIGIN = 'https://cdn.plaid.com';
const LINK_PREVIEW_EXTENSION = '/link/v2/stable/preview.html';
// TODO: remove these after testing is done
// const LINK_PREVIEW_ORIGIN = 'http://localhost:8000';
// const LINK_PREVIEW_EXTENSION = '/preview.html';

class Preview extends Component<Props> {
  static defaultProps = {
    scenario: 'default',
    displayedPresets: ['errorPane'],
    defaultPreset: 'errorPane',
    preset: 'errorPane',
    defaultCustomizations: {
      global: {
        overlayBackgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
  };
  state: State = {
    isLoaded: false,
  };
  _iframe: any = null;
  constructor(props) {
    super(props);
  }
  updatePreset(preset, scenario) {
    this._iframe.contentWindow.postMessage(
      JSON.stringify({
        action: MESSAGE_NAMESPACE + 'updatePreset',
        payload: {
          preset: preset,
          scenario: scenario,
        },
      }),
      LINK_PREVIEW_ORIGIN,
    );
  }
  updateDefaultCustomizations() {
    this._iframe.contentWindow.postMessage(
      JSON.stringify({
        action: MESSAGE_NAMESPACE + 'updateDefaultCustomizations',
        payload: this.props.defaultCustomizations,
      }),
      LINK_PREVIEW_ORIGIN,
    );
  }
  updateDisplayedPresets() {
    this._iframe.contentWindow.postMessage(
      JSON.stringify({
        action: MESSAGE_NAMESPACE + 'updateDisplayedPresets',
        payload: {
          displayedPresets: this.props.displayedPresets,
          defaultPreset: this.props.preset,
        },
      }),
      LINK_PREVIEW_ORIGIN,
    );
  }
  updateCustomizations() {
    const customizations = {
      errorPane: {
        title: this.props.error.link_error_title,
        error: {
          ...this.props.error,
        },
      },
    };
    this._iframe.contentWindow.postMessage(
      JSON.stringify({
        action: MESSAGE_NAMESPACE + 'updateCustomizations',
        payload: customizations,
      }),
      LINK_PREVIEW_ORIGIN,
    );
  }
  updateError() {
    this._iframe.contentWindow.postMessage(
      JSON.stringify({
        action: MESSAGE_NAMESPACE + 'updateError',
        payload: {
          error_code: this.props.error.error_code,
          mfa_type: this.props.error.mfa_type,
        },
      }),
      LINK_PREVIEW_ORIGIN,
    );
  }
  onPreviewLoad() {
    // On load, pass in what is meant to be the initial shown preset:
    this.updateDefaultCustomizations();
    this.updateError();
    this.updateCustomizations();
    this.updateDisplayedPresets();
    this.updatePreset(this.props.preset, this.props.scenario);
    this.setState({ isLoaded: true });
  }
  render() {
    return (
      <ContainerWithHeader
        header='Link on Desktop Web'
        className={styles.container}
      >
        {this.state.isLoaded ? null : (
          <span className={styles.loading} aria-live='polite'>
            Loading Link preview
          </span>
        )}

        <iframe
          title='Link error preview'
          className={styles.iframe}
          src={LINK_PREVIEW_ORIGIN + LINK_PREVIEW_EXTENSION}
          ref={(ref) => (this._iframe = ref)}
          onLoad={() => this.onPreviewLoad()}
        />
      </ContainerWithHeader>
    );
  }
}

export default Preview;
